
import { PropType, defineComponent, ref } from 'vue';
import {
  addOutline,
  removeOutline,
} from 'ionicons/icons';
import { loadingController } from '@ionic/vue';
import ButtonWithLoader from '@/atomic/organisms/ButtonWithLoader.vue';

export default defineComponent({
  name: 'MenuGenerateRecipe',
  components: { ButtonWithLoader },
  props: {
    daySelected: {
      type: Object as PropType<Date>,
      required: true,
    },
    menuId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['generate:success', 'generate:error'],
  setup() {
    const mealSize = ref(3);
    const addServing = () => {
      if (mealSize.value < 12) {
        mealSize.value += 1;
      }
    };
    const removeServing = () => {
      if (mealSize.value > 1) {
        mealSize.value -= 1;
      }
    };
    return {
      mealSize,
      addServing,
      removeServing,
      addOutline,
      removeOutline,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/get'];
    },
  },
  methods: {
    async presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'text-center',
          message: `On te prépare ${this.mealSize} recette${this.mealSize > 1 ? 's' : ''}...`,
          duration: 6000,
        });
      await loading.present();
      try {
        await this.generateRecipes();
      } catch (err) {
        this.$sentry.captureException(err);
      } finally {
        loading.dismiss();
      }
    },
    async generateRecipes() {
      try {
        if (this.menuId <= 0) {
          await this.$store.dispatch('menus/recommandationRecipes', {
            date: this.daySelected.toISOString(),
            recipesNumber: this.mealSize,
          });
        } else {
          await this.$store.dispatch('menus/fill', {
            menuId: this.menuId,
            mealSize: this.mealSize,
          });
        }
        this.$emit('generate:success');
      } catch { this.$emit('generate:error'); }
    },
  },
});
