/* eslint-disable max-classes-per-file */
import Fuse from 'fuse.js';
import filtersJSON from '@/assets/json/filters.json';
import { FilterClass } from './classes';
import Recipe from './recipe.class';

type SearchResult<T> = Fuse.FuseResult<T>;

type IFilter = {
  [key: string]: {
    title: string,
    isCategory: boolean,
    filters: { 'title': string, min?: number, max?: number }[],
    keyAction: keyof Recipe,
  },
};

type LibraryFilter = {
  key: string,
  groupKey: string,
  title: string,
  isActive: boolean,
  expression: string,
  keyAction: keyof Recipe,
};

class Filter implements FilterClass {
  public key: string;
  public groupKey: string;
  public title: string;

  public isActive: boolean;
  public isCategory: boolean;

  public keyAction: keyof Recipe;
  public minMax = [0, 10000];

  constructor({ filter }
  :{ filter: LibraryFilter & { min?: number; max?: number; isCategory: boolean; } }) {
    this.key = filter.key;
    this.groupKey = filter.groupKey;
    this.title = filter.title;
    this.isActive = filter.isActive;
    this.isCategory = filter.isCategory;
    this.keyAction = filter.keyAction ?? filter.key;
    if (filter.min !== undefined && filter.max !== undefined) {
      this.minMax = [filter.min, filter.max];
    }
  }

  /**
   * Check if a value is in the range of the filter
   * @param value value to compare with the min and max
   */
  isBetween(value: number) {
    return value >= this.minMax[0] && value <= this.minMax[1];
  }

  /**
   * Toggle the filter
   */
  toggleIsActive(): void {
    this.isActive = !this.isActive;
  }

  /**
   * Desactivate the filter
   */
  desactivate() { this.isActive = false; }
}

type FilterByGroup = {
  [key: string]: Filter[],
};

class Filters {
  public list: Filter[] = [];

  public groups: { key: string, title: string }[] = [];

  constructor() {
    Object.entries((filtersJSON as unknown as IFilter))
      .forEach(([key, group]) => {
        this.groups.push({ key, title: group.title });
        group.filters.forEach((element) => {
          this.list.push(new Filter({
            filter: {
              ...element,
              groupKey: key,
              isActive: false,
              key: element.title,
              keyAction: group.keyAction,
              isCategory: group.isCategory,
              expression: element.title,
            },
          }));
        });
      });
  }

  /**
   * Desactivate all filters
   */
  desactivateAll(): void {
    this.list.forEach((filter) => {
      filter.desactivate();
    });
  }

  /**
   * @returns true if at least one filter is active
   */
  haveActiveFilters(): boolean {
    return this.findByIsActive().length > 0;
  }

  /**
   * @param groupKey group key to get the filters
   * @returns list of filters
   */
  findFiltersByGroupKey(groupKey: string): Filter[] {
    return this.list.filter(({ groupKey: filterGroupKey }) => filterGroupKey === groupKey);
  }

  /**
   * @param key key of the filter to get
   * @returns filter if found, undefined otherwise
   */
  findGroupByKey(key: string): { key: string; title: string; } | undefined {
    return this.groups.find(({ key: groupKey }) => groupKey === key);
  }

  /**
   * @returns list of active filters
   */
  findByIsActive(): Filter[] {
    return this.list.filter(({ isActive }) => isActive);
  }

  /**
   * @returns list of filters by group key
   */
  reduceFiltersByGroup(): FilterByGroup {
    return this.list.reduce((acc, filter) => {
      const { groupKey } = filter;
      if (acc[groupKey]) {
        acc[groupKey].push(filter);
      } else { acc[groupKey] = [filter]; }
      return acc;
    }, {} as FilterByGroup);
  }

  /**
   * @returns expression(s) of the filters for the fuseJS search
   */
  buildExpressionForFuseJSFromList(): { [key: string]: string; }[] {
    const activeFilters = this.findByIsActive();
    const groups = activeFilters
      .filter(({ isCategory }) => isCategory)
      .reduce((acc, filter) => {
        const { groupKey } = filter;
        if (acc[groupKey]) {
          acc[groupKey].push(filter);
        } else { acc[groupKey] = [filter]; }
        return acc;
      }, {} as FilterByGroup);
    return Object.values(groups).reduce((acc, filters) => {
      acc.push({
        'categories.displayName': filters.map(({ key }) => key).join('|'),
      });
      return acc;
    }, [] as { [key: string]: string }[]);
  }

  /**
   * @returns functions for the fuseJS search filtering
   */
  buildFiltersFunctionForFuseJSFromList(): ((item: SearchResult<Recipe>) => boolean)[] {
    const activeFilters = this.findByIsActive();
    const groups = activeFilters
      .filter(({ isCategory }) => !isCategory)
      .reduce((acc, filter) => {
        const { groupKey } = filter;
        if (acc[groupKey]) {
          acc[groupKey].push(filter);
        } else { acc[groupKey] = [filter]; }
        return acc;
      }, {} as FilterByGroup);
    // can be optimized by getting value before looping;
    return Object.values(groups).reduce((acc, filters) => {
      const filterFunction = ({ item }: SearchResult<Recipe>) => filters.some((filter) => {
        const value = item[filter.keyAction] as NonNullable<number>;
        return filter.isBetween(value);
      });
      acc.push(filterFunction);

      return acc;
    }, [] as ((item: SearchResult<Recipe>) => boolean)[]);
  }
}

export default Filters;
