import { MutationTree } from 'vuex';

import { IMenu } from '@/typings/interfaces';
import Menu from '@/typings/classes/menu.class';

import { State } from './state';
import { MenuMutationTypes } from './menu-types';

type Mutations<S = State> = {
  [MenuMutationTypes.SET_DATA](state: S, payload: unknown): void;
  [MenuMutationTypes.SET_MENU](state: S, payload: unknown): void;
  [MenuMutationTypes.SET_MENU_MEALS](state: S, payload: IMenu): void;
  [MenuMutationTypes.CLEAR](state: S): void;
};

const mutations: MutationTree<State> & Mutations = {
  [MenuMutationTypes.SET_DATA](state: State, payload: IMenu) {
    const keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      Object.assign(state, {
        [key as keyof IMenu]: payload[key as keyof IMenu],
      });
    }
  },
  [MenuMutationTypes.SET_MENU](state: State, payload: IMenu) {
    const menus = [...state.menusClasses]
      .filter((menu) => menu.id !== payload.id);
    menus.push(new Menu(payload));
    Object.assign(state, { menusClasses: menus });
  },
  [MenuMutationTypes.SET_MENU_MEALS](state: State, payload: IMenu) {
    const menu = new Menu(payload);
    const { meals } = menu;
    const menuToUpdate = state.menusClasses
      .find((m) => m.id === payload.id);
    if (menuToUpdate) {
      menuToUpdate.meals = meals;
    }
  },
  [MenuMutationTypes.CLEAR](state: State) {
    Object.assign(state, { menus: [], menusClasses: [] });
  },
};

export { Mutations, mutations };
