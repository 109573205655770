
import { defineComponent, Ref, ref } from 'vue';
import {
  leafOutline,
  readerOutline,
  folderOpenOutline,
  searchSharp,
  addOutline,
  chevronBack,
} from 'ionicons/icons';
import { AnimationType } from '@/plugins/animation/types';

import {
  IonButton,
  useIonRouter,
} from '@ionic/vue';


import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'LibraryView',
  provide() {
    return {
      openModalProvider: this.openModal,
      closeModalProvider: this.closeModal,
      ionRouterParent: this.ionRouterParent,
    };
  },

  props: {
    firstTab: {
      type: Object,
      default: () => ({
        name: 'first tab',
        icon: leafOutline,
        component: IonButton,
      }),
      required: true,
    },
    secondTab: {
      type: Object,
      default: () => ({
        name: 'secondtab',
        icon: leafOutline,
        component: IonButton,
      }),
      required: true,
    },
  },
  setup() {
    const isOpenRef = ref(true);
    const modalName = ref('LibraryResearchModal');
    const modalId: Ref<number | string> = ref(0);
    const route = useRoute();
    const router = useIonRouter();
    return {
      // ref
      AnimationType,
      // router
      route,
      router,
      // icons
      readerOutline,
      chevronBack,
      folderOpenOutline,
      searchSharp,
      addOutline,
      // modal
      modalName,
      modalId,
      isOpenRef,
    };
  },
  computed: {
    isFirstTab(): boolean {
      return this.route.fullPath.includes(this.firstTab.url);
    },
    isSecondTab(): boolean {
      return this.route.fullPath.includes(this.secondTab.url);
    },
    presentingElement() {
      return this.$parent?.$refs.ionRouterOutlet as HTMLElement | undefined;
    },
  },
  methods: {
    ionRouterParent() {
      return this.$parent?.$refs.ionRouterOutlet;
    },
    closeModal() { this.isOpenRef = false; },
    openModal() { this.isOpenRef = false; },
    goPrevious() {
      if (this.router.canGoBack()) {
        this.router.back();
      } else {
        this.router.push('/library/recipes');
      }
    },
    openResearch() {
      this.router.push('/research');
    },
  },
});
