import { IUser } from '@/typings/interfaces';
import { getAge } from './helpers';

/**
 * All of these functions take two arguments height in centimeters
 * and gender which is either "male" or "female"
 * @return the weight in kilograms calculated using the respective formula.
 * Note that in all of these formulas, the weight is rounded to the nearest tenth of a kilogram.
 * Also note that 152.4 is the equivalent of 5 feet in centimeters.
 */

/**
 * The Robinson formula is the most commonly used formula for calculating ideal body weight.
 */
function robinsonFormula(height: number, gender: 'M' | 'F'): number {
  const centimetersOverFiveFeet = Math.max(height - 152.4, 0);
  const weight = gender === 'M'
    ? 52 + 0.6 * centimetersOverFiveFeet
    : 49 + 0.5 * centimetersOverFiveFeet;
  return weight;
}
/*
 * The Miller formula is a modification of the Robinson formula.
 */
function millerFormula(height: number, gender: 'M' | 'F'): number {
  const centimetersOverFiveFeet = Math.max(height - 152.4, 0);
  const weight = gender === 'M'
    ? 56.2 + 0.62 * centimetersOverFiveFeet
    : 53.1 + 0.47 * centimetersOverFiveFeet;
  return weight;
}
/**
 * The Hamwi formula is a modification of the Robinson formula.
 */
function hamwiFormula(height: number, gender: 'M' | 'F'): number {
  const centimetersOverFiveFeet = Math.max(height - 152.4, 0);
  const weight = gender === 'M'
    ? 48 + 1.1 * centimetersOverFiveFeet
    : 45.5 + 0.91 * centimetersOverFiveFeet;
  return weight;
}
/**
 * The most medically validated ideal body weight formula is the Devine formula.
 * Physicians use this formula to calculate the doses of certain drugs,
 * such as levothyroxine or immunoglobulins.
 * Other formulas don’t differ that much from the Devine formula.
 * They were mainly designed trying to improve the original formula.
 * @link https://www.omnicalculator.com/health/ideal-weight
 */
function devineFormula(height: number, gender: 'M' | 'F'): number {
  const centimetersOverFiveFeet = Math.max(height - 152.4, 0);
  const weight = gender === 'M'
    ? 50 + 0.9 * centimetersOverFiveFeet
    : 45.5 + 0.91 * centimetersOverFiveFeet;
  return weight;
}

function brocaFormula(height: number) {
  return height - 100;
}

function lorentzFormula(height: number, biologicalSex: 'F' | 'M' | null): number {
  const brocaBase = brocaFormula(height);
  if (biologicalSex === 'F') {
    return brocaBase - ((height - 150) / 2.5);
  }
  return brocaBase - ((height - 150) / 4);
}

function creffFormula(height: number, age: number, k = 1): number {
  return brocaFormula(height) + 0.09 * age * k;
}

function getIdealWeight(user: IUser) {
  const { height, birthdate, biologicalSex } = user;
  return [
    lorentzFormula(height, biologicalSex),
    creffFormula(height, getAge(birthdate)),
  ];
}

/**
 *  For a long time, the most common way to calculate your basal metabolic rate
 * was the Harris-Benedict equation. It was initially published back in 1919, and for over 70 years,
 * it was considered to be the best basal metabolic rate formula available.
 * It was later replaced with another basal metabolic rate formula that turned out to be even
 * more accurate – the Mifflin St Jeor Equation.
 * @return the basal metabolic rate in calories per day.
 * @link { https://www.omnicalculator.com/health/bmr }
 */
function mifflinStJeor(weight: number, height: number, age: number, gender: 'M' | 'F'): number {
  const s = gender === 'M' ? 5 : -161;
  const bmr = 10 * weight + 6.25 * height - 5 * age + s;
  return bmr;
}

// eslint-disable-next-line import/prefer-default-export
export {
  getIdealWeight,
  mifflinStJeor,
  robinsonFormula,
  millerFormula,
  hamwiFormula,
  devineFormula,
};
