
import { defineComponent, shallowRef } from 'vue';

import config from '@/assets/json/chart-config.json';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, ChartConfiguration, registerables } from 'chart.js';

import { ellipsisVertical, addOutline } from 'ionicons/icons';

import { IUser } from '@/typings/interfaces';
import ProfileModalWeight from './helpers/ProfileModalWeight.vue';
import ProfileAddWeight from './helpers/ProfileAddWeight.vue';

export default defineComponent({
  name: 'ProfileWeight',
  components: {
    ProfileModalWeight,
    ProfileAddWeight,
  },
  data() {
    return {
      config: config as ChartConfiguration,
      isAddModalOpen: false,
      isWeightModalOpen: false,
      addOutline,
      ellipsisVertical,
      isActive: true,
      myChart: shallowRef({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        destroy: (): void => { },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        update: (): void => { },
      }),
    };
  },
  computed: {
    profile(): IUser & { isLogged: boolean } {
      return this.$store.getters['user/get'] as IUser & { isLogged: boolean };
    },
    weights(): number[] {
      if (this.profile === null) {
        return [];
      }
      return (this.profile.weights ?? [])
        .map(({ value }) => value);
    },
    objectiveWeight(): number | null {
      return this.profile.objectiveWeight;
    },
  },
  watch: {
    weights: {
      handler(newValue: number[]) {
        if (!this.isActive) {
          return;
        }
        const labels = newValue.map((unused, index) => `S. ${index + 1}`);
        const shadowWeight = [...newValue];
        Object.assign(this.config.data, { labels });
        this.config.data.datasets[0].data = shadowWeight;
        this.myChart.update();
      },
    },
    objectiveWeight: {
      handler(value: number) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Object.assign(this.config.options!, {
          plugins: {
            legend: { display: false },
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  scaleID: 'yAxes',
                  value,
                  endValue: value,
                  borderColor: 'rgb(0, 99, 12)',
                  borderWidth: 1,
                  content: 'Objectif',
                },
              },
            },
          },
        });
        if (value) { this.myChart.update(); }
      },
    },
  },
  mounted() {
    const ctx = document.getElementById('line-chart') as HTMLCanvasElement;

    Chart.register(...registerables, annotationPlugin);
    if (this.weights && this.weights.length > 0) {
      const labels = this.weights.map((unused, index) => `S. ${index + 1}`);
      const shadowWeight = [...this.weights];
      this.config.data.labels = labels;
      this.config.data.datasets[0].data = shadowWeight;
    }
    this.myChart = new Chart(ctx, this.config);
  },
  ionViewWillLeave() {
    this.isActive = false;
    this.myChart.destroy();
  },
  ionViewWillEnter() {
    this.isActive = true;
  },
  methods: {
    addNewWeight() {
      this.isAddModalOpen = true;
    },
    toggleAddModal(isOpen: boolean) {
      this.isAddModalOpen = isOpen;
    },
    toggleWeightModal(value: boolean) {
      this.isWeightModalOpen = value;
    },
  },
});
