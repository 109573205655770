
import { defineComponent, ref } from 'vue';
import { IonCard, useIonRouter } from '@ionic/vue';

export default defineComponent({
  name: 'LibraryCarouselPriceTagCard',
  components: { IonCard },
  props: {
    tagPrice: {
      type: String,
      default: 'cheap',
    },
  },
  setup() {
    const router = useIonRouter();
    const width = ref(200);
    return { router, width };
  },
  computed: {
    priceTagLabel(): string {
      return {
        cheap: '2',
        reasonable: '3,5',
        expensive: '5',
      }[this.tagPrice] ?? '';
    },
    imgSrc() {
      return new URL(`../../assets/img/library-carousels/${this.tagPrice}.webp`, import.meta.url).href;
    },
  },
  methods: {
    goToPriceTagCategory() {
      this.router.push(`/library/price/${this.tagPrice}`);
    },
    getUrl() {
      return `@/assets/img/price-${this.tagPrice}.png`;
    },
  },
});
