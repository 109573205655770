
import {
  computed,
  ComputedRef,
  defineComponent,
} from 'vue';

import { get } from 'lodash';
import { useStore } from '@/store';

import {
  bonfireOutline,
  timeOutline,
  trashOutline,
  ellipsisVertical,
} from 'ionicons/icons';
import { useIonRouter, IonThumbnail } from '@ionic/vue';

import SvgRecipeCooking from '@/components/recipes/svg/SvgRecipeCooking.vue';
import SvgRecipeKnife from '@/components/recipes/svg/SvgRecipeKnife.vue';

import Recipe from '@/typings/classes/recipe.class';
import { alertController } from '@ionic/core';

export default defineComponent({
  name: 'LibraryFoodlistRecipeCard',
  components: {
    SvgRecipeCooking,
    SvgRecipeKnife,
    IonThumbnail,
  },
  props: {
    recipeId: {
      type: Number,
      default: 1,
    },
    foodlistId: {
      type: Number,
      default: 0,
    },
    haveOption: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useIonRouter();
    const store = useStore();
    const recipe: ComputedRef<Recipe> = computed(() => store.getters['library/findOneRecipe'](props.recipeId));
    return {
      timeOutline,
      bonfireOutline,
      trashOutline,
      ellipsisVertical,
      router,
      recipe,
    };
  },
  computed: {
    getUrl(): string {
      const imgUrl = get(this.recipe, 'imageSource', '');
      if (imgUrl) return `https://api.petitcitron.fr${imgUrl}`;
      return imgUrl;
    },
    energy() {
      return (this.recipe.calories ?? 0).toFixed(0);
    },
  },
  methods: {
    getRecipePath() {
      const pathBase = `/recipe/${this.recipe.id}`;
      if (this.$route.query) {
        const append = Object.entries(this.$route.query)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
        if (append.length) return (`${pathBase}?${append}`);
      }
      return (pathBase);
    },
    goToRecipe() {
      setTimeout(() => {
        this.router.push(this.getRecipePath());
      }, 200);
    },
    async presentAlertToDeleteRecipeInFoodlist() {
      const cancel = {
        text: 'Annuler',
        cssClass: 'text-danger',
      };
      const remove = {
        text: 'Confirmer',
        cssClass: 'text-primary',
        handler: () => this.$store.dispatch('foodlists/toggleRecipeInFoodlist', {
          recipe: this.recipeId,
          foodlist: this.foodlistId,
        }),
      };
      const alert = await alertController
        .create({
          header: 'Supprimer la recette ?',
          message: 'Confirmes la suppression de la recette. Cette action est irréversible',
          buttons: [cancel, remove],
        });
      await alert.present();
    },
  },
});
