import { omit } from 'lodash';
import { EMealTime, EMealType } from '../enums';

type TMealRecipe = number | { data: { id: number } | null } | null;

type TMealAliment = {
  id?: number,
  quantity: number,
  aliment: { data: { id: number } },
};

interface IMeal {
  id: number | null;
  starterCourse: TMealRecipe;
  mainCourse: TMealRecipe;
  dessert: TMealRecipe;
  aliments: TMealAliment[];
  mealTime?: EMealTime;
  date?: string | Date;
  servings: number;
  status: 'pending' | 'cooked' | 'deleted';
}

class Meal implements Omit<IMeal, 'aliments'> {
  id: number | null;
  starterCourse: number | null;
  mainCourse: number | null;
  dessert: number | null;
  aliments: { id?: number, quantity: number, aliment: number }[];
  mealTime?: EMealTime;
  date?: Date;
  servings: number;
  status: 'pending' | 'cooked' | 'deleted';

  constructor(meal: IMeal) {
    this.id = meal.id;
    this.starterCourse = null;
    this.starterCourse = null;
    this.mainCourse = null;
    this.dessert = null;
    this.status = meal.status;

    if (typeof meal.starterCourse !== 'number') {
      this.starterCourse = meal?.starterCourse?.data?.id ?? null;
    } else { this.starterCourse = meal.starterCourse; }

    if (typeof meal.mainCourse !== 'number') {
      this.mainCourse = meal?.mainCourse?.data?.id ?? null;
    } else { this.mainCourse = meal.mainCourse; }

    if (typeof meal.dessert !== 'number') {
      this.dessert = meal?.dessert?.data?.id ?? null;
    } else { this.dessert = meal.dessert; }

    this.aliments = meal.aliments
      .map((aliment: TMealAliment) => ({ ...aliment, aliment: aliment.aliment.data.id })) ?? [];
    this.mealTime = meal.mealTime;
    this.date = typeof meal.date === 'string' ? new Date(meal.date) : meal.date;
    this.servings = meal.servings ?? 1;
  }

  removeRecipeByMealType(mealType: EMealType) {
    switch (mealType) {
      case EMealType.STARTER_COURSE:
        this.starterCourse = null;
        break;
      case EMealType.MAIN_COURSE:
        this.mainCourse = null;
        break;
      case EMealType.DESSERT:
        this.dessert = null;
        break;
      default:
        break;
    }
  }

  addAliment(aliment: { quantity: number, aliment: number }) {
    if (this.aliments.some((a) => a.aliment === aliment.aliment)) {
      const arrayAliment = this.aliments.find((a) => a.aliment === aliment.aliment) as
        NonNullable<{ quantity: number, aliment: number }>;
      Object.assign(arrayAliment, { quantity: arrayAliment.quantity + aliment.quantity });
    } else {
      this.aliments.push(aliment);
    }
  }

  addQuantityToAliment(id: number, quantity: number) {
    const aliment = this.aliments.find((a) => a.aliment === id);
    if (aliment) {
      aliment.quantity = quantity;
    }
  }

  updateStatus(status: 'pending' | 'cooked' | 'deleted') {
    this.status = status;
  }

  removeAliment(id: number) {
    this.aliments = this.aliments.filter((a) => a.aliment !== id);
  }

  setServings(servings: number) {
    this.servings = servings;
  }

  get describeForApi(): Omit<IMeal, 'id' | 'aliments'> {
    if (this.id === null) {
      return {
        ...omit(this, 'id'),
        date: this.date?.toISOString().split('T')[0] ?? null,
      };
    }
    return {
      ...this,
      date: this.date?.toISOString().split('T')[0] ?? null,
    };
  }
}

export { Meal, IMeal };
