
import moment from 'moment';
import { defineComponent, ref } from 'vue';
import { DatetimeChangeEventDetail, IonDatetime } from '@ionic/vue';
import { IonDatetimeCustomEvent } from '@ionic/core';

export default defineComponent({
  components: { IonDatetime },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const isModalOpen = ref(false);
    const modalTitle = ref('Choisir une date');
    const date = ref(props.value ?? (new Date('06/14/1998')).toISOString());

    const onModalDismiss = () => {
      isModalOpen.value = false;
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const showModal = () => {
      isModalOpen.value = true;
    };

    const onDateChange = (event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
      const newDate = event.detail.value;
      date.value = newDate as string;
      emit('update:value', newDate);
    };

    return {
      isModalOpen,
      modalTitle,
      date,
      onModalDismiss,
      closeModal,
      showModal,
      onDateChange,
    };
  },
  computed: {
    dateFormattedToFrench() {
      return moment(this.date).format('DD/MM/YYYY');
    },
  },
});
