enum GroceryMutationTypes {
  SET_DATA = 'SET_DATA',
  SET_LISTS = 'SET_LISTS',
  CLEAR = 'CLEAR',
}

enum GroceryActionTypes {
  VALIDATE = 'validate',
  FETCH_GROCERY = 'fetchGrocery',
  FETCH_GROCERY_LISTS = 'fetchGroceryLists',
  UPDATE_LIST = 'updateList',
  UPDATE_PURCHASED = 'updatePurchased',
  PURCHASE_ALIMENT = 'purchaseAliment',
  UNPURCHASE_ALIMENT = 'unpurchaseAliment',
  UPDATE_MULTIPLE_PURCHASED = 'updateMultiplePurchased',
  CLEAR= 'clear',
}

export {
  GroceryMutationTypes,
  GroceryActionTypes,
};
