import { IFoodlist, IRecipe } from '../interfaces';

/**
 * Foodlist class
 */
class Foodlist {
  public id: number;
  public title: string;
  public recipes: Array<number>;
  public isFavorite: boolean;

  constructor(foodlist: IFoodlist) {
    this.id = foodlist.id;
    this.title = foodlist.attributes.title;
    this.isFavorite = foodlist.attributes.isFavorite;
    this.recipes = foodlist.attributes.recipes.data
      .map((recipe) => recipe.id);
  }

  copy(): Foodlist {
    return new Foodlist({
      id: this.id,
      attributes: {
        title: this.title,
        isFavorite: this.isFavorite,
        recipes: {
          data: this.recipes.map((id) => ({ id })) as unknown as IRecipe[],
        },
      },
    });
  }
}

export default Foodlist;
