
import { computed, ComputedRef, defineComponent, PropType } from 'vue';

import { useStore } from '@/store/index';
import { IMacronutriments, INutriments } from '@/typings/interfaces';

import ProgressCircle from '@/components/common/ProgressCircle.vue';

type BaseDisplay = {
  key: string;
  name: string;
  symbol?: string;
  color: string;
};

type NutrientDisplay = {
  value: string;
  recommended: number;
  percent: number;
} & Omit<BaseDisplay, 'key'>;

export default defineComponent({
  name: 'RecipeNutrimentsDisplay',
  components: { ProgressCircle },
  props: {
    nutriments: {
      type: Object as PropType<Omit<IMacronutriments, 'id'>>,
      default: () => ({
        fat: 0,
        protein: 0,
        carbohydrate: 0,
        energy_reg_kcal: 0,
      }),
    },
  },
  setup() {
    const store = useStore();
    const progressDisplay = [
      { key: 'carbohydrate', name: 'Glucides', color: 'danger' },
      { key: 'fat', name: 'Lipides', color: 'warning' },
      { key: 'protein', name: 'Protéines', color: 'success' },
    ] as BaseDisplay[];
    const energyDisplay = {
      key: 'energy_reg_kcal',
      name: 'Energie',
      color: 'primary',
      symbol: 'kCal',
    } as BaseDisplay;
    const recommendedMacronutriments: ComputedRef<INutriments> = computed(
      () => store.getters['user/getRecommendedUserMacronutrients'],
    );

    return {
      energyDisplay,
      progressDisplay,
      recommendedMacronutriments,
    };
  },
  computed: {
    nutrientToDisplayInProgressBar() {
      return this.progressDisplay.map(this.formatNutrientForDisplay);
    },
    energyToDisplayInProgressCircle() {
      return this.formatNutrientForDisplay(this.energyDisplay);
    },
  },
  methods: {
    formatNutrientForDisplay(base: BaseDisplay) {
      const {
        key, name, color, symbol = 'g',
      } = base;
      const value = this.nutriments[key as keyof Omit<IMacronutriments, 'id'>];
      const valueRecommended = this.recommendedMacronutriments[key as keyof Omit<IMacronutriments, 'id'>] ?? '1';
      const recommended = parseInt(valueRecommended, 10);
      const percent = computed(() => ((value / recommended) * 100));
      return {
        name,
        value: value.toFixed(0),
        recommended,
        percent: Number.isNaN(percent.value) ? 0 : percent.value,
        symbol,
        color,
      } as NutrientDisplay;
    },
  },
});

