
import { defineComponent } from 'vue';
import { IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/vue';
import GamificationEvent from '@/tmp/src/entities/gamification/gamificationEvent';

export default defineComponent({
  name: 'GiftActionCard',
  components: {
    IonCardHeader, IonCardTitle, IonCardSubtitle,
  },
  props: {
    gift: {
      type: Object as () => GamificationEvent,
      required: true,
    },
  },
  computed: {
    pictureUrl() {
      if (this.gift.picture) {
        if (import.meta.env.MODE === 'development') {
          return `http://localhost:1337${this.gift.picture}`;
        }
        return `https://api.petitcitron.fr${this.gift.picture}`;
      }
      return 'https://ionicframework.com/docs/img/demos/card-media.png';
    },
  },
});
