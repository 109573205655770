import { ICooker } from '@/typings/interfaces';
import conf from '@/conf/HttpInstance';
import { get } from 'lodash';
import { CrudHandler, HttpInstance } from '@/tmp/src';

interface Recipe {
  id: number;
  attributes: {
    title: string;
    cookingTime: number;
    preparationTime: number;
    price: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    isPremium: boolean | null;
    image: {
      data: { url: string },
    }
    seoUrl: string;
    months: number[] | null;
  };
}

interface Video {
  id: number;
  attributes: {
    name: string;
    alternativeText: string | null;
    caption: string | null;
    width: number | null;
    height: number | null;
    formats: object | null;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string | null;
    provider: string;
    provider_metadata: object | null;
    createdAt: string;
    updatedAt: string;
  };
}

type Picture = Video;

interface JsonCooker {
  id: number;
  attributes: {
    username: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    recipe: {
      data: Recipe;
    };
    video: {
      data: Video;
    };
    thumbnail: { data: Picture };
  };
}

class CookerRepository extends CrudHandler<ICooker> {
  protected resourceUrl = 'cookers';

  constructor(httpInstance: HttpInstance) {
    super(httpInstance);
    this.defaultPopulate = '';
  }

  // eslint-disable-next-line class-methods-use-this
  map(json: JsonCooker): ICooker {
    const cooker: ICooker = {
      id: json.id,
      username: json?.attributes?.username,
      video: {
        id: get(json, 'attributes.video.data.id', -1),
        ...json?.attributes?.video?.data?.attributes,
      },
      recipe: {
        id: get(json, 'attributes.recipe.data.id', -1),
        ...json?.attributes?.recipe?.data?.attributes,
      },
      thumbnail: {
        id: get(json, 'attributes.thumbnail.data.id', -1),
        ...json?.attributes?.thumbnail?.data?.attributes,
      },
    };
    return cooker;
  }
}

export default new CookerRepository(conf);
