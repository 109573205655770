
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';
import useGamificationAccountStore from '@/stores/gamification/gamificationAccountStore';
import { useIonRouter } from '@ionic/vue';
import { chevronExpandOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'MenuToolbarAccount',
  setup() {
    const store = useStore();
    const router = useIonRouter();
    const useGamificationAccount = useGamificationAccountStore();
    const objective = computed(() => store.getters['user/objective'] as string);
    const familySize = computed(() => store.getters['user/familySize'] as number);
    const points = computed(() => useGamificationAccount.account.balance ?? 0);
    return { points, objective, familySize, router, chevronExpandOutline };
  },
  methods: {
    goToAccountPreferences() {
      this.router.push({ name: 'AccountPreferences' });
    },
  },
});
