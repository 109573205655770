
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from '@/store';
import { star } from 'ionicons/icons';

import useGamificationAccountStore from '@/stores/gamification/gamificationAccountStore';
import { EventKeys } from '@/tmp/src/entities/gamification/gamificationEvent';
import { storeToRefs } from 'pinia';
import { Meal } from '@/typings/classes/meal.class';
import Track from '@/services/tracking/firebase';

export default defineComponent({
  name: 'RecipeMealValidation',
  props: {
    meal: {
      type: Object as PropType<Meal>,
      default: () => (ref(undefined)),
    },
    title: {
      type: String,
      default: 'Recette',
    },
  },
  setup(props) {
    const store = useStore();
    const gamificationAccountStore = useGamificationAccountStore();
    const { canValidateMoreRecipes } = storeToRefs(gamificationAccountStore);
    const hasCookedRecipe = computed(() => props.meal.status === 'cooked');
    return {
      star,
      props,
      store,
      hasCookedRecipe,
      gamificationAccountStore,
      canValidateMoreRecipes,
    };
  },
  methods: {
    async setMealStatusToCooked() {
      if (!this.hasCookedRecipe) {
        const success = await this.gamificationAccountStore
          .createEvent(EventKeys.RECIPE_VALIDATION);
        if (success !== false) {
          Track.logEvent('recipe_view', { title: this.title });
          this.meal.updateStatus('cooked');
          await this.$store.dispatch('menus/updateMeal', this.meal);
        }
      }
    },
  },
});
