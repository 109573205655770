
import { PropType, computed, defineComponent } from 'vue';
import { useIonRouter } from '@ionic/vue';
import AffiliateCampaign from '@/tmp/src/entities/affiliate/affiliateCampaign';
import useAffiliateCustomerStore from '@/stores/affiliate';
import GiftBadgeCost from './GiftBadgeCost.vue';

export default defineComponent({
  name: 'GiftCard',
  components: {
    GiftBadgeCost,
  },
  props: {
    gift: {
      type: Object as PropType<AffiliateCampaign>,
      required: true,
    },
  },
  setup(props) {
    const domains = {
      development: 'http://localhost:1337',
      staging: 'https://api.staging.petitcitron.fr',
      production: 'https://api.petitcitron.fr',
    };
    const baseDomain = domains[import.meta.env.MODE];
    const router = useIonRouter();
    const affiliateCustomerStore = useAffiliateCustomerStore();
    const goBack = () => {
      if (router.canGoBack()) {
        router.back();
      } else {
        router.push('/gifts');
      }
    };
    const logoUrl: string | null = affiliateCustomerStore
      .getAffilitatePictureByCampaignId(props.gift.id);
    const getLogoUrl = computed(() => {
      if (logoUrl === null) {
        return 'https://ionicframework.com/docs/img/demos/card-media.png';
      }
      return `${baseDomain}${logoUrl}`;
    });
    const getPictureUrl = () => {
      if (!props.gift.picture) {
        return 'https://ionicframework.com/docs/img/demos/card-media.png';
      }
      return `${baseDomain}${props.gift.picture}`;
    };
    const goToGiftPreview = () => { router.push(`/gifts/preview/${props.gift.id}`); };
    return { goBack, getLogoUrl, goToGiftPreview, getPictureUrl };
  },
});
