import { MutationTree } from 'vuex';

import { IUser } from '@/typings/interfaces';

import { State, state as defaultState } from './state';
import { UserMutationTypes } from './user-types';

type Mutations<S = State> = {
  [UserMutationTypes.SET_DATA](state: S, payload: unknown): void;
  [UserMutationTypes.CLEAR](state: S): void;
};

const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.SET_DATA](state: State, payload: IUser) {
    const keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      Object.assign(state, {
        [key as keyof IUser]: payload[key as keyof IUser],
      });
    }
  },
  [UserMutationTypes.CLEAR](state: State) {
    console.log(defaultState);
    Object.assign(state, {
      ...defaultState,
    });
  },
};

export { Mutations, mutations };
