
import { PropType, defineComponent } from 'vue';
import { IonChip } from '@ionic/vue';
import Recipe from '@/typings/classes/recipe.class';

export default defineComponent({
  name: 'RecipeCategoriesBlock',
  components: {
    IonChip,
  },
  props: {
    categories: {
      type: Array as PropType<Recipe['categories']>,
      default: () => [],
    },
  },
});
