
import { defineComponent, ref } from 'vue';
import { chevronForward } from 'ionicons/icons';
import { modalController } from '@ionic/vue';
import useReferralStore from '@/stores/referral/referralStore';
import ButtonWithLoader from '@/atomic/organisms/ButtonWithLoader.vue';

export default defineComponent({
  name: 'OnboardingReferralModal',
  components: { ButtonWithLoader },
  setup() {
    const referralStore = useReferralStore();
    const referralCode = ref('');
    const error = ref(null as string | null);
    const cancel = () => { modalController.dismiss(null, 'cancel', 'onboarding-referral-modal'); };
    const confirm = () => {
      modalController.dismiss(null, 'confirm', 'onboarding-referral-modal');
    };
    return {
      referralStore,
      referralCode,
      cancel,
      confirm,
      chevronForward,
      error,
    };
  },
  methods: {
    async validateCode(code: string): Promise<boolean> {
      try {
        this.error = null;
        await this.referralStore.bindRefereeToReferralWith(code);
        this.confirm();
        return true;
      } catch (e) {
        this.error = 'Code de parrainage invalide';
        return false;
      }
    },
  },
});
