
import { defineComponent, ref } from 'vue';
import { IonCard, useIonRouter } from '@ionic/vue';

export default defineComponent({
  name: 'LibraryCarouselRecipeTimeCard',
  components: { IonCard },
  props: {
    recipeTime: {
      type: String as () => 'fast' | 'normal' | 'slow' | 'slower',
      default: 'fast',
    },
  },
  setup() {
    const router = useIonRouter();
    const width = ref(200);
    return { router, width };
  },
  computed: {
    imgSrc() {
      return new URL(`../../assets/img/library-carousels/${this.recipeTime}.webp`, import.meta.url).href;
    },
    timeSpeedLabel(): string {
      return {
        fast: '15',
        normal: '30',
        slow: '45',
        slower: '60',
      }[this.recipeTime] ?? '';
    },
  },
  methods: {
    goToPriceTagCategory() {
      this.router.push(`/library/recipe-time/${this.timeSpeedLabel}`);
    },
    getUrl() {
      return `@/assets/img/price-${this.recipeTime}.png`;
    },
  },
});
