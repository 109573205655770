import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IUstensil } from '@/typings/interfaces';

type UstensilResult = {
  data: IUstensil[] | IUstensil,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};

class UstensilRepository implements IRead<UstensilResult> {
  private config = AxiosConfig;

  private prefix = 'ustensils';

  async find(): Promise<AxiosResponse<UstensilResult>> {
    try {
      return this.config.get(`${this.prefix}`);
    } catch (err) {
      console.error('PC -- Error UstensilRepository');
      console.error(err);
      throw err;
    }
  }

  async findOne(id: string): Promise<AxiosResponse<UstensilResult>> {
    try {
      return this.config.get(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error UstensilRepository');
      console.error(err);
      throw err;
    }
  }
}

export default UstensilRepository;
