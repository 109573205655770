
// TODO: Refactor this component
// TODO: Add objective BMI for the user;
import { computed, defineComponent } from 'vue';
import User from '@/typings/classes/user.class';
import { mapGetters, mapState } from 'vuex';
import { trendingDownOutline, trendingUpOutline } from 'ionicons/icons';
import { useStore } from '@/store';
import { UserWeight } from '@/typings/interfaces';
import moment from 'moment';
import WeightLossProgram from '@/services/nutrition/program';
import date from '@/services/date';

export default defineComponent({
  name: 'ProfileObjectives',
  setup() {
    const store = useStore();
    const weights = computed(() => store.getters['user/weights'] as UserWeight[]);
    const lossStatistics = computed(() => User.weigthLossStatistics(weights.value));
    return {
      lossStatistics,
      trendingDownOutline,
      trendingUpOutline,
    };
  },
  computed: {
    ...mapGetters('user', [
      'weights',
      'bmi',
      'idealWeight',
      'weightLoss',
      'weightToLoss',
      'idealUserWeight',
    ]),
    percentWeightLost() {
      const percent = this.$store.getters['user/percentWeightLost'];
      if (Number.isNaN(percent)) {
        return 0;
      }
      return percent;
    },
    ...mapState('user', ['objectiveWeight']),
    objectiveDate(): string {
      return this.dateToObjective;
    },
    dateToObjective(): string {
      const { weeksToObjective } = this.weightLossProgram;
      const momentLocal = date().locale('fr');
      return momentLocal.add(weeksToObjective, 'weeks')
        .locale('fr')
        .format('MMMM YYYY');
    },
    weightLossProgram(): WeightLossProgram {
      return this.$store.getters['user/program'] as WeightLossProgram;
    },
    objectiveDurationPercent(): number {
      const dateStart = this.$store.getters['user/get'].startObjectiveDate as string | null;
      const dateEnd = this.$store.getters['user/get'].objectiveDate as string | null;
      const dateNowRef = moment();
      const dateStartRef = moment(dateStart);
      const dateEndRef = moment(dateEnd);
      const diffEndNow = (dateEndRef.diff(dateNowRef, 'days'));
      const diffEndStart = (dateEndRef.diff(dateStartRef, 'days'));
      const diffPercent = (100 - (diffEndNow / diffEndStart) * 100);
      if (diffPercent < 0) {
        return 0;
      }
      if (diffPercent > 100) {
        return 100;
      }
      // eslint-disable-next-line no-bitwise
      return diffPercent | 0;
    },
  },
  methods: {
    formatNumber(number: number) {
      return new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    },
  },
});
