
import { defineComponent, computed } from 'vue';
import { getPlatforms, useIonRouter } from '@ionic/vue';
import {
  SignInWithApple,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import Track from '@/services/tracking/firebase';
import { useStore } from '@/store/index';
import { logoApple } from 'ionicons/icons';
import useGamificationAccountStore from '@/stores/gamification/gamificationAccountStore';
import { EventKeys } from '@/tmp/src/entities/gamification/gamificationEvent';

export default defineComponent({
  name: 'AppleSignIn',
  setup() {
    const isIos = computed(() => getPlatforms().includes('ios'));
    const store = useStore();
    const gamificationAccountStore = useGamificationAccountStore();
    const router = useIonRouter();
    const profile = computed(() => store.getters['user/get']);
    const options: SignInWithAppleOptions = {
      clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
      redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };
    return { options, profile, router, logoApple, gamificationAccountStore, isIos };
  },
  methods: {
    async signIn() {
      Track.logEvent('login', { method: 'apple' });
      const success = await SignInWithApple.authorize(this.options);
      const { status }: { status : 'register' | 'login' } = await this
        .$store.dispatch('user/connectWithApple', success);
      const {
        response: {
          givenName: firstname,
          familyName: lastname,
        },
      } = success;
      if (firstname && lastname) {
        await this.$store.dispatch('user/set', { firstname, lastname });
        await this.$store.dispatch('user/updateSelf');
      }
      if (status === 'register') {
        this.router.push('/login/apple');
      } else {
        await this.fetchUser();
        this.router.push('/menu');
      }
    },
    async fetchUser() {
      await this.$store.dispatch('user/me');
      const promises = [
        this.gamificationAccountStore.findAccount(),
        this.gamificationAccountStore.createEvent(EventKeys.DAILY_CONNECTION),
        this.$store.dispatch('menus/find'),
        this.$store.dispatch('grocery/fetchGroceryLists'),
        this.$store.dispatch('foodlists/fetchFoodlists'),
      ];

      if (this.$store.getters['library/isLibraryInitialized'] === false) {
        promises.push(this.$store.dispatch('library/initLibrary'));
      }
      await Promise.all(promises);
    },
  },
});
