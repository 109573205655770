/* eslint @typescript-eslint/no-shadow: ["error", { "allow": ["state"] }] */

import {
  Module,
  GetterTree,
  MutationTree,
  ActionTree,
  ActionContext,
} from 'vuex';

// repositories
import RecipeRepository from '@/repositories/recipe';
import CategoryRepository from '@/repositories/categories';
import AlimentRepository from '@/repositories/aliment';
import UnitRepository from '@/repositories/unit';

// utils
import { RequireAtLeastOne } from '@/typings/utils';

// classes
import { Library, LibraryGroup, TCategory } from '@/typings/classes/library.class';
import Recipe from '@/typings/classes/recipe.class';
import Aliment from '@/typings/classes/aliment.class';

// types
import {
  IRecipe, ICategory, IAliment, IUnit, IUstensil,
} from '@/typings/interfaces';
import UstensilRepository from '@/repositories/ustensil';
import AllergenRepository from '@/repositories/allergen/AllergenRepository';
import { LibraryActionTypes, LibraryMutationTypes } from './library-types';

type State = {
  library: Library,
  isLibraryInitialized: boolean,
};
type Getters = {
  recipes(state: State): Recipe[] | null,
  aliments(state: State): Map<number, Aliment>,
  units: (state: State) => typeof state.library.units,
  get(state: State): Library,
  groups(state: State): LibraryGroup[],
  findOneRecipe(state: State): (id: number) => Recipe | null,
  categories(state: State): TCategory[],
  isLibraryInitialized: (state: State) => boolean,
};
type Mutations<S = State> = {
  [LibraryMutationTypes.SET_DATA](state: S, payload:RequireAtLeastOne<State>): void;
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, unknown>, 'commit'>;

interface Actions {
  [LibraryActionTypes.INIT_LIBRARY]({ commit }: AugmentedActionContext): Promise<boolean>;
}

const mutations: MutationTree<State> & Mutations = {
  [LibraryMutationTypes.SET_DATA](state: State, payload) {
    const keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      Object.assign(state, {
        [key as keyof State]: payload[key as keyof State],
      });
    }
  },
};

const recipeRepository = new RecipeRepository();
const categoryRepository = new CategoryRepository();
const alimentRepository = new AlimentRepository();
const allergenRepository = AllergenRepository;
const unitRepository = new UnitRepository();
const ustensilRepository = new UstensilRepository();

const state: State = {
  library: new Library(),
  isLibraryInitialized: false,
};

const actions: ActionTree<State, unknown> & Actions = {
  async [LibraryActionTypes.FETCH_RECIPE]({ commit }): Promise<boolean> {
    const { data: { data: recipe } } = await recipeRepository.find();
    commit(LibraryMutationTypes.ADD_RECIPE, recipe);
    return true;
  },
  async [LibraryActionTypes.INIT_LIBRARY](): Promise<boolean> {
    try {
      const { data: { data: iRecipes } } = await recipeRepository.find();
      const { data: { data: iCategories } } = await categoryRepository.find();
      const { data: { data: iAliments } } = await alimentRepository.find();
      const allergen = await allergenRepository.find();
      const { data: { data: iUnits } } = await unitRepository.find();
      const { data: { data: iUstensils } } = await ustensilRepository.find();
      const recipes = (iRecipes as IRecipe[]).map((recipe) => new Recipe(recipe));
      const categories = (iCategories as ICategory[]).map((category) => ({
        id: category.id,
        ...category.attributes,
      })).filter(({ isActive }) => isActive);
      const aliments = (iAliments as IAliment[]).map((aliment) => new Aliment(aliment));
      const units = (iUnits as IUnit[]).map((unit) => ({
        id: unit.id,
        ...unit.attributes,
      }));
      state.library
        .initialise(recipes, categories, aliments, units, iUstensils as IUstensil[], allergen);
      state.isLibraryInitialized = true;
      return true;
    } catch (err) {
      state.isLibraryInitialized = false;
      throw err;
    }
  },

};

const getters: GetterTree<State, unknown> & Getters = {
  get: (state) => state.library,
  isLibraryInitialized: (state) => state.isLibraryInitialized,
  groups: (state) => state.library.groups,
  categories: (state) => state.library.findCategories(),
  aliments: (state) => state.library.aliments,
  units: (state) => state.library.units,
  recipes: (state) => state.library.recipes,
  findOneRecipe(state) {
    return (id: number) => state.library.findRecipeById(id);
  },
  emptyRecipes: (state) => state.library.emptyRecipes,
};

const recipesModule: Module<State, unknown> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default recipesModule;
