
import { Browser } from '@capacitor/browser';
import { computed, defineComponent } from 'vue';
import { logoFacebook, logoInstagram, logoTwitter, logoLinkedin, logoTiktok, logoYoutube, globeOutline } from 'ionicons/icons';
import Track from '@/services/tracking/firebase';

type SocialMediaName = 'facebook' | 'instagram' | 'linkedin' | 'twitter' | 'youtube' | 'tiktok' | 'website';
type SocialMedia = {
  id: number,
  SocialMediaName: SocialMediaName,
  url: string,
};

export default defineComponent({
  name: 'AffiliateSocialMedias',
  props: {
    socialMedias: {
      type: Array<SocialMedia>,
      required: true,
    },
    affiliateCustomerName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const socialMedias = computed(() => props.socialMedias);
    const socialNameToIcon: Record<SocialMediaName, string> = {
      facebook: logoFacebook,
      instagram: logoInstagram,
      twitter: logoTwitter,
      linkedin: logoLinkedin,
      tiktok: logoTiktok,
      youtube: logoYoutube,
      website: globeOutline,
    };
    const openLink = (url: string) => {
      if (url && props.affiliateCustomerName) {
        Track.logEvent('open_social_media', { url, affiliateCustomer: props.affiliateCustomerName });
        Browser.open({ url });
      }
    };
    const socialMediasForDisplay = computed(() => socialMedias.value.map((socialMedia) => ({
      ...socialMedia,
      logo: socialNameToIcon[socialMedia.SocialMediaName],
    })));
    return { socialMediasForDisplay, openLink };
  },
});
