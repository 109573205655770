
import { defineComponent, PropType } from 'vue';
// helpers
import { get } from 'lodash';
// components
import {
  IonThumbnail,
  useIonRouter,
} from '@ionic/vue';
// classes
import Foodlist from '@/typings/classes/foodlist.class';
// icons
import { ellipsisVertical } from 'ionicons/icons';

export default defineComponent({
  name: 'RecipeFoodlistItem',
  components: {
    IonThumbnail,
  },
  props: {
    foodlist: {
      type: Object as PropType<Foodlist>,
      default: () => ({ title: '', recipes: [] }),
    },
    recipeId: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:foodlist'],
  setup() {
    const router = useIonRouter();
    return {
      ellipsisVertical,
      router,
      error: {} as unknown,
    };
  },
  computed: {
    recipesLength() {
      return this.foodlist?.recipes.length ?? 0;
    },
    haveRecipeInFoodlist() {
      return this.foodlist?.recipes.includes(this.recipeId) ?? false;
    },
    getUrl(): string {
      const recipeId: number = +get(this.foodlist, 'recipes[0]', 0);
      const recipe = this.$store.getters['library/findOneRecipe'](recipeId);
      const imgUrl = get(recipe, 'imageSource', null);
      if (imgUrl) return `https://api.petitcitron.fr${imgUrl}`;
      return '/assets/logo/logo-small.png';
    },
  },
  methods: {
    async toggleRecipeInFoodlist() {
      const response = await this.$store.dispatch('foodlists/toggleRecipeInFoodlist', {
        foodlist: this.foodlist.id,
        recipe: this.recipeId,
      });
      this.$emit('update:foodlist', response);
    },
  },
});
