
import { defineComponent, Ref, ref } from 'vue';
import {
  IonList, IonItem, IonCheckbox, IonLabel,
} from '@ionic/vue';
import { close } from 'ionicons/icons';
import { useStore } from '@/store';
import { Library } from '@/typings/classes/library.class';
import { FilterClass } from '@/typings/classes/classes';
import Filters from '@/typings/classes/filters.class';

export default defineComponent({
  name: 'LibraryFiltersModal',
  components: {
    IonList, IonItem, IonCheckbox, IonLabel,
  },
  props: {
    resultsLength: {
      type: Number,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    keywords: {
      type: String,
      required: true,
    },
  },
  emits: ['close:modal'],
  setup(props, { emit }) {
    const store = useStore();
    const library: Library = store.getters['library/get'];
    const simulatedResultLength = ref(props.resultsLength);
    const closeModal = () => { emit('close:modal'); };

    const libraryFilters: Ref<Filters> = ref(library.filters);
    const group = libraryFilters.value.findGroupByKey(props.itemId);
    const filters = libraryFilters.value.findFiltersByGroupKey(props.itemId);

    return {
      group,
      library,
      filters,
      simulatedResultLength,
      closeModal,
      close,
    };
  },
  methods: {
    toggle(filter: FilterClass) {
      filter.toggleIsActive();
      this.simulatedResultLength = this.library.getSimulationResearchLength(
        this.keywords,
      );
    },
  },
});
