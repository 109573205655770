import AxiosInstance from '@/tmp/src/api/AxiosInstance';

const domains = {
  development: 'http://localhost:1337/api',
  staging: 'https://api.staging.petitcitron.fr/api',
  production: 'https://api.petitcitron.fr/api',
};

const baseDomain = domains[import.meta.env.MODE];
console.log('PC -- baseDomain', baseDomain);
const HttpInstance = new AxiosInstance(baseDomain);

export default HttpInstance;
