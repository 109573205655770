/* eslint-disable camelcase */
import { IUser } from '@/typings/interfaces';
import { getBMI } from './bmi';
import { devineFormula, getIdealWeight, mifflinStJeor } from './idealWeight';
import { getBasalMetabolism } from './basalMetabolism';
import { getPhysicalActivityMultiplier } from './physicalActivity';
import CaloriesDeficit from './caloriesDeficit';
import Program from './program';

/**
 * This function returns the total energy intake of the user
 * @param user
 */
function getTotalEnergyIntake(user: IUser) {
  // PAM is Physical Activity Multiplier
  const PAM = getPhysicalActivityMultiplier(user);
  // BM is Basal Metabolism
  const BM = getBasalMetabolism(user);
  return BM.reduce((acc, basalMetabolisms) => {
    acc.push(...basalMetabolisms.map((basalMetabolism) => basalMetabolism * PAM));
    return acc;
  }, []);
}

// 9 calories pour 1 g de lipides
// 4 calories pour 1 g de glucides ou de protéines
// proteins (10-35% of your total energy),
// carbohydrates (45-65% of your total energy),
// fats (20-35% of your total energy).
function getRecommendedMacronutrientsWithMinMax(user: IUser) {
  const context = Program.getContext(user);
  const recommendedCaloriesIntake = CaloriesDeficit.getCaloriesIntake(context);
  const recommendation: {
    [key:string]: { min: number; max: number },
  } = {
    protein: {
      min: (recommendedCaloriesIntake / 4) * 0.1,
      max: (recommendedCaloriesIntake / 4) * 0.35,
    },
    carbohydrate: {
      min: (recommendedCaloriesIntake / 4) * 0.45,
      max: (recommendedCaloriesIntake / 4) * 0.65,
    },
    fat: {
      min: (recommendedCaloriesIntake / 9) * 0.2,
      max: (recommendedCaloriesIntake / 9) * 0.35,
    },
  };
  return recommendation;
}

function getRecommendedMacronutriments(user: IUser) {
  const totalEnergyIntake = getTotalEnergyIntake(user);
  const recommendedMacronutriments = totalEnergyIntake.map((energy) => ({
    energy_reg_kcal: energy.toFixed(0),
    carbohydrate: ((0.55 * energy) / 4).toFixed(0),
    protein: ((0.15 * energy) / 4).toFixed(0),
    fat: ((0.30 * energy) / 9).toFixed(0),
  })).filter(({
    energy_reg_kcal, carbohydrate, protein, fat,
  }) => [energy_reg_kcal, carbohydrate, protein, fat].map(Number).every((number) => number !== 0));
  recommendedMacronutriments.sort(
    ({ energy_reg_kcal: a }, { energy_reg_kcal: b }) => parseInt(a, 10) - parseInt(b, 10),
  );
  const middle = Math.floor(recommendedMacronutriments.length / 2);
  return recommendedMacronutriments.length % 2 !== 0
    ? recommendedMacronutriments[middle]
    : recommendedMacronutriments[middle - 1];
}

class Nutrition {
  static getBMI = getBMI;

  static getPhysicalActivityMultiplier = getPhysicalActivityMultiplier;

  /**
   * Ideal weight is the weight that a person should have according to their height.
   * @equation The Devine formula is used to calculate the ideal weight and gender.
   */
  static getIdealWeight = devineFormula;

  /**
   * Basal metabolic rate is the amount of energy a human body uses when it is completely at rest.
   * @equation The Mifflin-St Jeor equation is used to calculate the basal metabolic rate.
   */
  static getBasalMetabolicRate = mifflinStJeor;

  static getTotalEnergyIntake = getTotalEnergyIntake;

  static getRecommendedMacronutriments = getRecommendedMacronutriments;

  static getRecommendedMacronutrientsWithMinMax = getRecommendedMacronutrientsWithMinMax;
}

export {
  Nutrition,
  getBMI,
  getPhysicalActivityMultiplier,
  getIdealWeight,
  getBasalMetabolism,
  getTotalEnergyIntake,
  getRecommendedMacronutriments,
  getRecommendedMacronutrientsWithMinMax,
};
