/* eslint-disable max-lines-per-function */

const [_MALE, FEMALE] = [0, 1];

type CaloriesDeficitContext = {
  age: number;
  activityFactor: number;
  calories: number;
  height: number;
  weight: number;
  weightLossPace: number;
  dailyCalorieDeficit: number;
  sex: number;
  targetWeight: number;
};

type CaloriesIntakeKeys = 'deficit' | 'calories' | 'carbs' | 'fats' | 'proteins';
type _CaloriesIntake = { [key in CaloriesIntakeKeys]: number; };

//
// functions and data
//

const sources = [
  { name: 'carbs', value: 60 },
  { name: 'fats', value: 27.5 },
  { name: 'proteins', value: 12.5 },
  { name: 'calories', value: 100 },
];

function roundToPrecision(number: number, precision: number): number {
  // FIX: number can be a string here ! Typing error
  const str = number.toPrecision(precision).toString();
  return Number(str);
}

function prepareWeightPerWeek(weight: number, weightLossPace: number, targetWeight: number) {
  let weekName: string;

  const loss = weight > targetWeight;
  const weightDifference = loss ? weight - targetWeight : targetWeight - weight;
  const weeksToTargetWeight = Math.ceil(weightDifference / weightLossPace);

  // initialize array with 4 weeks rebalancing diet
  const weightPerWeekKg: [string, number][] = [
    ['Rebalancing diet | Week 1', weight],
    ['Rebalancing diet | Week 2', weight],
    ['Rebalancing diet | Week 3', weight],
    ['Rebalancing diet | Week 4', weight],
  ];

  const iterations = Math.ceil(weeksToTargetWeight / 10);
  let currentWeight = weight;
  let currentWeekToTargetWeight = weeksToTargetWeight;
  for (let i = 0; i < iterations; i += 1) {
    let weekWeightKg = 0;
    for (let j = 0; j <= currentWeekToTargetWeight && j < 10; j += 1) {
      weekName = `Weight loss | Week ${(j + 1)}`;
      weekWeightKg = currentWeight + (loss ? -j : j) * weightLossPace;
      weightPerWeekKg.push([weekName, roundToPrecision(weekWeightKg, 4)]);
    }
    currentWeight = weekWeightKg;
    currentWeekToTargetWeight -= 10;
    weightPerWeekKg.push(
      ['Stabilisation diet | Week 1', weekWeightKg],
      ['Stabilisation diet | Week 2', weekWeightKg],
      ['Stabilisation diet | Week 3', weekWeightKg],
      ['Stabilisation diet | Week 4', weekWeightKg],
    );
  }


  return weightPerWeekKg;
}

function getCaloriesInCurrentWeek(
  activityFactor: number,
  age: number,
  height: number,
  weight: number,
  sex: number,
  weightLossPace: number,
): number {
  // MIFFLIN-ST JEOR EQUATION
  const bodyMeasuresModificator = 10 * weight + 6.25 * height * 100;
  if (sex === FEMALE) {
    return (bodyMeasuresModificator - 5 * age - 161) * activityFactor - weightLossPace * 1000;
  }
  return (bodyMeasuresModificator - 5 * age + 5) * activityFactor - weightLossPace * 1000;
}

function prepareCaloriesIntakePerWeek(
  activityFactor: number,
  age: number,
  height: number,
  weightPerWeekKg: [string, number][],
  sex: number,
  weightLossPace: number,
) {
  const caloriesIntakePerWeek: [string, number, number, number, number][] = [];

  // create bars for each week of loosing weight
  weightPerWeekKg.forEach((week) => {
    const [weekName, weekWeightKg] = week;
    const weekCalories = getCaloriesInCurrentWeek(
      activityFactor,
      age,
      height,
      weekWeightKg,
      sex,
      weekName.includes('Weight loss') ? weightLossPace : 0,
    );
    // const caloriesDetail = sources.reduce(
    //   (accumulator, { name, value }) => {
    //     accumulator[name as CaloriesIntakeKeys] = Math.round((value * weekCalories) / 100);
    //     return accumulator;
    //   },
    //   { deficit: 500, calories: weekCalories, carbs: 0, fats: 0, proteins: 0 } as CaloriesIntake,
    // );
    // [index fats proteins carbs calories]
    caloriesIntakePerWeek.push(
      sources.reduce(
        (accumulator, source) => {
          accumulator.push(Math.round((source.value * weekCalories) / 100));
          return accumulator;
        },
        [weekName] as unknown as [string, number, number, number, number],
      ),
    );
  });

  return caloriesIntakePerWeek;
}

class CaloriesDeficit {
  age: number;
  sex: number;
  activityFactor: number;

  height: number;
  weight: number;
  targetWeight: number;

  calories: number;

  weightLossPace: number;
  weightPerWeek: [string, number][];
  dailyCalorieDeficit: number;
  caloriesIntakePerWeek: [string, number, number, number, number, number?][];

  constructor(user: CaloriesDeficitContext) {
    this.age = user.age;
    this.activityFactor = user.activityFactor;
    this.calories = user.calories;
    this.height = user.height;
    this.weight = user.weight;
    this.weightLossPace = user.weightLossPace;
    this.dailyCalorieDeficit = user.dailyCalorieDeficit;
    this.sex = user.sex;
    this.targetWeight = user.targetWeight;

    // data for chart #3 - weight loss at week rate
    this.weightPerWeek = prepareWeightPerWeek(
      this.weight,
      this.weightLossPace,
      this.targetWeight,
    );
    // data for chart #2 - calories intake separated by source type (fat, proteins, carbs)
    this.caloriesIntakePerWeek = prepareCaloriesIntakePerWeek(
      this.activityFactor,
      this.age,
      this.height,
      this.weightPerWeek,
      this.sex,
      this.weightLossPace,
    );

    for (let i = 0; i < this.weightPerWeek.length; i += 1) {
      const weightPerWeekValue = this.weightPerWeek[i][1];
      const caloriesNew = (
        (10 * weightPerWeekValue + 6.25 * this.height * 100 - 5 * this.age + 5)
          * this.activityFactor) * (1 - this.sex)
        + ((10 * weightPerWeekValue + 6.25 * this.height * 100 - 5 * this.age - 161)
        * this.activityFactor) * (this.sex);
      this.caloriesIntakePerWeek[i].push(caloriesNew);
    }
  }

  static getCaloriesIntake(user: Pick<CaloriesDeficitContext, 'activityFactor' | 'age' | 'height' | 'weight' | 'sex'>) {
    return getCaloriesInCurrentWeek(
      user.activityFactor,
      user.age,
      user.height,
      user.weight,
      user.sex,
      0,
    );
  }
}

export default CaloriesDeficit;
