
import { computed, defineComponent, ref } from 'vue';
import RateWithStars from '@/components/common/RateWithStars.vue';
import useRatingStore from '@/stores/rating';
import { useStore } from '@/store/index';

type DismissModalData = {
  role?: string;
  data?: {
    rating: number;
    comment: string;
  };
};

export default defineComponent({
  name: 'RecipeRateModal',
  components: { RateWithStars },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    recipeId: {
      type: Number,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  emits: { dismiss: (event: DismissModalData) => event },
  setup(props, { emit }) {
    const ratingStore = useRatingStore();
    const currentRating = ref(ratingStore.findOneByRecipeId(props.recipeId));
    const rating = ref(currentRating.value?.attributes.value ?? 4);
    const comment = ref(currentRating.value?.attributes.comment ?? '');
    const store = useStore();
    const firstname = computed(() => store.getters['user/firstname'] as string);
    const dismissModal = ({ role, data }: DismissModalData): void => {
      emit('dismiss', { role, data });
    };

    const updateRating = async () => {
      await ratingStore.updateRating(currentRating.value?.id ?? 0, {
        recipe: props.recipeId,
        value: rating.value,
        comment: comment.value,
      });
      dismissModal({ role: 'submit', data: { rating: rating.value, comment: comment.value } });
    };
    const submitRating = async () => {
      await ratingStore.createRating({
        recipe: props.recipeId,
        value: rating.value,
        comment: comment.value,
        firstname: firstname.value,
      });
      dismissModal({ role: 'submit', data: { rating: rating.value, comment: comment.value } });
    };

    return {
      rating,
      comment,
      currentRating,
      dismissModal,
      submitRating,
      updateRating,
    };
  },
  methods: {
    buttonAction() {
      if (this.action === 'create') {
        this.submitRating();
      } else {
        this.updateRating();
      }
    },
  },
});
