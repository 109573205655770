import { defineStore } from 'pinia';
import ReferralRepository from '@/repositories/referral/referralRepository';

const useReferralStore = defineStore({
  id: 'referral',
  actions: {
    bindRefereeToReferralWith(code: string) {
      return ReferralRepository.assign({ referralCode: code });
    },
  },
});

export default useReferralStore;
