import moment from 'moment';
import { IMacronutriments, IMenu } from '@/typings/interfaces';
import { IMeal, Meal } from './meal.class';
import { EMealTime, EMealType } from '../enums';
import { Library } from './library.class';

type TMenuSetMealPayload = {
  mealId: null | number;
  date?: string,
  mealType?: EMealType,
  mealTime?: EMealTime,
  recipeId: number | null,
  servings: number,
  aliments?: { quantity: number, aliment: number }[],
};

class Menu {
  id: number;
  startDate: string;
  endDate: string;
  meals: Meal[] = [];

  constructor(menu: IMenu) {
    this.id = 0;
    this.startDate = '';
    this.endDate = '';
    this.create(menu);
  }

  create(menu: IMenu) {
    const {
      startDate,
      endDate,
      meals,
    } = menu.attributes;

    this.id = menu.id;
    this.startDate = startDate;
    this.endDate = endDate;
    this.meals = meals.map((meal) => new Meal(meal));
  }

  update(menu: IMenu) {
    if (menu.id === this.id || this.id === -1) {
      this.create(menu);
    }
  }

  getMeals(): Meal[] {
    return this.meals
      .filter((meal) => meal.starterCourse || meal.mainCourse || meal.dessert);
  }

  getMealsByDay(date: string): Meal[] {
    return this.meals
      .filter((meal) => moment(meal.date).isSame(date, 'day')) ?? [];
  }

  findMealById(id: number): Meal | null {
    return this.meals.find((meal) => meal.id === id) ?? null;
  }

  isEmptyByDay(date: string): boolean {
    return this.getMealsByDay(date).length === 0;
  }

  isMenuBetweenDates(date: string): boolean {
    if (moment(date).isSame(this.endDate, 'day')
    || moment(date).isSame(this.startDate, 'day')) {
      return true;
    }
    return moment(date).isBetween(this.startDate, this.endDate);
  }

  setMeal(payload: TMenuSetMealPayload): void {
    const {
      mealId, date, mealType = 'mainCourse', mealTime, recipeId, servings, aliments = [],
    } = payload;
    if (mealId === null) {
      const newMealPayload: IMeal = {
        id: null,
        starterCourse: null,
        mainCourse: null,
        dessert: null,
        aliments: aliments.map((aliment) => ({
          quantity: aliment.quantity,
          aliment: { data: { id: aliment.aliment } },
        })),
        mealTime,
        date,
        servings,
        status: 'pending',
      };
      if (recipeId) {
        Object.assign(newMealPayload, { [mealType]: recipeId });
      }
      const newMeal = new Meal(newMealPayload);
      this.meals.push(newMeal);
    } else {
      const meal = this.meals.find(({ id }) => mealId === id);
      if (!meal) return;
      meal[mealType] = recipeId;
      meal.servings = servings;
    }
  }

  getNutrimentsByDay(date: string, library: Library): IMacronutriments {
    const { recipes } = library;
    const alimentsMap = library.aliments;
    const meals = this.getMealsByDay(date);
    const nutriments: IMacronutriments = {
      id: 0,
      carbohydrate: 0,
      energy_reg_kcal: 0,
      fat: 0,
      protein: 0,
    };
    meals.forEach((meal) => {
      const {
        starterCourse, mainCourse, dessert, aliments,
      } = meal;
      const recipesId = [starterCourse, mainCourse, dessert]
        .filter((recipe) => recipe !== null);
      aliments.forEach((al) => {
        const { quantity, aliment: alimentId } = al;
        const aliment = alimentsMap.get(alimentId);
        if (aliment) {
          const { macronutrients: m } = aliment;
          nutriments.carbohydrate += (m.carbohydrates * quantity);
          nutriments.energy_reg_kcal += (aliment.calories * quantity);
          nutriments.fat += (m.fat * quantity);
          nutriments.protein += (m.proteins * quantity);
        }
      });
      recipesId.forEach((recipeId) => {
        const recipe = recipes.find((r) => r.id === recipeId);
        if (recipe && recipe.macronutriments) {
          const {
            carbohydrate,
            energy_reg_kcal: calories,
            fat,
            protein,
          } = recipe.macronutriments;
          nutriments.carbohydrate += carbohydrate;
          nutriments.energy_reg_kcal += calories;
          nutriments.fat += fat;
          nutriments.protein += protein;
        }
      });
    });
    return nutriments;
  }

  get isEmpty(): boolean {
    return this.meals.length === 0;
  }

  get describeForApi() {
    if (this.id === -1) {
      return { startDate: this.startDate, endDate: this.endDate };
    }
    return {
      id: this.id,
      startDate: this.startDate,
      endDate: this.endDate,
      meals: this.meals.map((meal) => meal.describeForApi),
    };
  }

  get recipesIdWithServings(): Map<number, number> {
    const recipeIdWithServings = new Map();
    this.meals.forEach((meal) => {
      const {
        starterCourse, mainCourse, dessert, servings,
      } = meal;
      const recipesId = [starterCourse, mainCourse, dessert]
        .filter((recipe) => recipe !== null);
      recipesId.forEach((recipeId) => {
        const currentServings = recipeIdWithServings.get(recipeId) ?? 0;
        recipeIdWithServings.set(recipeId, currentServings + servings);
      });
    });
    return recipeIdWithServings;
  }

  deleteMealById(id: number): void {
    this.meals = this.meals.filter((meal) => meal.id !== id);
  }
}

export default Menu;
