import { GetterTree } from 'vuex';
import moment from 'moment';
import Menu from '@/typings/classes/menu.class';
import { State } from './state';

type Getters = {
  getClasses(state: State): Menu[],
};

const getters: GetterTree<State, unknown> & Getters = {
  getClasses: (state) => state.menusClasses,
  getThisWeekMenu: (state) => {
    const today = moment().toISOString();
    return state.menusClasses.find((menu) => menu.isMenuBetweenDates(today));
  },
  findById: (state) => (id: number) => state.menusClasses.find((menu) => menu.id === id) ?? null,
};

export { Getters, getters };
