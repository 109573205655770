
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import useCurrentShoppingSessionStore from '@/stores/shopping/currentShoppingSessionStore';

export default defineComponent({
  name: 'ShoppingAddressForm',
  setup() {
    const currentShoppingSessionStore = useCurrentShoppingSessionStore();
    const { position } = storeToRefs(currentShoppingSessionStore);
    return { position };
  },
});
