

import { defineComponent, PropType } from 'vue';
import { chevronForwardOutline } from 'ionicons/icons';
import CarouselVue from '@/components/common/Carousel.vue';

export default defineComponent({
  name: 'LibrarySwiper',
  components: { CarouselVue },
  props: {
    showSeeMoreAction: {
      type: Boolean,
      default: true,
    },
    itemsId: {
      type: Object as PropType<number[]>,
      default: () => ([] as unknown as PropType<number[]>),
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    isRectangle: {
      type: Boolean,
      default: false,
    },
    title: { type: String, default: 'Dernières recettes' },
    seeMoreFunction: {
      type: Function,
      default: (payload: MouseEvent) => payload,
    },
  },
  setup(props) {
    const seeMoreAction = (payload: MouseEvent) => props.seeMoreFunction(payload);
    return { chevronForwardOutline, seeMoreAction };
  },
});
