// store.ts
import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';

import menus from './menus';
import user from './user';
import library from './library';
import grocery from './grocery';
import foodlists from './foodlists';
import advices from './advices';

// define your typings for the store state
export interface State {
  code: string
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol('Injection key for store state');

export const store = createStore<State>({
  state: { code: '' },
  getters: { code: (state) => state.code },
  actions: { setcode({ commit }, payload) { commit('setcode', payload); } },
  mutations: { setcode(state, payload) { state.code = payload; } },
  modules: {
    foodlists,
    grocery,
    library,
    user,
    menus,
    advices,
  },
});

export function useStore() {
  return baseUseStore(key);
}
