import { ApiObject } from '../../api';
import { ApiGamificationEventGamificationEvent } from '../../strapi-generation/schemas';

export enum EventKeys {
  DAILY_CONNECTION = 'DAILY_CONNECTION',
  RECIPE_VALIDATION = 'RECIPE_VALIDATION',
  FACEBOOK_CONNECTION = 'FACEBOOK_CONNECTION',
  TIKTOK_CONNECTION = 'TIKTOK_CONNECTION',
  INSTAGRAM_CONNECTION = 'INSTAGRAM_CONNECTION',
  DAILY_ADVICE = 'DAILY_ADVICE',
}

export default class GamificationEvent implements ApiObject<ApiGamificationEventGamificationEvent> {
  [key: string]: any;
  id: number;
  title: string;
  description: string;
  order: number;
  earning: number;
  picture: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  key: EventKeys;
}
