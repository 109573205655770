
import { defineComponent, computed, ComputedRef } from 'vue';
import { useIonRouter } from '@ionic/vue';
import CardImageWithTextUnder from '@/atomic/molecules/CardImageWithTextUnder.vue';
import RecipeBadgeDetailsOrganism from '@/components/recipes/RecipeBadgeDetailsOrganism.vue';
import { useStore } from '@/store';

import Recipe from '@/typings/classes/recipe.class';
import { get } from 'lodash';

export default defineComponent({
  name: 'RecipeRectangleCard',
  components: { RecipeBadgeDetailsOrganism, CardImageWithTextUnder },
  props: {
    recipeId: {
      type: Number,
      default: 1,
    },
    haveOption: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useIonRouter();
    const store = useStore();
    const recipe: ComputedRef<Recipe> = computed(() => store.getters['library/findOneRecipe'](props.recipeId));
    return { router, recipe };
  },
  computed: {
    getUrl(): string {
      const imgUrl = get(this.recipe, 'imageSource', '');
      if (imgUrl) return `https://api.petitcitron.fr${imgUrl}`;
      return imgUrl;
    },
  },
  methods: {
    goToRecipe() {
      const pathBase = `/recipe/${this.recipe.id}`;
      if (this.$route.query) {
        const append = Object.entries(this.$route.query)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
        this.router.push(`${pathBase}?${append}`);
      } else {
        this.router.push(pathBase);
      }
    },
  },
});
