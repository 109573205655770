/* eslint @typescript-eslint/no-shadow: ["error", { "allow": ["state"] }] */
import { Module } from 'vuex';

import { State, state } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const userModule: Module<State, unknown> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default userModule;
