import { last as _last } from 'lodash';
import { IUser } from '@/typings/interfaces';
import { getAge } from './helpers';
import { getIdealWeight } from './idealWeight';

function blackAndAl(weight: number, height: number, age: number, c: number) {
  return c * (weight ** 0.48) * (height ** 0.5) * (age ** -0.13) * (1000 / 4.1844);
}

function maleBasalMetabolism(weight: number, height: number, age: number) {
  const hAndB = (13.707 * weight) + (492.3 * height) - (6.673 * age) + 77.607;
  const bAndAl = blackAndAl(weight, height, age, 1.083);
  return [hAndB, bAndAl];
}
function femaleBasalMetabolism(weight: number, height: number, age: number) {
  const hAndB = (9.740 * weight) + (172.9 * height) - (4.737 * age) + 667.051;
  const bAndAl = blackAndAl(weight, height, age, 0.963);
  return [hAndB, bAndAl];
}

function getBasalMetabolism(user: IUser) {
  const {
    weights,
    height: h,
    birthdate,
  } = user;
  const age = getAge(birthdate);
  const height = h / 100;
  const idealWeights = getIdealWeight(user);
  const currentWeight = (_last(weights)?.value ?? 0);

  const functionToUse = user.biologicalSex === 'F' ? femaleBasalMetabolism : maleBasalMetabolism;
  // height is in meters instead of centimeters here
  return [
    functionToUse(currentWeight, height, age),
    functionToUse(idealWeights[0], height, age),
    functionToUse(idealWeights[1], height, age),
  ];
}

// eslint-disable-next-line import/prefer-default-export
export { getBasalMetabolism };
