
import { Ref, ref, defineComponent, onMounted } from 'vue';
import { modalController } from '@ionic/vue';
import { shuffle } from 'lodash';

import '@/assets/theme/effect-cards.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import cards from '@/plugins/animation/swiper/cards';

import useCookerStore from '@/stores/cookers';

import { ICooker } from '@/typings/interfaces';

import LibraryVideoModal from './modals/LibraryVideoModal.vue';

export default defineComponent({
  name: 'LibraryCookerCarousel',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const cookerStore = useCookerStore();
    const { findCookers } = cookerStore;
    const cookers: Ref<ICooker[]> = ref([]);
    const videoPlayersState: Ref<boolean[]> = ref([]);
    const activeIndex = ref<number>(1);

    onMounted(async () => {
      const cookersToPush: ICooker[] = await findCookers() as ICooker[];
      videoPlayersState.value = new Array(cookersToPush.length).fill(false);
      cookers.value = shuffle(cookersToPush);
    });

    return {
      findCookers,
      videoPlayersState,
      modules: [cards],
      cookers,
      activeIndex,
    };
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: LibraryVideoModal,
        id: 'LibraryVideoModal',
        componentProps: {
          url: this.cookers[this.activeIndex].video.url,
          username: this.cookers[this.activeIndex].username,
          recipe: this.cookers[this.activeIndex].recipe,
          picture: this.cookers[this.activeIndex].recipe.picture?.data.attributes.url,
        },
      });

      modal.present();
    },
    onSlideChange(event: any, index: number) {
      this.activeIndex = index;
    },
    getCookerThumbnail(cooker: ICooker) {
      const domains = {
        development: 'http://localhost:1337',
        staging: 'https://api.staging.petitcitron.fr',
        production: 'https://api.petitcitron.fr',
      };
      const baseDomain = domains[import.meta.env.MODE];
      return `${baseDomain}${cooker.thumbnail.url}`;
    },
  },
});
