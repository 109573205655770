
import { defineComponent, ref } from 'vue';
import {
  IonList, IonItem, IonCheckbox, IonLabel,
} from '@ionic/vue';
import { chevronBack } from 'ionicons/icons';
import { useStore } from '@/store';
import { Library } from '@/typings/classes/library.class';
import { FilterClass } from '@/typings/classes/classes';

export default defineComponent({
  name: 'LibraryFiltersModal',
  components: {
    IonList, IonItem, IonCheckbox, IonLabel,
  },
  props: {
    resultsLength: {
      type: Number,
      required: true,
    },
    keywords: {
      type: String,
      required: true,
    },
  },
  emits: ['close:modal'],
  setup(props, { emit }) {
    const store = useStore();
    const library: Library = store.getters['library/get'];
    const simulatedResultLength = ref(props.resultsLength);
    const closeModal = () => { emit('close:modal'); };

    const libraryFilters = ref(Object.entries(library.filters.reduceFiltersByGroup()));
    const findGroupByKey = (key: string) => library.filters.findGroupByKey(key);
    return {
      library,
      libraryFilters,
      simulatedResultLength,
      chevronBack,
      closeModal,
      findGroupByKey,
    };
  },
  methods: {
    toggle(filter: FilterClass): void {
      filter.toggleIsActive();
      this.simulatedResultLength = this.library.getSimulationResearchLength(
        this.keywords,
      );
    },
  },
});
