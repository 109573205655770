
import { defineComponent } from 'vue';
import { useIonRouter } from '@ionic/vue';

export default defineComponent({
  name: 'ReferralButton',
  setup() {
    const router = useIonRouter();
    return { router };
  },
  methods: {
    goToReferralView() {
      this.router.push('/referral');
    },
  },
});
