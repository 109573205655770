/* eslint @typescript-eslint/no-shadow: ["error", { "allow": ["state"] }] */
import {
  Module,
  GetterTree,
  MutationTree,
  ActionTree,
  ActionContext,
} from 'vuex';
import AdviceRepository from '@/repositories/advice';
import { IAdvice, IImage } from '@/typings/interfaces';
import moment from 'moment';
import { AdviceActionTypes, AdviceMutationTypes, ADVICE_ORDER } from './advices-types';

const repository = new AdviceRepository();

enum AdviceOrder {
  'OTHER',
  'WEIGHT_LOSS',
  'HEALTH',
  'HOW_TO',
  'SPORT',
  'ENVIRONMENT',
}

type AdviceCategory = {
  id: number,
  attributes: { title: string, picture: { data: IImage | null } }
};

type State = {
  advices: IAdvice[],
  categories: AdviceCategory[],
};

type Getters = {
  get(state: State): IAdvice[],
  categories(state: State): AdviceCategory[],
  getRecommandation(state: State): IAdvice,
  getRecommandationByObjective(state: State, getters: Getters)
  : (objective: string | null) => IAdvice,
  findAdviceById(state: State): (id: number) => IAdvice | undefined,
  findAdvicesByCategoryId(state: State): (id: number) => IAdvice[],
  findCategoryById(state: State): (id: number) => AdviceCategory | undefined,
};

type Mutations<S = State> = {
  [AdviceMutationTypes.CLEAR](state: S): void;
  [AdviceMutationTypes.SET_DATA](state: S, payload: IAdvice): void;
  [AdviceMutationTypes.SET_ADVICES](state: S, payload: IAdvice[]): void;
  [AdviceMutationTypes.ADD_ADVICE](state: S, payload: IAdvice): void;
  [AdviceMutationTypes.SET_CATEGORIES]
  (state: S, payload: AdviceCategory[]): void;
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, unknown>, 'commit'>;

interface Actions {
  [AdviceActionTypes.CLEAR]({ commit }: AugmentedActionContext)
  : Promise<boolean>;
  [AdviceActionTypes.FETCH_ADVICE]({ commit }: AugmentedActionContext, id: number)
  : Promise<boolean>;
  [AdviceActionTypes.FETCH_ADVICES]({ commit }: AugmentedActionContext)
  : Promise<boolean>;
  [AdviceActionTypes.FETCH_CATEGORIES]({ commit }: AugmentedActionContext)
  : Promise<boolean>;
}


const state: State = {
  advices: [],
  categories: [],
};

const getters: GetterTree<State, unknown> & Getters = {
  get: (state) => state.advices,
  categories: (state) => state.categories,
  getRecommandation: (state) => {
    const baseDay = moment('03-09-2022', 'DD-MM-YYYY');
    const today = moment();
    const diff = Math.abs(baseDay.diff(today, 'days')) % (ADVICE_ORDER.length);
    const index = ADVICE_ORDER[diff];
    return state.advices.find((advice) => advice.id === index) || state.advices[0];
  },
  getRecommandationByObjective: (state, g) => (objective: string | null) => {
    if (objective === 'weightLoss') {
      return g.getRecommandation as unknown as IAdvice;
    }
    const baseDay = moment('03-09-2022', 'DD-MM-YYYY');
    const today = moment();
    const diff = Math.abs(baseDay.diff(today, 'days')) % (ADVICE_ORDER.length);
    let index = diff;
    while (index < ADVICE_ORDER.length) {
      const adviceId = ADVICE_ORDER[index];
      const advice = state.advices.find((a) => a.id === adviceId);
      if (advice) {
        const { categories } = advice.attributes;
        if (categories && categories.data.every(({ id }) => id !== AdviceOrder.WEIGHT_LOSS)) {
          return advice;
        }
      }
      index += 1;
    }
    return state.advices[0];
  },
  findAdviceById: (state) => (id: number) => state.advices.find((advice) => advice.id === id),
  findAdvicesByCategoryId: (state) => ((id: number) => state.advices
    .filter((advice) => advice.attributes.categories.data.some((category) => category.id === id))),
  findCategoryById: (state) => (id: number) => state.categories
    .find((category) => category.id === id),
};

const mutations: MutationTree<State> & Mutations = {
  [AdviceMutationTypes.SET_DATA](state: State, payload: IAdvice) {
    const keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      Object.assign(state, {
        [key as keyof IAdvice]: payload[key as keyof IAdvice],
      });
    }
  },
  // eslint-disable-next-line max-lines-per-function
  [AdviceMutationTypes.SET_ADVICES](state: State, payload: IAdvice[]) {
    state.advices = payload;
  },
  [AdviceMutationTypes.SET_CATEGORIES](state: State, payload) {
    const order = [
      AdviceOrder.HOW_TO, // 3 Prise en main de Petit Citron
      AdviceOrder.WEIGHT_LOSS, // 1 Perte de poids
      AdviceOrder.HEALTH, // 2 Santé
      AdviceOrder.SPORT, // 4 Sport
      AdviceOrder.ENVIRONMENT, // 5 Environnement
    ];
    state.categories = order
      .map((id) => payload.find((category) => category.id === id) as AdviceCategory);
  },
  [AdviceMutationTypes.ADD_ADVICE](state: State, payload: IAdvice) {
    const index = state.advices.findIndex((advice) => advice.id === payload.id);
    if (index === -1) {
      state.advices.push(payload);
    } else {
      state.advices[index] = payload;
    }
  },
  [AdviceMutationTypes.CLEAR](state: State) {
    state.advices = [];
  },
};

const actions: ActionTree<State, unknown> & Actions = {

  async [AdviceActionTypes.FETCH_ADVICE]({ commit }, payload): Promise<boolean> {
    const { data: { data: advice } } = await repository.findOne(payload.toString());
    commit(AdviceMutationTypes.ADD_ADVICE, advice as IAdvice);
    return true;
  },
  async [AdviceActionTypes.FETCH_ADVICES]({ commit, dispatch }): Promise<boolean> {
    const { data: { data: advices } } = await repository.find();
    commit(AdviceMutationTypes.SET_ADVICES, advices as IAdvice[]);

    await dispatch(AdviceActionTypes.FETCH_CATEGORIES);
    return true;
  },
  async [AdviceActionTypes.FETCH_CATEGORIES]({ commit }): Promise<boolean> {
    const { data: { data: categories } } = await repository.findCategories();
    categories.sort(({ id: a }, { id: b }) => a - b);
    commit(AdviceMutationTypes.SET_CATEGORIES, categories);
    return true;
  },
  async [AdviceActionTypes.CLEAR]({ commit }) : Promise<boolean> {
    commit(AdviceMutationTypes.CLEAR, undefined);
    return true;
  },

};

const adviceModule: Module<State, unknown> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default adviceModule;
