
import { computed, defineComponent, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { chevronForwardOutline } from 'ionicons/icons';
import { useStore } from '@/store';
import { IAdvice, IImage } from '@/typings/interfaces';
import { useIonRouter } from '@ionic/vue';


export default defineComponent({
  name: 'ProfileAdviceCarousel',
  components: { Swiper, SwiperSlide },
  props: {
    advices: {
      type: Array<number>,
      default: [65, 23, 30, 61, 5],
    },
  },
  setup(props) {
    const store = useStore();
    const router = useIonRouter();
    const advicesSorted = computed(() => (store.getters['advices/get'] as IAdvice[])
      .filter((advice) => props.advices.includes(advice.id)));
    const width = ref(`calc(${100 / 1.1}vw - 32px)`);
    return { width, advicesSorted, router, chevronForwardOutline };
  },
  methods: {
    getUrl(picture: { data: IImage | null }) {
      if (picture.data) return `https://api.petitcitron.fr${picture.data.attributes.url}`;
      return 'https://dummyimage.com/160x210/000/aaa';
    },
  },
});
