
import { defineComponent, Ref, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { chevronDown, filterOutline } from 'ionicons/icons';
import { useStore } from '@/store';
import { Library } from '@/typings/classes/library.class';
import Filters from '@/typings/classes/filters.class';

export default defineComponent({
  name: 'LibraryFilters',
  components: {
    Swiper,
    SwiperSlide,
  },
  emits: ['open:modal', 'reset:filters'],
  setup() {
    const store = useStore();
    const library: Library = store.getters['library/get'];
    const filters: Ref<Filters> = ref(library.filters);
    const displayTextWidth = (text: string, font: string): number => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (context) {
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
      } return 0;
    };

    const slidesOptions = filters.value.groups.map(({ title, key }) => (
      { key, title, width: `${displayTextWidth(title, '15px Montserrat') + 45}px` }));

    return {
      library,
      slidesOptions,
      filterOutline,
      chevronDown,
    };
  },
  methods: {
    unActiveAllFilters() {
      this.library.filters.desactivateAll();
      this.$emit('reset:filters');
    },
  },
});
