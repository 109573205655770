function getAge(birthdate: string | null) {
  const objectBirthdate = new Date(birthdate ?? 0);
  const today = new Date();
  let age = today.getFullYear() - objectBirthdate.getFullYear();
  const month = today.getMonth() - objectBirthdate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < objectBirthdate.getDate())) {
    age -= 1;
  }
  return age;
}

// eslint-disable-next-line import/prefer-default-export
export { getAge };
