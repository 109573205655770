import axios from 'axios';
import { get } from 'lodash';
import storage from '@/services/storage';
import { ref } from 'vue';

const unauthorizedRef = ref(false);

const domains = {
  development: 'https://api.staging.petitcitron.fr/api',
  staging: 'https://api.staging.petitcitron.fr/api',
  production: 'https://api.petitcitron.fr/api',
};

const baseDomain = domains[import.meta.env.MODE];
const baseURL = `${baseDomain}`; // Incase of /api/v1;

// ALL DEFAULT CONFIGURATION HERE

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = get(error, 'response.status', 400);
    if ((status === 401 || status === 403) && !originalRequest.retry) {
      originalRequest.retry = true;
      unauthorizedRef.value = true;
      await storage.clear();
      delete instance.defaults.headers.common.Authorization;
    }
    return Promise.reject(error.response);
  },
);
Object.assign(instance, { unauthorizedRef });
export default instance as typeof instance & { unauthorizedRef: typeof unauthorizedRef };
