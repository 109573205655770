

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GroceryAlimentInline',
  props: {
    title: { type: String, required: true },
    quantity: { type: Number, required: true, default: 0 },
    unitTitle: { type: String, default: null },
    picture: { type: String, required: true },
  },
  emits: [],
  methods: {
    valueDisplay(value: number): number | string {
      const floorValue = Math.floor(value);
      const remainder = value - floorValue;
      function getFractionnalPart(float: number) {
        switch (float) {
          case 0.25:
            return '1/4';
          case 0.5:
            return '1/2';
          case 0.75:
            return '3/4';
          default:
            break;
        }
        return float;
      }
      const fractionnalPart = getFractionnalPart(remainder);
      if (floorValue === 0) {
        return fractionnalPart;
      }
      return value;
    },
  },
});
