import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

class Track {
  static isDevMode = false;

  static async initialize() {
    /**
     * Platform: Web
     * Configure and initialize the firebase app.
     * @param options - firebase web app configuration options
     * */
    if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging') {
      console.log('PC -- FirebaseAnalytics not initialized in non-production mode');
      this.isDevMode = true;
    }
    return Promise.resolve();
  }

  static async setUserId(userId: string) {
    /**
     * Platform: Web/Android/iOS
     * Sets the user ID property.
     * @param userId - unique identifier of a user
     * @returns void
     * https://firebase.google.com/docs/analytics/userid
     */
    try {
      if (this.isDevMode) return Promise.resolve();
      return FirebaseAnalytics.setUserId({ userId });
    } catch (err) { console.error(err); throw (err); }
  }

  static async setUserProperty(name: string, value: string) {
    /**
     * Platform: Web/Android/iOS
     * Sets a user property to a given value.
     * @param name - name of the user property to set
     * @param value - value of the user property
     * @returns void
     * https://firebase.google.com/docs/analytics/user-properties
     */
    if (this.isDevMode) return Promise.resolve();
    try {
      return FirebaseAnalytics.setUserProperty({ name, value });
    } catch (err) { console.error(err); throw (err); }
  }

  static async getAppInstanceId() {
    /**
   * Platform: Android/iOS
   * Retrieves the app instance id from the service.
   * @param none
   * @returns instanceId - individual instance id value
   * https://firebase.google.com/docs/analytics/user-properties
   */
    if (this.isDevMode) return Promise.resolve();
    try {
      return FirebaseAnalytics.getAppInstanceId();
    } catch (err) { console.error(err); throw (err); }
  }

  static async setScreenName(screenName: string, nameOverride?: string) {
    /**
     * Platform: Android/iOS
     * Sets the current screen name, which specifies the current visual context in your app.
     * @param screenName - name of the current screen to track
     *        nameOverride - name of the screen class to override
     * @returns instanceId - individual instance id value
     * https://firebase.google.com/docs/analytics/screenviews
     */
    if (this.isDevMode) return Promise.resolve();
    try {
      return FirebaseAnalytics.setScreenName({
        screenName,
        nameOverride: nameOverride ?? screenName,
      });
    } catch (err) {
      console.error(err);
      throw (err);
    }
  }

  static async reset() {
    /**
     * Platform: Web/Android/iOS
     * Clears all analytics data for this app from the device and resets the app instance id.
     * @param none
     * @returns void
     */
    if (this.isDevMode) return Promise.resolve();
    return FirebaseAnalytics.reset();
  }

  static logEvent(name: string, params: object) {
    /**
     * Platform: Web/Android/iOS
     * Logs an app event.
     * @param name - name of the event to log
     *        params - key/value pairs of properties (25 maximum per event)
     * @returns void
     */
    if (this.isDevMode) {
      console.log('PC -- FirebaseAnalytics.logEvent name:', name, 'params:', params);
      return Promise.resolve();
    }
    try {
      return FirebaseAnalytics.logEvent({ name, params });
    } catch (err) { console.error(err); throw (err); }
  }

  static async setEnabled(enabled: boolean) {
    /**
     * Platform: Web/Android/iOS
     * Sets whether analytics collection is enabled for this app on this device.
     * @param name - enabled - boolean true/false
     * @returns void
     */
    if (this.isDevMode) return Promise.resolve();
    return FirebaseAnalytics.setCollectionEnabled({ enabled });
  }

  static async setSessionTimeoutDuration(duration: number) {
    /**
     * Platform: Web/Android/iOS
     * Sets the duration of inactivity that terminates the current session.
     * @param duration - session timeout duration in milliseconds
     * @returns void
     */
    if (this.isDevMode) return Promise.resolve();
    return FirebaseAnalytics.setSessionTimeoutDuration({ duration });
  }
}

export default Track;
