
import { Ref, defineComponent, ref } from 'vue';
import { close } from 'ionicons/icons';
import {
  isPlatform,
} from '@ionic/vue';
import GamificationUserBalance from '@/components/gamification/GamificationUserBalance.vue';
import gamificationEventRepository from '@/repositories/gamification/gamificationEventRepository';
import GamificationEvent, { EventKeys } from '@/tmp/src/entities/gamification/gamificationEvent';
import useGamificationAccountStore from '@/stores/gamification/gamificationAccountStore';
import GiftActionCard from '@/components/gifts/helpers/GiftActionCard.vue';

type PlateformType = 'ios' | 'android' | 'web';

export default defineComponent({
  name: 'GiftEarningCard',
  components: {
    GamificationUserBalance,
    GiftActionCard,
  },
  setup() {
    const events: Ref<Array<GamificationEvent>> = ref([]);
    const gamificationAccountStore = useGamificationAccountStore();
    const isChallengeModalOpen = ref(false);
    const getPlatform = (): PlateformType => {
      if (import.meta.env.MODE === 'development') {
        return 'web';
      }
      if (isPlatform('ios')) { return 'ios'; }
      if (isPlatform('android')) { return 'android'; }
      return 'web';
    };
    const platform = getPlatform();
    const links = {
      facebook: {
        ios: import.meta.env.VITE_FACEBOOK_IOS_DEEP_LINK,
        android: import.meta.env.VITE_FACEBOOK_ANDROID_DEEP_LINK,
        web: import.meta.env.VITE_FACEBOOK_WEB_DEEP_LINK,
      },
      instagram: {
        ios: import.meta.env.VITE_INSTAGRAM_IOS_DEEP_LINK,
        android: import.meta.env.VITE_INSTAGRAM_ANDROID_DEEP_LINK,
        web: import.meta.env.VITE_INSTAGRAM_WEB_DEEP_LINK,
      },
      tiktok: {
        ios: import.meta.env.VITE_TIKTOK_IOS_DEEP_LINK,
        android: import.meta.env.VITE_TIKTOK_ANDROID_DEEP_LINK,
        web: import.meta.env.VITE_TIKTOK_WEB_DEEP_LINK,
      },
    };
    gamificationEventRepository
      .find()
      .then((values) => {
        events.value.push(...values);
        events.value.sort((a, b) => a.order - b.order);
      });
    return {
      events,
      platform,
      links,
      close,
      gamificationAccountStore,
      isChallengeModalOpen,
    };
  },
  methods: {
    openChallengeModal() {
      this.isChallengeModalOpen = true;
    },
    closeChallengeModal() {
      this.isChallengeModalOpen = false;
    },
    openLink(link: string) {
      window.open(
        link,
        '_system',
        'location=yes',
      );
    },
    goToFacebook() {
      this.openLink(this.links.facebook[this.platform]);
    },
    goToInstagram() {
      this.openLink(this.links.instagram[this.platform]);
    },
    goToTikTok() {
      this.openLink(this.links.tiktok[this.platform]);
    },
    selectAction(event: GamificationEvent) {
      const keyToAction: Partial<Record<EventKeys, () => void>> = {
        [EventKeys.INSTAGRAM_CONNECTION]: this.goToInstagram,
        [EventKeys.FACEBOOK_CONNECTION]: this.goToFacebook,
        [EventKeys.TIKTOK_CONNECTION]: this.goToTikTok,
      };
      this.gamificationAccountStore.updateChallenge(event.key);
      const action = keyToAction[event.key];
      if (action) action();
    },
  },
});
