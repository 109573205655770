
import { defineComponent, onMounted, ref } from 'vue';
import { Share, ShareOptions } from '@capacitor/share';
import Track from '@/services/tracking/firebase';

export default defineComponent({
  name: 'ShareButton',
  props: {
    color: {
      type: String,
      default: 'light',
    },
    strong: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  setup() {
    const showShareButton = ref(true);
    const canShare = async () => {
      const { value } = await Share.canShare();
      return value;
    };
    onMounted(async () => {
      const canShareValue = await canShare();
      if (!canShareValue) {
        showShareButton.value = false;
      }
    });
    return { showShareButton };
  },
  methods: {
    async share() {
      const options: ShareOptions = {
        title: this.subject,
        text: this.message,
        url: this.url,
        dialogTitle: this.subject,
      };
      const canShare = await Share.canShare();
      if (canShare) {
        const success = await Share.share(options);
        await Track.logEvent('share', {
          method: 'button',
          type: success.activityType,
          content: this.subject,
        });
      }
    },
  },
});

