
import { computed, ComputedRef, defineComponent, ref, watch } from 'vue';
import { useStore } from '@/store';
import { pencil, chevronBack, chevronForward, close, checkmarkOutline } from 'ionicons/icons';
import { IUser, UserWeight } from '@/typings/interfaces';
import moment from 'moment';
import { loadingController } from '@ionic/vue';

export default defineComponent({
  name: 'ProfileModalWeight',
  props: {
    isOpen: Boolean,
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const isOpenRef = ref(props.isOpen);
    const store = useStore();
    const openIndex = ref(-1);
    const weights: ComputedRef<UserWeight[]> = computed(
      () => (store.getters['user/weights'] as UserWeight[]),
    );
    const weightsReverse: ComputedRef<UserWeight[]> = computed(
      () => [...weights.value].reverse(),
    );
    const closeModal = () => {
      isOpenRef.value = false;
      emit('toggle', false);
    };
    watch(() => props.isOpen, (value) => {
      if (value) { isOpenRef.value = true; }
    });
    return {
      weights,
      weightsReverse,
      isOpenRef,
      openIndex,
      closeModal,
      close,
      pencil,
      chevronBack,
      chevronForward,
      checkmarkOutline,
    };
  },
  computed: {
    presentingElement() {
      return this.$parent?.$refs.ionRouterOutlet as HTMLElement | undefined;
    },
    user(): IUser {
      return this.$store.getters['user/get'];
    },
  },
  methods: {
    timestampToLocaleDate(timestamp: number) {
      moment.locale('fr');
      return moment(timestamp).format('D MMMM YYYY');
    },
    toggleEditMode(index: number) {
      if (this.openIndex === -1) {
        this.openIndex = index;
      } else {
        this.openIndex = -1;
      }
    },
    openEditMode(index: number) {
      this.openIndex = index;
    },
    closeEditMode() {
      this.openIndex = -1;
    },
    updateWeight() {
      this.presentUpdateLoader();
    },
    async presentUpdateLoader() {
      const loading = await loadingController
        .create({
          message: 'Modification en cours...',
          duration: 6000,
        });
      await loading.present();
      try {
        await this.$store.dispatch('user/update', this.user);
      } catch (err) {
        this.$sentry.captureException(err);
      } finally {
        loading.dismiss();
        this.closeEditMode();
      }
    },
  },
});
