
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccountEditObjective',
  props: {
    objective: { type: String, default: 'eatHealthy' },
  },
  emits: ['change'],
  methods: {
    change(objective: string) {
      this.$emit('change', objective);
    },
  },
});
