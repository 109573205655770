
import { defineComponent, Ref, ref } from 'vue';
import { IonInput, IonItem, IonLabel } from '@ionic/vue';

import { chevronBack } from 'ionicons/icons';
import { useStore } from '@/store';
import Foodlist from '@/typings/classes/foodlist.class';

export default defineComponent({
  name: 'LibaryFoodlistRenameModal',
  components: { IonInput, IonItem, IonLabel },
  props: {
    itemId: { type: Number, default: 0 },
  },
  emits: ['close:modal'],
  setup(props, { emit }) {
    const closeModal = () => { emit('close:modal', true); };
    const store = useStore();
    const foodlist = store.getters['foodlists/getById'](props.itemId) as Foodlist;
    const titleRef: Ref<string> = ref(foodlist?.title ?? '');
    const renameFoodlist = async () => {
      await store.dispatch(
        'foodlists/renameFoodlist',
        { id: props.itemId, title: titleRef.value },
      );
      closeModal();
    };
    return {
      closeModal, renameFoodlist, chevronBack, titleRef, foodlist,
    };
  },
  mounted() {
    if (this.titleRef !== this.foodlist.title) {
      this.titleRef = this.foodlist.title;
    }
  },
});
