
import { logoGoogle } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { Browser } from '@capacitor/browser';
import Track from '@/services/tracking/firebase';

export default defineComponent({
  name: 'GoogleSignIn',
  setup() {
    const googleUrl = import.meta.env.VITE_GOOGLE_SIGN_IN_URL;
    return {
      googleUrl,
      logoGoogle,
    };
  },
  methods: {
    async openInAppBrowser() {
      Track.logEvent('login', { method: 'google' });
      await Browser.open({
        url: this.googleUrl,
        windowName: '_self',
        presentationStyle: 'popover',
      });
    },
  },
});
