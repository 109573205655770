import { ApiObject } from '../../api';
import {
  GamificationGamificationActionEvent,
  ApiGamificationEventGamificationEvent,
} from '../../strapi-generation/schemas';

export default
class GamificationAccountEvent implements ApiObject<GamificationGamificationActionEvent> {
  id: number;
  action: ApiObject<ApiGamificationEventGamificationEvent>;
  creationDate: Date;
}
