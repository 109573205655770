
import { useIonRouter } from '@ionic/vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AccountHomeLink',
  props: {
    link: {
      type: Object as PropType<{ title: string; icon: string; route?: string, href?: string }>,
      required: true,
    },
  },
  setup() {
    const router = useIonRouter();
    return { router };
  },
  methods: {
    handleLink(link: { title: string; icon: string; route?: string; }) {
      if (link.route) {
        this.router.push(link.route);
      }
    },
  },
});
