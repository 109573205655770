/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosHeaders } from 'axios';
import { get } from 'lodash';
import storage from '@/services/storage';
import HttpInstanceInterface from './HttpInstanceInterface';
import { CollectionTypeSchema } from '../engine';
import { ApiObject } from './types';

type StrapiResponse<
  T extends ApiObject<CollectionTypeSchema> | ApiObject<CollectionTypeSchema>[]> = Promise<{
    data: T,
    meta: unknown,
  }>;

class AxiosConfig implements HttpInstanceInterface {
  public baseUrl: string;
  public instance: AxiosInstance;

  constructor(url: string) {
    this.baseUrl = url;

    this.instance = axios.create({ baseURL: url });
    storage.get('jwt')
      .then((jwt) => {
        if (jwt !== null && jwt !== 'null') {
          this.instance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
        }
      });
    this.setInterceptorsOnConfig();
  }

  private setInterceptorsOnConfig() {
    // handling errors
    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        const status = get(error, 'response.status', 400);
        if ((status === 401 || status === 403) && !originalRequest.retry) {
          originalRequest.retry = true;

          // TODO : implement refresh token on server side
          // try {
          // Call the function to refresh the JWT token
          // const refreshedToken = await refreshToken();

          // Update the authorization header with the new token
          // updateAuthorizationHeader(refreshedToken);

          // Retry the original request with the updated token
          // return this.instance(originalRequest);
          // } catch (refreshError) {
          // Handle the refresh error, e.g., redirect to login page or show an error message
          // console.error('Failed to refresh JWT token:', refreshError);
          // ... handle the error appropriately ...
          // }
        }
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.request.use(
      async (config) => {
        const jwt = await storage.get('jwt');
        if (jwt === null || jwt === 'null') {
          return config;
        }
        (config.headers as AxiosHeaders).set('Authorization', `Bearer ${jwt}`);
        return config;
      },
    );
  }

  async create<
    T extends ApiObject<CollectionTypeSchema> |
    ApiObject<CollectionTypeSchema>[]>(endpoint: string, item: T)
    : Promise<T> {
    const axiosResponse = await this.instance.post(endpoint, item);
    return axiosResponse.data;
  }

  async read<
    T extends ApiObject<CollectionTypeSchema> |
    ApiObject<CollectionTypeSchema>[]>(endpoint: string):
  Promise<T> {
    const axiosResponse = await this.instance.get(`${endpoint}`);
    const strapiResponse: StrapiResponse<T> = axiosResponse.data;
    return (await strapiResponse).data;
  }

  async update(
    endpoint: string,
    item: Partial<CollectionTypeSchema> | Partial<CollectionTypeSchema>[],
  ): Promise<CollectionTypeSchema | CollectionTypeSchema[]> {
    const axiosResponse = await this.instance.put(endpoint, item);
    const strapiResponse: StrapiResponse<CollectionTypeSchema> = axiosResponse.data;
    return (await strapiResponse).data;
  }

  delete(endPoint: string): Promise<void> {
    return this.instance.delete(endPoint);
  }
}

export default AxiosConfig;
