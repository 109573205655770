import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IGrocery } from '@/typings/interfaces';
import GroceryList from '@/typings/classes/grocery.class';

type GroceryResult = {
  data: IGrocery[] | IGrocery,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};
function mapper(item: GroceryList, userId: number) {
  return {
    data: {
      id: item.id,
      isValidated: true,
      menu: { id: item.menu },
      purchasedItems: Array.from(item.purchasedItems),
      user: userId,
    },
  };
}

class GroceryRepository implements IRead<GroceryResult> {
  private config = AxiosConfig;

  private prefix = 'shopping-lists';

  async find(): Promise<AxiosResponse<GroceryResult>> {
    return this.config.get(this.prefix);
  }

  async findOne(id: string): Promise<AxiosResponse<GroceryResult>> {
    return this.config.get(`${this.prefix}/${id}`);
  }

  async update(id: number, item: GroceryList, userId: number)
    : Promise<AxiosResponse<GroceryResult>> {
    return this.config.put(`${this.prefix}/${id}`, mapper(item, userId));
  }
}

export default GroceryRepository;
