import { ICategory } from '../interfaces';
import { CategoryClass } from './classes';

class Category implements CategoryClass {
  public id: number;
  public title: string;
  public isActive: boolean;
  public displayName: string;

  constructor(category: ICategory) {
    const {
      title,
      isActive,
      displayName,
    } = category.attributes;
    this.id = category.id;
    this.title = title;
    this.isActive = isActive;
    this.displayName = displayName;
  }
}

export default Category;
