import { AxiosInstance } from 'axios';
import { Address } from '../entity/Address';

export default class AddressController {
  private axiosInstance: AxiosInstance;
  private baseUrl: string;

  constructor(axiosInstance: AxiosInstance, baseUrl: string) {
    this.axiosInstance = axiosInstance;
    this.baseUrl = baseUrl;
  }

  // existing CRUD operations

  get = async (id: number): Promise<Address> => (await this.axiosInstance.get(`${this.baseUrl}/${id}`)).data;

  create = async (address: Address) => {
    const { data } = await this.axiosInstance.post(`${this.baseUrl}`, address);
    // TODO: type data
    return data;
  };

  update = async (address: Address) => {
    const { data } = await this.axiosInstance.put(`${this.baseUrl}`, address);
    // TODO: type data
    return data;
  };
}
