import conf from '@/conf/HttpInstance';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CrudHandler, HttpInstance } from '@/tmp/src/index';
import AffiliateCampaign from '@/tmp/src/entities/affiliate/affiliateCampaign';

type PictureFormat = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null;
  size: number;
  width: number;
  height: number;
};

type PictureDataAttributes = {
  name: string;
  alternativeText: null;
  caption: null;
  width: number;
  height: number;
  formats: {
    thumbnail: PictureFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  provider_metadata: null;
  createdAt: string;
  updatedAt: string;
};

type JsonCampaign = {
  id: number;
  attributes: {
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    offer: string;
    cost: number;
    voucher: string;
    type: string;
    picture: {
      data: {
        id: number;
        attributes: PictureDataAttributes;
      };
    };
    portrait: {
      data: {
        id: number;
        attributes: PictureDataAttributes;
      };
    };
    isPromoted: boolean;
  }
};

class AffiliateCampaignRepository extends CrudHandler<AffiliateCampaign> {
  protected resourceUrl = 'affiliate-campaigns';

  constructor(httpInstance: HttpInstance) {
    super(httpInstance);
    this.defaultPopulate = 'populate=deep';
  }

  // eslint-disable-next-line class-methods-use-this
  map(json: JsonCampaign): AffiliateCampaign {
    const affiliateCampaign = new AffiliateCampaign({
      id: json.id,
      ...json.attributes,
      picture: json.attributes.picture?.data?.attributes?.url,
      portrait: json.attributes.portrait.data?.attributes.url,
    });

    return affiliateCampaign;
  }
}

export default new AffiliateCampaignRepository(conf);
