

import { defineComponent, ref } from 'vue';
import { IonCheckbox } from '@ionic/vue';
import { CheckboxCustomEvent } from '@ionic/core';

export default defineComponent({
  name: 'GroceryBlockDisplay',
  components: { IonCheckbox },
  props: {
    checkColor: {
      type: String,
      default: 'primary',
    },
    removeUnit: {
      type: Boolean,
      default: false,
    },
    title: { type: String, required: true },
    quantity: { type: Number, required: true, default: 0 },
    unitTitle: { type: String, default: null },
    isPurchased: { type: Boolean, default: false },
  },
  emits: ['update-checked'],
  setup(props) {
    const checked = ref(props.isPurchased);
    return { checked };
  },
  methods: {
    updateChecked(event: CheckboxCustomEvent<unknown>) {
      this.checked = event.detail.checked;
      this.$emit('update-checked', this.checked);
    },
    valueDisplay(value: number): number | string {
      const floorValue = Math.floor(value);
      const remainder = value - floorValue;
      function getFractionnalPart(float: number) {
        switch (float) {
          case 0.25:
            return '1/4';
          case 0.5:
            return '1/2';
          case 0.75:
            return '3/4';
          default:
            break;
        }
        return float;
      }
      const fractionnalPart = getFractionnalPart(remainder);
      if (floorValue === 0) {
        return fractionnalPart;
      }
      return value;
    },
  },
});
