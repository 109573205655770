
import { defineComponent, customRef, inject, computed, watch } from 'vue';

import { IonInput } from '@ionic/vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'SurveyDietProgram',
  components: { IonInput },
  setup() {
    const store = useStore();
    const updateValidation = inject('updateValidation') as (v: boolean) => void;
    const objectiveWeight = customRef((track, trigger) => ({
      get(): string { track(); return `${store.getters['user/get'].objectiveWeight ?? 0}`; },
      set(newValue: string) {
        const value = parseInt(newValue, 10);
        if (Number.isNaN(value)) {
          store.dispatch('user/set', { objectiveWeight: 0 });
        } else {
          store.dispatch('user/set', { objectiveWeight: value });
        }
        trigger();
      },
    }));
    const isValid = computed(() => !Number.isNaN(objectiveWeight.value));
    watch(isValid, (newValue: boolean) => {
      updateValidation(newValue);
    });
    return { objectiveWeight, objectiveDate: new Date(), updateValidation, isValid };
  },
  ionViewDidEnter() {
    this.updateValidation(this.isValid);
  },
});
