
import { defineComponent, customRef, watch, inject } from 'vue';
import { IonCardContent } from '@ionic/vue';
import { EPhysicalActivity } from '@/typings/enums';
import { useStore } from '@/store';

export default defineComponent({
  name: 'SurveyPhysicalActivity',
  components: { IonCardContent },
  setup() {
    const store = useStore();
    const options = [
      {
        text: 'Position assise presque toute la journée',
        subtext: 'travail au bureau, étudiant',
        value: EPhysicalActivity.VERY_LOW,
      },
      {
        text: 'Assis, debout et un peu en mouvement',
        subtext: 'commercial ou professeur',
        value: EPhysicalActivity.LOW,
      },
      {
        text: 'Debout et en mouvement',
        subtext: 'travail en restauration, boulanger',
        value: EPhysicalActivity.MEDIUM,
      },
      {
        text: 'Travail physique éprouvant',
        subtext: 'sur des chantiers',
        value: EPhysicalActivity.HIGH,
      },
    ];
    const updateValidation = inject('updateValidation') as (v: boolean) => void;
    const goToNextSurvey = inject('goToNextSurvey') as () => void;
    const physicalActivity = customRef((track, trigger) => ({
      get(): EPhysicalActivity { track(); return store.getters['user/get'].physicalActivity; },
      set(newPhysicalActivity: EPhysicalActivity) {
        store.dispatch('user/set', { physicalActivity: newPhysicalActivity });
        trigger();
        goToNextSurvey();
      },
    }));
    watch(physicalActivity, (newValue) => {
      updateValidation(newValue !== null);
    });
    return { options, physicalActivity, EPhysicalActivity, updateValidation };
  },
  ionViewDidEnter() {
    this.updateValidation(this.physicalActivity !== null);
  },
});
