
import { defineComponent, PropType } from 'vue';
import { IStep } from '@/typings/interfaces';

export default defineComponent({
  name: 'RecipeSteps',
  props: {
    steps: {
      type: Object as PropType<IStep[]>,
      default: () => ({}),
    },
  },
});
