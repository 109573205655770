
// TODO: ajouter 3 de margin sur le titre
import { defineComponent, ref, watch } from 'vue';
import {
  IonItem, IonInput, IonLabel,
} from '@ionic/vue';
import { chevronBack } from 'ionicons/icons';

export default defineComponent({
  name: 'ProfileAddWeight',
  components: {
    IonItem, IonInput, IonLabel,
  },
  props: {
    isOpen: Boolean,
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const day = ref(new Date());
    const isOpenRef = ref(props.isOpen);
    const weightRef = ref(0);
    const closeModal = () => {
      isOpenRef.value = false;
      emit('toggle', false);
    };
    watch(() => props.isOpen, (value) => {
      if (value) { isOpenRef.value = true; }
    });
    return {
      day,
      isOpenRef,
      weightRef,
      closeModal,
      chevronBack,
    };
  },
  computed: {
    presentingElement() {
      return this.$parent?.$refs.ionRouterOutlet as HTMLElement | undefined;
    },
  },
  methods: {
    addWeight() {
      this.$store.dispatch('user/addWeight', {
        timestamp: new Date().toISOString(),
        value: this.weightRef,
      });
      this.isOpenRef = false;
      this.$emit('toggle', false);
    },
  },
});
