import { CrudHandler, HttpInstance } from '@/tmp/src';
import conf from '@/conf/HttpInstance';
import Allergen from '@/tmp/src/entities/allergen/allergen';

type alimentJson = {
  id: number;
  attributes: {
    name: string;
    alim_code: string;
    alim_name_fr: string;
    energy_reg_kcal: string;
    energy_n_x_jones_kcal: string;
    protein: string;
    protein_crude_n_x_6: string;
    carbohydrate: string;
    fat: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    group_code: string;
    averageWeight: number | null;
    toto: string;
  };
};

type AllergenJson = {
  id: number;
  attributes: {
    name: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    aliments: { data: alimentJson[]; }
  }
};

class AllergenRepository extends CrudHandler<Allergen> {
  protected resourceUrl = 'allergens';

  // eslint-disable-next-line class-methods-use-this
  map(json: AllergenJson): Allergen {
    const allergen = new Allergen();
    allergen.id = json.id;
    allergen.name = json.attributes.name;
    allergen.createdAt = new Date(json.attributes.createdAt);
    allergen.updatedAt = new Date(json.attributes.updatedAt);
    allergen.publishedAt = new Date(json.attributes.publishedAt);
    allergen.aliments = json.attributes.aliments.data;
    return allergen;
  }

  constructor(httpInstance: HttpInstance) {
    super(httpInstance);
    this.defaultPopulate = 'pagination[start]=0&populate[0]=aliments&pagination[limit]=500';
  }
}

export default new AllergenRepository(conf);

