enum MenuActionTypes {
  FIND = 'find',
  SET_DATA = 'set',
  CREATE_MENU = 'createMenu',
  UPDATE_MENU = 'updateMenu',
  UPDATE_MENU_BY_ID = 'updateMenuById',
  ADD_ALIMENT_TO_MEAL_BY_DATE_AND_MEAL_TIME = 'addAlimentToMealByDateAndMealTime',
  UPDATE_MENU_BY_MEAL_ID = 'updateMenuByMealId',
  UPDATE_MEAL = 'updateMeal',
  UPDATE_MEAL_BY_IDS = 'updateMealByIds',
  UPDATE_MEAL_BY_MEAL_ID = 'updateMealByMealId',
  RECOMMANDATION = 'recommandation',
  RECOMMANDATION_RECIPES = 'recommandationRecipes',
  DELETE_MEAL_BY_MENU_ID_AND_MEAL_ID = 'deleteMealByMenuIdAndMealId',
  FILL= 'fill',
  SORT = 'sort',
  SOLVE = 'solve',
  CLEAR = 'clear',
}

enum MenuMutationTypes {
  SET_DATA = 'SET_DATA',
  SET_MENU = 'SET_MENU',
  CREATE_RECIPE = 'CREATE_RECIPE',
  REMOVE_RECIPE = 'REMOVE_RECIPE',
  UPDATE_RECIPE = 'UPDATE_RECIPE',
  CREATE_BREAKFAST = 'CREATE_BREAKFAST',
  REMOVE_BREAKFAST = 'REMOVE_BREAKFAST',
  UPDATE_BREAKFAST = 'UPDATE_BREAKFAST',
  SET_MENU_MEALS = 'SET_MENU_MEALS',
  CLEAR = 'CLEAR',
}

export { MenuMutationTypes, MenuActionTypes };
