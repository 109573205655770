
import { defineComponent, ref, Ref } from 'vue';

import {
  IonToolbar,
  IonFooter,
  useIonRouter,
} from '@ionic/vue';

import {
  restaurantOutline,
  personOutline,
  bookOutline,
  giftOutline,
  restaurant,
  person,
  book,
  gift,
} from 'ionicons/icons';

type FooterListItem = {
  iconActive: string,
  iconInactive: string,
  route: string,
  title: string,
  isActive?: boolean,
  match: string[],
};

export default defineComponent({
  components: {
    IonToolbar,
    IonFooter,
  },
  setup() {
    const router = useIonRouter();
    // TODO : add a match regex to check if the route is the same as the current route
    const footer: Ref<Array<FooterListItem>> = ref([
      { title: 'Recettes', iconInactive: bookOutline, iconActive: book, route: '/library', match: ['recipe'] },
      { title: 'Menu', iconInactive: restaurantOutline, iconActive: restaurant, route: '/menu', match: ['menu'] },
      { title: 'Cadeaux', iconInactive: giftOutline, iconActive: gift, route: '/gifts' },
      { title: 'Profil', iconInactive: personOutline, iconActive: person, route: '/profile' },
    ].map((item) => Object.assign(item, {
      isActive: false,
      match: [item.route.split('/')[1], ...(item.match ?? [])],
    })));

    return { footer, router };
  },
  computed: {
    userIsLogged() {
      return this.$store.getters['user/isLogged'] as boolean;
    },
    isViewOnboarding() {
      return this.$route.fullPath.includes('ob/') || this.$route.name === 'Register';
    },
    routeActive() {
      return this.$route.path;
    },
  },
  watch: {
    routeActive: {
      immediate: true,
      handler(value: string) {
        const [, valuePath] = value.split('/');
        this.footer.forEach((item) => {
          Object.assign(item, {
            isActive: item.match.includes(valuePath),
          });
        });
      },
    },
  },
});

