
import { PropType, defineComponent } from 'vue';
import { IonCardHeader, IonCardTitle, useIonRouter } from '@ionic/vue';
import { Store } from '@/services/flyingBlue';
import useCurrentShoppingSessionStore from '@/stores/shopping/currentShoppingSessionStore';

interface BrandToImage {
  [brand: string]: string;
}
const brandToImage: BrandToImage = {
  Biocoop: '/assets/img/brand/biocop.png',
  Carrefour: '/assets/img/brand/carrefour.png',
  Franprix: '/assets/img/brand/franprix.png',
  Intermarché: '/assets/img/brand/intermarche.png',
  Monoprix: '/assets/img/brand/monoprix.png',
};
const priceFormatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

export default defineComponent({
  name: 'ShopSelectionCard',
  components: {
    IonCardHeader,
    IonCardTitle,
  },
  props: {
    shop: {
      type: Object as PropType<Store>,
      required: true,
    },
    groceryListAlimentsLength: {
      type: Number,
      required: true,
    },
    isPrimaryCard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const router = useIonRouter();
    const shoppingSessionStore = useCurrentShoppingSessionStore();
    return {
      router,
      shoppingSessionStore,
    };
  },
  computed: {
    distance() {
      return this.shop?.distance?.toFixed(2).replace('.', ',') ?? 0;
    },
    cartId() {
      return this.shoppingSessionStore.cartFromStore(this.shop.id)?.id ?? 0;
    },
    price() {
      const total = this.shoppingSessionStore.cartFromStore(this.shop.id)?.total ?? 0;
      return priceFormatter.format(total);
    },
    pictureUrl() {
      const pictureUrl = brandToImage[this.shop.chain.name];
      return pictureUrl ?? this.shop.chain.logoImgUrl;
    },
    availableShopIngredients() {
      return this.shop?.availability?.available ?? 0;
    },
  },
  methods: {
    goToBasketReview(shop: Store) {
      this.shoppingSessionStore.$state.shopInfos.selectedStore = shop;
      // const { shopInfos } = this.shoppingSessionStore.$state;
      // shopInfos.selectedStore = shopInfos.availableShops.find(
      //   (store) => store.id === id,
      // ) ?? null;
      this.shoppingSessionStore.$state
        .cartsInfos.selectedCart = this.shoppingSessionStore.cartFromStore(shop.id) ?? null;
      this.router.push({ path: '/shopping-basket-review/section' });
    },
  },
});
