
import { defineComponent } from 'vue';
import { uniq } from 'lodash';
import { IonSearchbar, IonChip } from '@ionic/vue';
import { chevronBack, addOutline, close } from 'ionicons/icons';
import { Library, SearchResult } from '@/typings/classes/library.class';
import Aliment from '@/typings/classes/aliment.class';

import useAllergenStore from '@/stores/allergen/allergenStore';
import Allergen from '@/tmp/src/entities/allergen/allergen';
import { AlimentRestriction } from '@/typings/interfaces';

export default defineComponent({
  name: 'SurveyAllergyModal',
  components: {
    IonSearchbar, IonChip,
  },
  props: {
    alimentRestriction: {
      type: Array as () => AlimentRestriction[],
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      results: [] as SearchResult<Aliment | Allergen>[],
      keywords: '' as string,
      chevronBack,
      addOutline,
      close,
      allergenStore: useAllergenStore(),
    };
  },
  computed: {
    library() { return this.$store.getters['library/get'] as Library; },
  },
  methods: {
    search(keywords: string) {
      const allergenResults = this.allergenStore.searchByName(keywords)
        .filter((result) => !this
          .alimentRestriction.find((a) => result.item.id === a.allergen?.id));
      const foodResults = this.library
        .searchAliments(keywords)
        .filter((result) => !this
          .alimentRestriction.find((a) => result.item.id === a.aliment?.id));
      if (allergenResults.length > 1) this.results = allergenResults;
      else this.results = foodResults.slice(0, 7);
    },
    addAllergyForAliment(aliment: Aliment) {
      const restriction: AlimentRestriction = {
        aliment,
        reason: '',
        id: 0,
        allergen: undefined,
      };
      this.$emit('change', uniq([...this.alimentRestriction, restriction]));
    },
    addAllergyForAllergen(allergen: Allergen) {
      const restriction: AlimentRestriction = {
        aliment: undefined,
        reason: 'dislike',
        allergen,
      };
      this.$emit('change', uniq([...this.alimentRestriction, restriction]));
    },
    isSearchResultForAliment(searchResult: SearchResult<Allergen | Aliment>)
      : searchResult is SearchResult<Aliment> {
      return (searchResult.item as Aliment).calories > 0;
    },
    removeAllergy(id?: number) {
      this.$emit('change', uniq(this.alimentRestriction.filter((a) => a.id !== id)));
    },
    getAlimentName(id: number) {
      return this.library.aliments.get(id)?.name;
    },
  },
});
