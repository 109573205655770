
import { defineComponent } from 'vue';
import { EPhysicalActivity } from '@/typings/enums';

export default defineComponent({
  name: 'AccountEditPhysicalActivity',
  props: {
    physicalActivity: { type: String, default: 'MEDIUM' },
  },
  emits: ['change'],
  setup() {
    return { EPhysicalActivity };
  },
  methods: {
    change(physicalActivity: string) {
      this.$emit('change', physicalActivity);
    },
  },
});
