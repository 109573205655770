import { ActionTree, ActionContext } from 'vuex';

import { IUser, UserWeight } from '@/typings/interfaces';
import UserRepository from '@/repositories/user';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { State } from './state';
import { Mutations } from './mutations';

import {
  UserActionTypes,
  UserMutationTypes,
  UserAuthentification,
  UserAuthentificationWithProvider,
} from './user-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, unknown>, 'commit'>;

// Actions
interface Actions {
  [UserActionTypes.LOGIN](
    { commit }: AugmentedActionContext, user: UserAuthentification
  ): Promise<boolean>;
  [UserActionTypes.REGISTER](
    { commit }: AugmentedActionContext, user: UserAuthentification
  ): Promise<boolean>;
  [UserActionTypes.LOGIN_WITH_PROVIDER](
    { commit }: AugmentedActionContext, payload: UserAuthentificationWithProvider
  ): Promise<boolean>
  [UserActionTypes.SET_DATA](
    { commit }: AugmentedActionContext, payload: unknown
  ): Promise<boolean>;
  [UserActionTypes.ME](
    { commit }: AugmentedActionContext, payload: IUser,
  ): Promise<boolean>;
  [UserActionTypes.CONNECT_WITH_APPLE](
    { commit }: AugmentedActionContext, payload: SignInWithAppleResponse,
  ): Promise<{ status: 'register' | 'login' }>;
}

const repository = new UserRepository();

const actions: ActionTree<State, unknown> & Actions = {
  async [UserActionTypes.LOGIN]({ commit }, userAuth): Promise<boolean> {
    const user = await repository.login(userAuth);
    // eslint-disable-next-line no-debugger
    commit(UserMutationTypes.SET_DATA, user);
    return true;
  },
  async [UserActionTypes.REGISTER]({ state, commit }, userAuth): Promise<boolean> {
    const user = await repository.register(userAuth);
    commit(UserMutationTypes.SET_DATA, {
      startObjectiveDate: new Date().toISOString(),
      provider: user.provider ?? 'local',
    });
    await repository.update(state);
    commit(UserMutationTypes.SET_DATA, user);
    return true;
  },
  async [UserActionTypes.LOGIN_WITH_PROVIDER]({ commit }, informations): Promise<boolean> {
    const profile = await repository.loginWithProvider(informations);
    commit(UserMutationTypes.SET_DATA, profile);
    return profile.haveAcceptCGU;
  },
  async [UserActionTypes.CONNECT_WITH_APPLE]({ commit }, payload): Promise<{ status: 'register' | 'login' }> {
    const profile = await repository.connectWithApple(payload);
    commit(UserMutationTypes.SET_DATA, { ...profile, isLogged: true });
    const haveAcceptCGU = profile.haveAcceptCGU === true;
    return { status: haveAcceptCGU ? 'login' : 'register' };
  },
  async [UserActionTypes.UPDATE]({ commit }, userUpdate): Promise<boolean> {
    await repository.update(userUpdate);
    commit(UserMutationTypes.SET_DATA, userUpdate);
    return true;
  },
  async [UserActionTypes.UPDATE_SELF]({ state }): Promise<boolean> {
    const user = state;
    await repository.update(user);
    return true;
  },
  async [UserActionTypes.ME]({ commit }): Promise<boolean> {
    try {
      const profile = await repository.profile();
      commit(UserMutationTypes.SET_DATA, { ...profile, isLogged: true });
      return true;
    } catch (err) {
      commit(UserMutationTypes.SET_DATA, { isLogged: false });
      return false;
    }
  },
  async [UserActionTypes.SET_DATA]({ commit }, payload): Promise<boolean> {
    commit(UserMutationTypes.SET_DATA, payload);
    return true;
  },
  async [UserActionTypes.DISCONNECT]({ commit, dispatch }): Promise<boolean> {
    commit(UserMutationTypes.CLEAR);
    await repository.disconnect();
    await dispatch('menus/clear', null, { root: true });
    await dispatch('foodlists/clear', null, { root: true });
    await dispatch('grocery/clear', null, { root: true });
    return true;
  },
  async [UserActionTypes.ADD_WEIGHT]({ commit, state }, payload: UserWeight): Promise<boolean> {
    const user = state;
    user.weights.push(payload);
    await repository.update(user);
    commit(UserMutationTypes.SET_DATA, user);
    return true;
  },
  async [UserActionTypes.FORGOT_PASSWORD](unused, payload: string): Promise<boolean> {
    await repository.forgotPassword(payload);
    return true;
  },
  async [UserActionTypes.RESET_PASSWORD](
    unused,
    payload: { password: string, passwordConfirmation: string, code: string },
  ): Promise<boolean> {
    await repository.resetPassword(payload);
    return true;
  },
  async [UserActionTypes.CHANGE_PASSWORD](
    unused,
    payload: { password: string, currentPassword: string, passwordConfirmation: string },
  ): Promise<boolean> {
    await repository.changePassword(payload);
    return true;
  },
  async [UserActionTypes.DESTROY]({ state }): Promise<boolean> {
    await repository.destroy(state.id);
    return true;
  },
};

export { Actions, actions };
