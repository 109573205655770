import conf from '@/conf/HttpInstance';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApiObject, CrudHandler, HttpInstance } from '@/tmp/src/index';
import AffiliateCustomer from '@/tmp/src/entities/affiliate/affiliateCustomers';
import { ApiAffiliateCampaignAffiliateCampaign } from '@/tmp/src/strapi-generation/schemas';

type PictureFormat = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null;
  size: number;
  width: number;
  height: number;
};

type PictureDataAttributes = {
  name: string;
  alternativeText: null;
  caption: null;
  width: number;
  height: number;
  formats: {
    thumbnail: PictureFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  provider_metadata: null;
  createdAt: string;
  updatedAt: string;
};

type SocialMediaName = 'facebook' | 'instagram' | 'linkedin' | 'twitter' | 'youtube' | 'tiktok';

type JsonSocialMedia = {
  id: number;
  url: string;
  SocialMediaName: SocialMediaName;
};

type DataAttributes = {
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  title: string;
  description: string;
  picture: {
    data: {
      id: number;
      attributes: PictureDataAttributes;
    };
  };
  campaigns: { data: ApiObject<ApiAffiliateCampaignAffiliateCampaign>[] };
  socialMedias: JsonSocialMedia[];
};

type AffiliateCustomerJson = {
  id: number;
  attributes: DataAttributes;
};

class AffiliateCustomerRepository extends CrudHandler<AffiliateCustomer> {
  protected resourceUrl = 'affiliate-customers';

  constructor(httpInstance: HttpInstance) {
    super(httpInstance);
    this.defaultPopulate = 'populate=deep';
  }

  // eslint-disable-next-line class-methods-use-this
  map(json: AffiliateCustomerJson): AffiliateCustomer {
    const affiliateCustomer = new AffiliateCustomer();

    // map the data attributes
    affiliateCustomer.createdAt = new Date(json.attributes.createdAt);
    affiliateCustomer.updatedAt = new Date(json.attributes.updatedAt);
    affiliateCustomer.publishedAt = new Date(json.attributes.publishedAt);
    affiliateCustomer.title = json.attributes.title;
    affiliateCustomer.description = json.attributes.description;
    affiliateCustomer.picture = json.attributes.picture.data.attributes.url;
    affiliateCustomer.campaigns = json.attributes.campaigns.data;

    affiliateCustomer.socialMedias = json.attributes.socialMedias;

    // map the remaining properties
    affiliateCustomer.id = json.id;

    return affiliateCustomer;
  }
}

export default new AffiliateCustomerRepository(conf);
