export enum EDiets {
  'NONE' = 'Aucune',
  'PORKFREE' = 'Sans porc',
  'PESCOVEGETARIAN' = 'Pesco végétarien',
  'VEGETARIAN' = 'Végétarien',
  'GLUTENFREE' = 'Sans gluten',
  'LACTOSEFREE' = 'Sans lactose',
}

export enum EPhysicalActivity {
  'VERY_LOW' = 'veryLow',
  'LOW' = 'low',
  'MEDIUM' = 'medium',
  'HIGH' = 'high',
  'VERY_HIGH' = 'veryHigh',
  'PROFESSIONAL' = 'professional',
}

export enum EBiologicalSex {
  'F' = 'Femme',
  'M' = 'Homme',
}

export enum ENutrimentKeys {
  'energy',
  'carbohydrate',
  'fat',
  'protein',
}

export enum EMealTime {
  'BREAKFAST' = 'breakfast',
  'BRUNCH' = 'brunch',
  'ELEVENSES' = 'elevenses',
  'LUNCH' = 'lunch',
  'AFTERNOONTEA' = 'afternoonTea',
  'SUPPER' = 'supper',
  'DINNER' = 'dinner',
}

export enum EMealType {
  'STARTER_COURSE' = 'starterCourse',
  'MAIN_COURSE' = 'mainCourse',
  'DESSERT' = 'dessert',
}
