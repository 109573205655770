
import { computed, defineComponent, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { chevronForwardOutline } from 'ionicons/icons';
import { useStore } from '@/store';
import { IImage } from '@/typings/interfaces';
import { useIonRouter } from '@ionic/vue';
import { get } from 'lodash';

type AdviceCategory = {
  id: number,
  attributes: { title: string, picture: { data: IImage | null } }
};

export default defineComponent({
  name: 'ProfileAdviceCategoriesCarousel',
  components: { Swiper, SwiperSlide },
  setup() {
    const store = useStore();
    const router = useIonRouter();
    const adviceCategories = computed(() => store.getters['advices/categories'].slice(0, 3) as AdviceCategory[]);
    const width = ref(`calc(${100 / 1.5}vw - 32px)`);
    return { width, adviceCategories, router, chevronForwardOutline };
  },
  methods: {
    goToCategory(id: number) {
      this.router.push(`/advice/category/${id}`);
    },
    goToAllAdviceCategories() {
      this.router.push('/advice/categories');
    },
    getUrl(picture: { data: IImage | null }) {
      const url = get(picture, 'data[0].attributes.url');
      if (url) return `https://api.petitcitron.fr${url}`;
      return 'https://dummyimage.com/200x240/000/aaa';
    },
    getCategoryTitle(category: AdviceCategory) {
      switch (category.id) {
        case 1:
          return 'Perte de poids';
        case 2:
          return 'Santé';
        case 3:
          return 'Prise en main';
        case 4:
          return 'Sport';
        case 5:
          return 'Environnement';
        default:
          return 'Autres';
      }
    },
  },
});
