
import { defineComponent } from 'vue';
import { IonDatetime } from '@ionic/vue';
import { close } from 'ionicons/icons';

export default defineComponent({
  name: 'MenuCalendarModal',
  components: { IonDatetime },
  props: {
    daySelected: {
      type: Date,
      required: true,
    },
  },
  emits: ['change-date', 'close:calendar'],
  setup() {
    return { close };
  },
  computed: {
    daySelectedIso(): string {
      return this.daySelected.toISOString();
    },
  },
  methods: {
    setDateAndCloseModal(date: string | string[] | null | undefined) {
      if (!date) return;
      this.$emit('change-date', new Date(date as string));
      this.$emit('close:calendar');
    },
    closeCalendar() {
      this.$emit('close:calendar');
    },
  },
});
