import { defineStore } from 'pinia';
import AffiliateCustomerRepository from '@/repositories/affiliateCustomer/affiliateCustomerRepository';
import AffiliateCampaignRepository from '@/repositories/affiliateCampaign/affiliateCampaignRepository';
import AffiliateCustomer from '@/tmp/src/entities/affiliate/affiliateCustomers';
import AffiliateCampaign from '@/tmp/src/entities/affiliate/affiliateCampaign';

type AffiliateState = {
  customers: AffiliateCustomer[],
  campaigns: AffiliateCampaign[],
};

const useAffiliateStore = defineStore({
  id: 'affiliate',
  state: (): AffiliateState => ({
    customers: [],
    campaigns: [],
  }),
  getters: {
    getAffiliateCustomers: (state) => state.customers,
    getAffiliateCampaigns: (state) => state.campaigns as AffiliateCampaign[],
    getGiftByCampaignId: (state) => (campaignId: number) => {
      const campaign = state.campaigns.find((c) => c.id === campaignId);
      return campaign;
    },
    getAffilitatePictureByCampaignId: (state) => (campaignId: number) => {
      const affiliateCustomer = state.customers.find(({ id }) => id === campaignId);
      if (affiliateCustomer) {
        return affiliateCustomer.picture;
      }
      return null;
    },
  },
  actions: {
    async hydrate() {
      if (this.customers.length === 0) {
        await this.fetchAffiliateCustomers();
      }
      if (this.campaigns.length === 0) {
        await this.fetchAffiliateCampaigns();
      }
    },
    async fetchAffiliateCustomers() {
      const affiliateCustomers = await AffiliateCustomerRepository.find();
      affiliateCustomers.sort((a, b) => a.id - b.id);
      this.customers = affiliateCustomers;
    },
    async fetchAffiliateCampaigns() {
      const affiliateCampaigns = await AffiliateCampaignRepository.find();
      this.campaigns = affiliateCampaigns;
    },
  },
});

export default useAffiliateStore;
