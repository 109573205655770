
import { computed, defineComponent, inject, ref, watch } from 'vue';
import { IonCardContent } from '@ionic/vue';
import { useStore } from '@/store';
import { IUser } from '@/typings/interfaces';

type Objective = 'eatHealthy' | 'weightLoss' | null;

export default defineComponent({
  name: 'SurveyObjective',
  components: { IonCardContent },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters['user/get'] as IUser);
    const goToNextSurvey = inject('goToNextSurvey') as () => void;
    const updateValidation = inject('updateValidation') as (value: boolean) => void;

    const { objective: objectiveRef }: { objective: Objective } = store.getters['user/get'];
    const objective = ref(objectiveRef ?? null as Objective);
    const isValid = computed(() => objective.value !== null);
    updateValidation(isValid.value);
    watch(objective, (value: Objective) => {
      updateValidation(value !== null);
    });
    return { objective, updateValidation, goToNextSurvey, isValid, user };
  },
  ionViewDidEnter() {
    this.updateValidation(this.isValid);
  },
  methods: {
    setObjective(value: Objective) {
      this.$store.dispatch('user/set', { objective: value });
      this.objective = value;
      this.goToNextSurvey();
    },
  },
});
