
import { defineComponent, inject, computed } from 'vue';
// helpers
import { get } from 'lodash';
// components
import {
  IonThumbnail,
  actionSheetController, useIonRouter, alertController,
} from '@ionic/vue';
// classes
import Foodlist from '@/typings/classes/foodlist.class';
// icons
import { ellipsisVertical } from 'ionicons/icons';
import { useStore } from '@/store';
import { LibraryModalFunction } from './modals/library-typings';

export default defineComponent({
  name: 'LibraryFoodlistCard',
  components: {
    IonThumbnail,
  },
  props: {
    foodlistId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const router = useIonRouter();
    const store = useStore();
    const foodlist = computed(() => store.getters['foodlists/getById'](props.foodlistId) as Foodlist);
    const title = computed(() => foodlist.value?.title ?? '');
    const openModalProvider = inject('openModalProvider') as LibraryModalFunction;
    const closeModalProvider = inject('closeModalProvider') as () => void;
    return {
      ellipsisVertical,
      router,
      foodlist,
      title,
      error: {} as unknown,
      openModalProvider,
      closeModalProvider,
    };
  },
  computed: {
    recipesLength() {
      return this.foodlist.recipes.length ?? 0;
    },
    getUrl(): string {
      const recipeId: number = +get(this.foodlist, 'recipes[0]', 0);
      const recipe = this.$store.getters['library/findOneRecipe'](recipeId);
      const imgUrl = get(recipe, 'imageSource', null);
      if (imgUrl) return `https://api.petitcitron.fr${imgUrl}`;
      return '/assets/logo/logo-small.png';
    },
  },
  methods: {
    goToFoodlistView() {
      this.router.push(`/library/foodlist/${this.foodlist.id}`);
    },
    async presentAction(title: string, id: number | string) {
      const modify = {
        text: 'Renommer',
        role: 'selective',
        handler: () => this.openModalProvider('LibraryFoodlistRenameModal', id),
      };
      const remove = {
        text: 'Supprimer',
        role: 'destructive',
        cssClass: 'text-danger',
        handler: this.presentSuppressActionSheet,
      };
      const cancel = {
        text: 'Annuler',
        role: 'cancel',
        cssClass: 'text-white',
      };
      const actionSheet = await actionSheetController
        .create({
          header: title,
          cssClass: 'fw-medium text-primary',
          buttons: [modify, remove, cancel],
        });
      await actionSheet.present();
    },
    async presentSuppressActionSheet() {
      const cancel = {
        text: 'Annuler',
        cssClass: 'text-danger',
      };
      const remove = {
        text: 'Confirmer',
        cssClass: 'text-primary',
        handler: () => this.$store.dispatch('foodlists/deleteFoodlist', this.foodlist.id),
      };
      const alert = await alertController
        .create({
          header: 'Supprimer la foodlist ?',
          message: `Confirmes la suppression de la foodlist "${this.foodlist.title}". Cette action est irréversible`,
          buttons: [cancel, remove],
        });
      await alert.present();
    },
  },
});
