import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { ICategory } from '@/typings/interfaces';

type CategoryResult = {
  data: ICategory[] | ICategory,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};


class CategoryRepository implements IRead<CategoryResult> {
  private config = AxiosConfig;

  private prefix = 'categories';

  async find(): Promise<AxiosResponse<CategoryResult>> {
    try {
      return this.config.get(this.prefix);
    } catch (err) {
      console.error('PC -- Error CategoriesRepository');
      console.error(err);
      throw err;
    }
  }

  async findOne(id: string): Promise<AxiosResponse<CategoryResult>> {
    try {
      return this.config.get(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error CategoriesRepository');
      console.error(err);
      throw err;
    }
  }
}

export default CategoryRepository;
