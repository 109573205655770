
import { computed, defineComponent, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import lottie, { AnimationItem } from 'lottie-web';

import { checkmarkCircle, closeCircleOutline, close } from 'ionicons/icons';
import { EventKeys } from '@/tmp/src/entities/gamification/gamificationEvent';

import useGamificationAccountStore from '@/stores/gamification/gamificationAccountStore';

export default defineComponent({
  name: 'GamificationEarningSuccess',
  props: {
    isReady: {
      type: Boolean,
      default: false,
    },
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    const affiliateCustomerStore = useGamificationAccountStore();
    const { countTodayEventsByActionKey } = storeToRefs(affiliateCustomerStore);
    const isGamificationEarningSuccessModalOpen = ref(false);
    const animation = ref(null as AnimationItem | null);
    const gain = computed(() => Object
      .values(countTodayEventsByActionKey.value).reduce((acc, value) => acc + value, 0));
    const key = ref(null as null | string);
    const earningInformations = [
      { key: EventKeys.DAILY_CONNECTION, label: 'Se connecter sur Petit Citron', max: 1 },
      { key: EventKeys.RECIPE_VALIDATION, label: 'Valider deux recettes', max: 2 },
      { key: EventKeys.DAILY_ADVICE, label: 'Regarder le conseil du jour', max: 1 },
    ];
    watch(countTodayEventsByActionKey, (newValue, oldValue) => {
      const recipeValue = newValue[EventKeys.RECIPE_VALIDATION] ?? 0;
      const dailyAdviceValue = newValue[EventKeys.DAILY_ADVICE] ?? 0;
      if (recipeValue && recipeValue === 2 && oldValue[EventKeys.RECIPE_VALIDATION] === 1) {
        isGamificationEarningSuccessModalOpen.value = true;
        animation.value?.play();
      } else if (dailyAdviceValue !== oldValue[EventKeys.DAILY_ADVICE]) {
        isGamificationEarningSuccessModalOpen.value = true;
        animation.value?.play();
      }
    });
    return {
      gain,
      key,
      close,
      checkmarkCircle,
      closeCircleOutline,
      earningInformations,
      countTodayEventsByActionKey,
      isGamificationEarningSuccessModalOpen,
      animation: ref(null as AnimationItem | null),
    };
  },
  computed: {
    userIsLogged() {
      return this.$store.getters['user/isLogged'] as boolean;
    },
  },
  watch: {
    isGamificationEarningSuccessModalOpen: {
      immediate: true,
      handler(value) {
        if (!value) { this.animation?.stop(); } else {
          this.animation?.play();
        }
      },
    },
    animation: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          value.play();
        }
      },
    },
  },
  mounted() {
    this.animation = lottie.loadAnimation({
      container: document.getElementById('lottie-gamification') as HTMLElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/animations/success.json',
    });
  },
  ionViewDidEnter() {
    this.animation?.destroy();
    this.animation = lottie.loadAnimation({
      container: document.getElementById('lottie-gamification') as HTMLElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/animations/success.json',
    });
  },
  ionViewDidLeave() {
    this.animation?.destroy();
  },
});
