import {
  Animation as IonicAnimation,
} from '@ionic/vue';

type AnimationOptionArray = Array<{
  key: keyof IonicAnimation,
  params: Array<string | number | string[] | boolean>,
}>;

enum AnimationType {
  SLIDE_ENTER_LEFT = 'slideEnterLeft',
  SLIDE_ENTER_RIGHT = 'slideEnterRight',
  SLIDE_LEAVE_RIGHT = 'slideLeaveRight',
  SLIDE_LEAVE_LEFT = 'slideLeaveLeft',

  SLIDE_ENTER_UP = 'slideEnterUp',
  SLIDE_ENTER_DOWN = 'slideEnterDown',
  SLIDE_LEAVE_UP = 'slideLeaveUp',
  SLIDE_LEAVE_DOWN = 'slideLeaveDown',

  NO_OP = 'noOp',
}

type AnimationOptions = {
  id: AnimationType;
  options: AnimationOptionArray;
};

interface IAnimationService {
  /**
   * Create a Ionic animation from the given options
   * @returns animation created
   * static create: (options: AnimationOptions) => IonicAnimation;
   */

  /**
   * Get an animation from the list of animations
   * @param id Animation id
   * @param el Element to set the animation from
   * @returns a new animation for the element provided
  */
  use(id: AnimationType, el: Element): IonicAnimation;

  /**
   * Get two animations from the list of animations
   * to use it in a transition
   * @param ids Animation ids
   * @param el Element to set the animation from
   */
  useForEnterLeave(
    ids: AnimationType[],
    el: Element,
    opts: { enteringEl: Element; leavingEl: Element; },
  ): IonicAnimation;
  describe: () => void;
}

export {
  AnimationType,
  IAnimationService,
  AnimationOptions,
  AnimationOptionArray,
};
