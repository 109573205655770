enum UserActionTypes {
  ME = 'me',
  LOGIN = 'login',
  REGISTER = 'register',
  UPDATE = 'update',
  DISCONNECT = 'disconnect',
  SET_DATA = 'set',
  ADD_WEIGHT = 'addWeight',
  CLEAR = 'clear',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  UPDATE_SELF = 'updateSelf',
  CHANGE_PASSWORD = 'changePassword',
  DESTROY = 'destroy',
  SET_META_DATA = 'setMetaData',
  LOGIN_WITH_PROVIDER = 'loginWithProvider',
  CONNECT_WITH_APPLE = 'connectWithApple',
}

enum UserMutationTypes {
  SET_DATA = 'SET_DATA',
  CLEAR = 'CLEAR',
}


export type StrapiTypeLogin = {
  id: number;
  username: string;
  email: string;
  provider?: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
};

export const isStrapiTypeLogin = (loginObject: unknown): loginObject is StrapiTypeLogin => (
  typeof loginObject === 'object'
    && loginObject !== null
    && typeof (loginObject as StrapiTypeLogin).id === 'number'
    && typeof (loginObject as StrapiTypeLogin).username === 'string'
    && typeof (loginObject as StrapiTypeLogin).email === 'string'
    && typeof (loginObject as StrapiTypeLogin).confirmed === 'boolean'
    && typeof (loginObject as StrapiTypeLogin).blocked === 'boolean'
    && typeof (loginObject as StrapiTypeLogin).createdAt === 'string'
    && typeof (loginObject as StrapiTypeLogin).updatedAt === 'string'
);

type UserAuthentification = {
  email: string,
  password: string
};

type UserAuthentificationWithProvider = {
  user: StrapiTypeLogin,
  jwt: string
};

export {
  UserMutationTypes,
  UserActionTypes,
  UserAuthentification,
  UserAuthentificationWithProvider,
};
