
// TODO: replace ion input date for ion picker
// https://ionicframework.com/docs/api/picker

import {
  defineComponent, Ref, customRef, inject,
} from 'vue';
import { IonCardContent } from '@ionic/vue';
import type { DatetimeCustomEvent } from '@ionic/core';
import { useStore } from '@/store';
import DayMonthYearPickerWheel from '../common/DayMonthYearPickerWheel.vue';

type BiologicalSex = 'M' | 'F' | null;

export default defineComponent({
  name: 'SurveyPersona',
  components: { IonCardContent, DayMonthYearPickerWheel },
  setup() {
    const store = useStore();
    const updateValidation = inject('updateValidation') as (v: boolean) => void;
    function customRefFactory<T>(refKey: string) {
      return customRef((track, trigger) => ({
        get(): T { track(); return store.getters['user/get'][refKey]; },
        set(newValue: T) {
          store.dispatch('user/set', { [refKey]: newValue });
          trigger();
        },
      }));
    }
    function customRefFactoryNumber(refKey: string) {
      return customRef((track, trigger) => ({
        get(): string {
          track();
          return `${store.getters['user/get'][refKey] ?? 0}`;
        },
        set(newValue: string) {
          const value = parseInt(newValue, 10);
          store.dispatch('user/set', { [refKey]: Number.isNaN(value) ? 0 : value });
          trigger();
        },
      }));
    }
    const biologicalSex: Ref<BiologicalSex> = customRefFactory('biologicalSex');
    const birthdate = customRefFactory<string>('birthdate');
    const height: Ref<string> = customRefFactoryNumber('height');
    const weight: Ref<string> = customRef((track, trigger) => ({
      get(): string {
        track();
        return `${store.getters['user/get'].weights[0]?.value ?? 0}`;
      },
      set(newValue: string) {
        const value = parseInt(newValue, 10);
        store.dispatch('user/set', { weights: [{ timestamp: Date.now(), value: Number.isNaN(value) ? 0 : value }] });
        trigger();
      },
    }));

    return {
      updateValidation,
      biologicalSex,
      birthdate,
      height,
      weight,
    };
  },
  computed: {
    now(): Date {
      return new Date();
    },
    max(): number {
      return this.now.getFullYear() - 15;
    },
    isValid(): boolean {
      return [this.biologicalSex, this.birthdate, this.height, this.weight]
        .every((v) => v !== null)
        && parseInt(this.height, 10) > 0 && parseInt(this.weight, 10) > 0;
    },
  },
  watch: {
    isValid(newValue: boolean) {
      this.updateValidation(newValue);
    },
  },
  ionViewDidEnter() {
    this.updateValidation(this.isValid);
  },
  methods: {
    setObjective(value: BiologicalSex) {
      this.biologicalSex = value;
    },
    updateBirthdate(event: DatetimeCustomEvent) {
      const { value } = event.detail;
      if (value && typeof value === 'string') {
        this.birthdate = (new Date(value)).toLocaleDateString('fr-FR');
      }
    },
  },
});
