
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<{ title: string, color?: string }>,
      default: () => ({
        title: '',
        color: '',
      }),
    },
  },
});
