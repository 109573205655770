import { last as _last } from 'lodash';
import { IUser } from '@/typings/interfaces';

// body mass indice
export type BMI = {
  value: number
  formatted: string,
  color?: string
  text: string
};

function getBMIText(value: number): string {
  if (value < 17) return 'Dénutrition';
  if (value <= 19) return 'Maigreur';
  if (value <= 25) return 'Normale';
  if (value <= 30) return 'Surpoids';
  if (value <= 35) return 'Obésité modérée';
  if (value <= 40) return 'Obésité sévère';
  return 'Obésité morbide';
}

function getBMI(user: IUser): BMI {
  const { weights, height } = user;
  if (weights.length === 0) {
    return {
      value: 0,
      formatted: 'Aucun poids',
      text: '',
    };
  }
  const currentWeight = (_last(weights)?.value ?? 0);
  const value = currentWeight / ((height / 100) ** 2);
  const formatted = value.toFixed(2);
  const text = getBMIText(value);
  return { value, formatted, text };
}

export { getBMI };
