
import { AxiosInstance } from 'axios';

export default class StoreController {
  private axiosInstance: AxiosInstance;
  private baseUrl: string;

  constructor(axiosInstance: AxiosInstance, baseUrl: string) {
    this.axiosInstance = axiosInstance;
    this.baseUrl = baseUrl;
  }

  // existing CRUD operations

  get = async (params: Record<string, string>) => {
    const value = await this.axiosInstance.get(`${this.baseUrl}`, { params });
    return value.data;
  };
}
