import AxiosConfig from '@/services/api/index';
import ChainController from './controller/ChainController';
import IngredientController from './controller/IngredientController';
import ProductController from './controller/ProductController';
import SessionController from './controller/SessionController';
import StoreController from './controller/StoreController';
import CartController from './controller/CartController';
import AddressController from './controller/AddressController';
import CategoryController from './controller/CategoryController';

const baseUrl = 'v1/grocery';

export const chainController = new ChainController(AxiosConfig, `${baseUrl}/chains`);
export const ingredientController = new IngredientController(AxiosConfig, `${baseUrl}/ingredients`);
export const productController = new ProductController(AxiosConfig, `${baseUrl}/products`);
export const sessionController = new SessionController(AxiosConfig, `${baseUrl}/sessions`);
export const storeController = new StoreController(AxiosConfig, `${baseUrl}/stores`);
export const cartController = new CartController(AxiosConfig, `${baseUrl}/carts`);
export const addressController = new AddressController(AxiosConfig, `${baseUrl}/addresses`);
export const categoryController = new CategoryController(AxiosConfig, `${baseUrl}/categories`);

export * from './entity/Address';
export * from './entity/Chain';
export * from './entity/Ingredient';
export * from './entity/Product';
export * from './entity/Recipe';
export * from './entity/RecipeComposition';
export * from './entity/Session';
export * from './entity/SessionIngredient';
export * from './entity/Store';
export * from './entity/Cart';
export * from './entity/Category';
