import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IRecipe } from '@/typings/interfaces';

type RecipeResult = {
  data: IRecipe[] | IRecipe,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};

class RecipeRepository implements IRead<RecipeResult> {
  private config = AxiosConfig;

  async find(): Promise<AxiosResponse<RecipeResult>> {
    const primary = await this.config.get('recipes');
    return primary;
  }

  async findOne(id: string): Promise<AxiosResponse<RecipeResult>> {
    return this.config.get(`recipes/${id}`);
  }
}

export default RecipeRepository;
