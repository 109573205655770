
import { EDiets } from '@/typings/enums';
import { customRef, defineComponent } from 'vue';

export default defineComponent({
  name: 'AccountEditDiets',
  props: {
    diets: {
      type: Array<string>,
      required: true,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const userDiets = customRef((track, trigger) => ({
      get(): string[] { track(); return props.diets as string[]; },
      set(newDiets: string[]) {
        if (newDiets.length > 1 && newDiets.includes(EDiets.NONE)) {
          if (props.diets.includes(EDiets.NONE)) {
            const diets = newDiets.filter((diet: string) => diet !== EDiets.NONE);
            emit('change', diets);
          } else {
            emit('change', [EDiets.NONE]);
          }
        } else {
          emit('change', newDiets);
        }
        trigger();
      },
    }));
    return {
      userDiets,
      dietsList: Object.values(EDiets) as EDiets[],
    };
  },
});
