import { EPhysicalActivity } from '@/typings/enums';
import { IUser } from '@/typings/interfaces';
import moment from 'moment';
import CaloriesDeficit from './caloriesDeficit';

// 1 kilo = 7700 calories
// threshold = 0.5 kilo
class WeightLossProgram {
  public user: IUser;
  public caloriesDeficit: CaloriesDeficit;
  public weightDeficit: number[] = [];
  public weeksToObjective: number;
  public startObjectiveDate: Date;

  constructor(user: IUser) {
    this.user = user;
    const context = WeightLossProgram.getContext(user);
    this.caloriesDeficit = new CaloriesDeficit(context);
    this.weightDeficit = this.caloriesDeficit.weightPerWeek.map(([_unused, weight]) => weight);
    this.weeksToObjective = this.weightDeficit.length;
    this.startObjectiveDate = user.startObjectiveDate ?? new Date();
  }

  static getContext(user: IUser) {
    try {
      const age = moment().diff(user.birthdate, 'years');
      const firstWeight = user.weights[0].value;
      return {
        age,
        activityFactor: WeightLossProgram.getActivityFactor(user.physicalActivity ?? 'SEDENTARY'),
        calories: WeightLossProgram.getCalories(user.height, user.weights[0].value, age),
        height: user.height / 100,
        weight: firstWeight,
        weightLossPace: 0.5,
        dailyCalorieDeficit: 500,
        sex: user.biologicalSex === 'F' ? 1 : 0,
        targetWeight: firstWeight - (user.objectiveWeight ?? 0),
      };
    } catch (err) {
      return {
        age: 0,
        activityFactor: 0,
        calories: 0,
        height: 0,
        weight: 0,
        weightLossPace: 0,
        dailyCalorieDeficit: 0,
        sex: 0,
        targetWeight: 0,
      };
    }
  }

  static getCurrentBMI(height: number, weight: number): number {
    return weight / ((height / 100) ** 2);
  }

  static getTargetBMI(height: number, objectiveWeight: number): number {
    return objectiveWeight / ((height / 100) ** 2);
  }

  static getCalories(height: number, weight: number, age: number) {
    return 10 * weight + 6.25 * height - 5 * (age + 5);
  }

  static getActivityFactor(activity: EPhysicalActivity | 'SEDENTARY'): number {
    switch (activity) {
      case EPhysicalActivity.VERY_LOW:
        return 1.2;
      case EPhysicalActivity.LOW:
        return 1.375;
      case EPhysicalActivity.MEDIUM:
        return 1.55;
      case EPhysicalActivity.HIGH:
        return 1.725;
      case EPhysicalActivity.VERY_HIGH:
        return 1.9;
      case EPhysicalActivity.PROFESSIONAL:
        return 2.2;
      default:
        return 1.2;
    }
  }
}

export default WeightLossProgram;
