
import { customRef, defineComponent, inject, ref, watch } from 'vue';
import { person, personOutline } from 'ionicons/icons';
import { useStore } from '@/store';

export default defineComponent({
  name: 'SurveyFamily',
  setup() {
    const store = useStore();
    const groupIndexAdults = ref(1);
    const groupIndexChildren = ref(1);
    const updateValidation = inject('updateValidation') as (value: boolean) => void;
    const numberOfAdults = customRef((track, trigger) => ({
      get(): number | null { track(); return store.getters['user/get'].adults; },
      set(newNumberOfAdults: number | null) {
        groupIndexAdults.value = Math.ceil(((newNumberOfAdults ?? 0) + 1) / 5);
        store.dispatch('user/set', { adults: newNumberOfAdults });
        trigger();
      },
    }));
    const numberOfChildren = customRef((track, trigger) => ({
      get(): number | null { track(); return store.getters['user/get'].children; },
      set(newNumberOfChildren: number | null) {
        groupIndexChildren.value = Math.ceil(((newNumberOfChildren ?? 0) + 1) / 5);
        store.dispatch('user/set', { children: newNumberOfChildren });
        trigger();
      },
    }));
    watch(numberOfAdults, (newValueAdults) => {
      updateValidation(newValueAdults !== null && newValueAdults > 0);
    });
    return {
      groupIndexAdults,
      groupIndexChildren,
      numberOfAdults,
      numberOfChildren,
      updateValidation,
      person,
      personOutline,
    };
  },
  ionViewDidEnter() {
    this.updateValidation(this.numberOfAdults !== null && this.numberOfAdults > 0);
  },
  methods: {
    setAdults(adults: number) {
      this.numberOfAdults = adults;
    },
    setChildren(children: number) {
      if (this.numberOfChildren === 1 && children === 1) {
        this.numberOfChildren = 0;
      } else {
        this.numberOfChildren = children;
      }
    },
  },
});
