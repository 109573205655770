import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';
import Menu from '@/typings/classes/menu.class';
import IRead from '@/repositories/interfaces/IRead';
import IWrite from '@/repositories/interfaces/IWrite';
import { IMenu } from '@/typings/interfaces';

type MenuResult = {
  data: IMenu[] | IMenu,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};

type MenuWritePayload = {
  userId: number;
  menu: Menu,
};
type RecommandationPayload = {
  date: string | undefined,
  mealSize: number,
};

class MenusRepository implements IRead<MenuResult>, IWrite<MenuWritePayload, MenuResult> {
  private config = AxiosConfig;

  private prefix = 'menus';

  async find(): Promise<AxiosResponse<MenuResult>> {
    const primary = await this.config.get(`${this.prefix}`);
    return primary;
  }

  async findOne(id: string): Promise<AxiosResponse<MenuResult>> {
    return this.config.get(`${this.prefix}/${id}`);
  }

  async create(payload: MenuWritePayload): Promise<AxiosResponse<MenuResult>> {
    return this.config.post(`${this.prefix}`, {
      data: {
        ...payload.menu.describeForApi,
        user: payload.userId,
      },
    });
  }

  async update(id: string, payload: MenuWritePayload): Promise<AxiosResponse<MenuResult>> {
    return this.config.put(`${this.prefix}/${id}`, {
      data: {
        ...payload.menu.describeForApi,
        user: payload.userId,
      },
    });
  }


  async delete(id: string): Promise<AxiosResponse<boolean>> {
    return this.config.delete(`${this.prefix}/${id}`);
  }

  async recommandation(payload: RecommandationPayload): Promise<AxiosResponse<MenuResult>> {
    if (payload.date !== undefined) {
      const { date: day, mealSize = 12 } = payload;
      return this.config.get(`${this.prefix}/recommandation?day=${day}&mealSize=${mealSize}`);
    }
    return this.config.get(`${this.prefix}/recommandation`);
  }

  async fill(menuId: number, mealSize: number): Promise<IMenu> {
    const data = await (this.config
      .get(`${this.prefix}/fill?menuId=${menuId}&mealSize=${mealSize}`) as Promise<AxiosResponse<MenuResult>>
    );
    return data.data.data as IMenu;
  }

  async solve(): Promise<AxiosResponse<MenuResult>> {
    return this.config.get(`${this.prefix}/solve`);
  }
}

export default MenusRepository;
