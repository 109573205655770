
import { defineComponent, shallowRef } from 'vue';

import config from '@/assets/json/chart-config-program.json';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, ChartConfiguration, registerables } from 'chart.js';

import WeightLossProgram from '@/services/nutrition/program';

export default defineComponent({
  name: 'CaloriesDeficitChart',
  data() {
    return {
      config: config as ChartConfiguration,
      myChart: shallowRef({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        destroy: (): void => { },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        update: (): void => { },
      }),
    };
  },
  computed: {
    weightLossProgram(): WeightLossProgram {
      return this.$store.getters['user/program'] as WeightLossProgram;
    },
    weights(): number[] {
      return this.weightLossProgram.weightDeficit;
    },
  },
  mounted() {
    const ctx = document.getElementById('calories-deficit-chart') as HTMLCanvasElement;

    Chart.register(...registerables, annotationPlugin);
    if (this.weights && this.weights.length > 0) {
      const labels = this.weights.map((unused, index) => `S. ${index + 1}`);
      const shadowWeight = [...this.weights];
      this.config.data.labels = labels;
      this.config.data.datasets[0].data = shadowWeight;
      this.myChart.update();
    }
    this.myChart = new Chart(ctx, this.config);
  },
  beforeUnmount() {
    this.myChart.destroy();
  },
});
