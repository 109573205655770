
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    percent: {
      type: Number,
      default: 75,
    },
    color: {
      type: String,
      default: 'success',
    },
    size: {
      type: Number,
      default: 70,
    },
    strokeWidth: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    radius() {
      return (this.size - (this.strokeWidth * 2)) / 2;
    },
    circumference() {
      return this.radius * 2 * Math.PI;
    },
    calcDashArrayStroke(): string {
      const offset = this.circumference - (this.percent / 100) * this.circumference;
      return offset.toString();
    },
    getOutsideColor(): string {
      return `var(--ion-color-${this.color})`;
    },
    getInsideColor(): string {
      // not DRY !!!
      if (this.percent > 100) {
        return `var(--ion-color-${this.color}-shade)`;
      }
      return `var(--ion-color-${this.color}-tint)`;
    },
  },
});
