
/* eslint-disable arrow-body-style */
import {
  defineComponent, PropType, computed, ComputedRef,
} from 'vue';
import { IRecipeUstensil } from '@/typings/interfaces';
import { useStore } from '@/store';
import { Library } from '@/typings/classes/library.class';

export default defineComponent({
  name: 'RecipeUstensils',
  props: {
    ustensils: {
      type: Object as PropType<{ id:number, quantity: number }[]>,
      default: () => [] as Array<IRecipeUstensil>,
    },
  },
  setup(props) {
    const store = useStore();
    const library: Library = store.getters['library/get'];
    const list = library.ustensils;
    const createName = ({ quantity, singularName, pluralName }
    : { quantity: number, singularName: string, pluralName: string }) => {
      if (quantity <= 1) {
        return `${quantity} ${singularName}`;
      }
      return `${quantity} ${pluralName}`;
    };
    const createUrl = (url: string | null | undefined) => {
      if (url === null || url === undefined) {
        return null;
      }
      if (import.meta.env.MODE === 'development') {
        return `http://localhost:1337${url}`;
      }
      return `https://api.petitcitron.fr${url}`;
    };
    type UstensilListItem = { id: number, display: string, picture: string | null };
    const ustensilList: ComputedRef<UstensilListItem[]> = computed(() => {
      return props.ustensils.map((ustensil) => {
        const ustensilItem = list.get(ustensil.id);
        const name = createName({
          quantity: ustensil.quantity,
          singularName: ustensilItem?.singularName ?? '',
          pluralName: ustensilItem?.pluralName ?? '',
        });
        return {
          display: name,
          id: ustensil.id,
          picture: createUrl(ustensilItem?.picture),
        };
      });
    });
    return { ustensilList };
  },
});
