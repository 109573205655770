const ADVICE_ORDER = [
  // eslint-disable-next-line vue/max-len
  81, 86, 87, 1, 34, 55, 54, 33, 39, 2, 3, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 25, 27, 28, 29, 30, 31, 32, 35, 36, 37, 38, 40, 41, 65, 66, 4, 70, 44, 42, 45, 46, 67, 49, 68, 50, 69, 51, 71, 52, 72, 73, 53, 74, 75, 56, 77, 57, 76, 59, 78, 58, 60, 80, 62, 61, 79, 63, 64, 47, 48, 5, 7, 6, 11, 8, 9, 10, 12, 14, 13,
];

enum AdviceMutationTypes {
  SET_DATA = 'SET_DATA',
  SET_ADVICES = 'SET_ADVICES',
  SET_CATEGORIES = 'SET_CATEGORIES',
  ADD_ADVICE = 'ADD_ADVICE',
  CLEAR = 'CLEAR',
}

enum AdviceActionTypes {
  FETCH_ADVICE = 'fetchAdvice',
  FETCH_ADVICES = 'fetchAdvices',
  FETCH_CATEGORIES = 'fetchCategories',
  CLEAR= 'clear',
}

export {
  AdviceMutationTypes,
  AdviceActionTypes,
  ADVICE_ORDER,
};
