
import { defineComponent, PropType } from 'vue';

import { INutriments, IMacronutriments } from '@/typings/interfaces';
import ProgressCircle from '@/components/common/ProgressCircle.vue';

type BaseDisplay = {
  key: string;
  name: string;
  symbol?: string;
  color: string;
};

type NutrientDisplay = {
  value: string;
  recommended: number;
  percent: number;
} & Omit<BaseDisplay, 'key'>;

export default defineComponent({
  components: { ProgressCircle },
  props: {
    nutriments: {
      type: Object as PropType<Omit<IMacronutriments, 'id'>>,
      default: () => ({
        fat: 0,
        protein: 0,
        carbohydrate: 0,
        energy_reg_kcal: 0,
      }),
    },
    toDisplay: {
      type: Array<string>,
      default: () => [
        'fat',
        'protein',
        'carbohydrate',
        'energy_reg_kcal',
      ],
    },
  },
  setup() {
    return {
      baseDisplay: [
        // eslint-disable-next-line object-curly-newline
        { key: 'energy_reg_kcal', name: 'Calories', color: 'warning', symbol: 'kCal' },
        { key: 'carbohydrate', name: 'Glucides', color: 'danger' },
        { key: 'fat', name: 'Lipides', color: 'warning' },
        { key: 'protein', name: 'Protéines', color: 'success' },
      ] as BaseDisplay[],
    };
  },
  computed: {
    recommendedMacronutriments(): INutriments {
      return this.$store.getters['user/recommendedMacronutriments'];
    },
    formatNutrientForDisplay() {
      return (base: BaseDisplay) => {
        const {
          key, name, color, symbol = 'g',
        } = base;
        const value = this.nutriments[key as keyof Omit<IMacronutriments, 'id'>];
        const percent = 100;
        return {
          name,
          value: Number.isNaN(value) ? 'N/A' : value.toFixed(0),
          recommended: value,
          percent,
          symbol,
          color,
        } as NutrientDisplay;
      };
    },
    defaultNutrients(): NutrientDisplay[] {
      return this.baseDisplay
        .filter(({ key }) => this.toDisplay.includes(key))
        .map(this.formatNutrientForDisplay);
    },
  },

});
