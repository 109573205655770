import { AxiosInstance } from 'axios';

export default class ProductController {
  private axiosInstance: AxiosInstance;
  private axios: AxiosInstance;
  private baseUrl: string;

  constructor(axiosInstance: AxiosInstance, baseUrl: string) {
    this.axiosInstance = axiosInstance;
    this.baseUrl = baseUrl;
  }

  // existing CRUD operations
  // get = (id: number) => this.axiosInstance.get(`${this.baseUrl}/${id}`);

  get = (id: number, storeId = 0) => {
    if (storeId > 0) {
      return this.axiosInstance.get(`${this.baseUrl}/${id}/${storeId}`);
    }
    return this.axiosInstance.get(`${this.baseUrl}/${id}`);
  };

  find = (searchParams: {
    product_query?: string,
    query?: string,
    category_id?: number,
    store_id?: number,
  }) => this.axiosInstance.get(`${this.baseUrl}/search`, { params: searchParams });

  getByCategory = (searchParams: {
    category_id?: number,
    store_id?: number,
    page?: number,
    per_page?: number,
  }) => this.axiosInstance.get(`${this.baseUrl}/byCategories`, { params: searchParams });
}
