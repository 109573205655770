import { ApiObject } from '../../api';
import {
  ApiAffiliateCampaignAffiliateCampaign,
  ApiGamificationAccountGamificationAccount,
  GamificationGamificationActionEvent,
  GamificationGamificationChallengeEvent,
} from '../../strapi-generation/schemas';

import { PluginUsersPermissionsUser } from '../../strapi-generation/tmpschemas';
import HistoricItem from './gamificationAccountEvent';

class GamificationAccount implements ApiObject<ApiGamificationAccountGamificationAccount> {
  user: ApiObject<PluginUsersPermissionsUser>;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  events: ApiObject<GamificationGamificationActionEvent>[];
  challenges: ApiObject<GamificationGamificationChallengeEvent>[];
  balance: number;
  historic: HistoricItem[];
  id: number;
  purchases: Partial<ApiObject<ApiAffiliateCampaignAffiliateCampaign>>[];
}

export default GamificationAccount;
