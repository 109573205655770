
import { computed, defineComponent, PropType, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { chevronForwardOutline } from 'ionicons/icons';
import { useStore } from '@/store';
import { IAdvice, IImage } from '@/typings/interfaces';
import { useIonRouter } from '@ionic/vue';
import { shuffle } from 'lodash';

type AdviceCategory = {
  id: number,
  attributes: { title: string, picture: { data: IImage | null } }
};

export default defineComponent({
  name: 'ProfileAdviceCarousel',
  components: { Swiper, SwiperSlide },
  props: {
    category: {
      type: Object as PropType<AdviceCategory>,
      default: () => ({
        id: 0,
        attributes: {
          title: '',
          picture: { data: null },
        },
      }),
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const router = useIonRouter();
    const adviceCategories = computed(() => store.getters['advices/categories'] as AdviceCategory[]);
    const width = ref(`calc(${100 / 1.1}vw - 32px)`);
    return { width, adviceCategories, router, chevronForwardOutline };
  },
  computed: {
    advicesByCategory() {
      const getterFunction = this.$store.getters['advices/findAdvicesByCategoryId'];
      const advices = getterFunction(this.category.id) as IAdvice[];
      return shuffle(advices).slice(0, 5);
    },
    getCategoryTitle() {
      switch (this.category.id) {
        case 1:
          return 'Perte de poids';
        case 2:
          return 'Santé';
        case 3:
          return 'Prise en main';
        case 4:
          return 'Sport';
        case 5:
          return 'Environnement';
        default:
          return 'Autres';
      }
    },
  },
  methods: {
    goToCategory() {
      if (this.isClickable) {
        this.router.push(`/advice/category/${this.category.id}`);
      }
    },
    goToAdvice(advice: IAdvice) {
      if (this.isClickable) {
        this.router.push(`/advice/${advice.id}`);
      }
    },
    getUrl(picture: { data: IImage | null }) {
      if (picture.data) return `https://api.petitcitron.fr${picture.data.attributes.url}`;
      return 'https://dummyimage.com/160x210/000/aaa';
    },
  },
});
