import axios from 'axios';
import { defineStore } from 'pinia';
import { storeController, sessionController, Store, SessionIngredient, cartController, Cart, productController, CartProduct, Product, categoryController, Category } from '@/services/flyingBlue';
import GroceryList from '@/typings/classes/grocery.class';
import { CartBody } from '@/services/flyingBlue/entity/CartBody';

type Address = {
  street: string,
  city: string;
  postalCode?: number,
  numero?: number
};

interface BrandToImage {
  [brand: string]: string;
}

const brandToImage: BrandToImage = {
  Biocoop: '/assets/img/brand/biocop.png',
  Carrefour: '/assets/img/brand/carrefour.png',
  FranxPrix: '/assets/img/brand/franxprix.png',
  Intermarché: '/assets/img/brand/intermarche.png',
  Monoprix: '/assets/img/brand/monoprix.png',
};

type Localization = {
  lat: number,
  lon: number
};

type BasketInfo = {
  groceryList: GroceryList | null,
  flyMenuAliments: SessionIngredient[]
  sortedByGroupFlyMenuIngredients: any,
};

type ShopInfos = {
  availableShops: Store[],
  selectedStore: Store | null,
  categories: Category[]
};

type CartsInfos = {
  availableCarts: Cart[],
  selectedCart: Cart | null,
  expectedTotalCartsCount: number,
  successfullyLoadedCartsCount: number
  cartHistory: Cart[]
};

type ShoppingSession = {
  position: {
    address: Address,
    localisation: Localization
  },
  basketInfo: BasketInfo,
  shopInfos: ShopInfos,
  cartsInfos: CartsInfos,
};


const useCurrentShoppingSessionStore = defineStore('currentShoppingSession', {
  state: (): ShoppingSession => (
    {
      position: {
        address: {
          street: '',
          city: '',
        },
        localisation: {
          lat: 0,
          lon: 0,
        },
      },
      basketInfo: {
        groceryList: null,
        sortedByGroupFlyMenuIngredients: null,
        flyMenuAliments: [],
      },
      shopInfos: {
        availableShops: [],
        selectedStore: null,
        categories: [],
      },
      cartsInfos: {
        availableCarts: [],
        expectedTotalCartsCount: 0,
        successfullyLoadedCartsCount: 0,
        selectedCart: null,
        cartHistory: [],
      },
    }
  ),
  getters: {
    getCheapestDelivery: (state): Cart | null => {
      const filteredCarts = state.cartsInfos.availableCarts
        .filter((c) => {
          const concernedShops = state.shopInfos.availableShops.find((s) => s.id === c.storeId);
          return concernedShops?.isDelivery;
        })
        .sort((a, b) => a.total - b.total);

      if (filteredCarts.length === 0) {
        return null;
      }
      return filteredCarts[0] as Cart;
    },
    // DRIVES
    getCheapestDrive: (state) => {
      const filteredCarts = state.cartsInfos.availableCarts
        .filter((c) => {
          const concernedShops = state.shopInfos.availableShops.find((s) => s.id === c.storeId);
          return concernedShops?.isDrive;
        })
        .sort((a, b) => a.total - b.total);

      if (filteredCarts.length === 0) {
        return undefined;
      }
      return filteredCarts[0] as Cart;
    },
    // CARTS
    getCurrentCartProductByGroup: (state) => {
      const { groceryList } = state.basketInfo;
      const sIngredients = state.cartsInfos.selectedCart?.products ?? [];
      if (groceryList === null) {
        return null;
      }
      return groceryList.alimentsByGroup.map(
        ([group, list]) => ({
          group,
          list: list.map(
            // TODO: add a typing definition for this
            (alimentToRetrieve) => sIngredients.find(
              (i) => i.name === alimentToRetrieve.name,
            ),
          ),
        }),
      );
    },
    getCurrentCartTotalPrice: (state) => {
      const products = state.cartsInfos.selectedCart?.products ?? [];
      return products
        .map((product) => product.qty * product.price)
        .reduce((acc, price) => acc + price, 0);
    },
    // STORES
    getDeliveryStores: (state) => state.shopInfos.availableShops
      .filter((shop) => shop.isDelivery) as Store[],
    getDriveStores: (state) => state.shopInfos.availableShops
      .filter((shop) => shop.isDrive) as Store[],
    // TODO: change this getter to get all stores and one to get only the selected one
    cartFromStore: (state) => (storeId: number) => {
      const { availableCarts }: { availableCarts: Cart[] } = state.cartsInfos;
      return availableCarts.find((cart) => cart.storeId === storeId) as Cart;
    },

    //  PRODUCTS
    getCurrentCartProductsByCategory: (state) => {
      if (!state.cartsInfos.selectedCart) {
        console.warn('getCurrentCartProductsByCategory aborted: no selected cart');
        return [];
      }
      // const order = [
      //   'Fruits & Légumes',
      //   'Viandes & Poissons',
      //   'Produits frais',
      //   'Epicerie salée',
      //   'Epicerie sucrée',
      //   'Herbes & Epices',
      // ];
      const sortedAliments = [] as { [key: string]: any };
      // order.forEach((category) => {
      //   sortedAliments[category] = [];
      // });
      state.cartsInfos.selectedCart.products.forEach((cartProduct) => {
        const categoryName = cartProduct.product?.main_ingredient.category ?? '';
        if (!sortedAliments[categoryName]) {
          sortedAliments[categoryName] = [];
        }
        sortedAliments[categoryName].push(cartProduct);
      });
      return Object.entries(sortedAliments);
    },
    getCurrentCartProductsByRecipes: (state) => {
      if (!state.cartsInfos.selectedCart) {
        console.warn('getCurrentCartProductsByCategory aborted: no selected cart');
        return [];
      }
      const groceryList = state.basketInfo?.groceryList;
      if (groceryList === null || state.cartsInfos.selectedCart === null) {
        return [];
      }
      const cart = state.cartsInfos.selectedCart;
      const alimentsByRecipe = groceryList.alimentsByRecipe ?? [];
      const sortedAliments = [] as { [key: string]: any };
      alimentsByRecipe.forEach((recipeData) => {
        const ingSids = recipeData[1].map((a) => (a.id));
        const products = cart.products
          .filter((product) => ingSids.indexOf(product.ingredient_sid as number) !== -1);
        sortedAliments[recipeData[0]] = products;
      });
      return Object.entries(sortedAliments);
    },
    getCurrentCartSelectedProducts: (state) => {
      const products = state.cartsInfos.selectedCart?.products;
      return products?.filter((p) => p.qty > 0) as CartProduct[];
    },
    getSimilarProducts: (_state) => (product: CartProduct, store: Store) => {
      const params: { query?: string, store_id?: number } = {};
      if (product.product?.name) params.query = product.product?.main_ingredient.name;
      if (store.id) params.store_id = store.id;
      return productController.find(params);
    },
    getUnavailableIngredients: (state) => {
      if (state.cartsInfos.selectedCart !== null
        && state.basketInfo.groceryList !== null) {
        const ingredientSids = state.cartsInfos.selectedCart.products.map((a) => (
          a.ingredient_sid
        ));
        const list = state.basketInfo.groceryList.mergedAliments()
          .filter((item) => ingredientSids.indexOf(item.id) === -1);
        return list;
      }
      return [];
    },
    // STORES
    getStorePicture: (_state) => (store: Store) => {
      const pictureUrl = brandToImage[store.chain.name];
      return pictureUrl ?? store.chain.logoImgUrl;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },

    async initAlimentsForFlyMenu(groceryList: GroceryList) {
      this.$state.basketInfo.groceryList = groceryList;
      const ingredients: Partial<SessionIngredient>[] = groceryList?.aliments?.map((a) => ({
        qty: groceryList.alimentsQuantity.get(a.id),
        unit: a.unit?.singularName,
        ingredient_sid: a.id,
        ingredient: {
          name: a.name,
        },
      }));
      const { data } = await sessionController.post({ ingredients });
      const sIngredients = (data as unknown as { ingredients: SessionIngredient[] }).ingredients;
      this.$state.basketInfo.flyMenuAliments = sIngredients;
      return Promise.resolve(true);
    },

    // ADDRESSE
    async convertAddressToLocalization(address: Address) {
      const { street, city, postalCode } = address;
      const url = `https://geocode.maps.co/search?street=${street}&city=${city}&postalcode=${postalCode}&country=FR&api_key=6613e50e1a3a8931802997ceh43cc75`;
      const result = await axios.get(url);
      if (result.data.length > 0) {
        const { lat, lon } = result.data[0];
        if (lat && lon) {
          this.position.localisation.lat = lat;
          this.position.localisation.lon = lon;
          return this.position;
        }
      }
      throw new Error('L\'adresse n\'a pas été trouvée');
    },
    async findShopsByLocalization(size = 10) {
      this.$state.cartsInfos.expectedTotalCartsCount = size;
      const { lat, lon } = this.position.localisation;
      const ingredientsIds = this.$state.basketInfo
        .flyMenuAliments
        .map((i) => i.ingredient.id)
        .join(',');
      // no needs to await, but wed preps it
      const stores = await storeController.get({
        lat: lat.toString(),
        lon: lon.toString(),
        ingredient_ids: ingredientsIds,
      });
      this.$state.shopInfos.availableShops = stores.slice(0, size);
      this.$state.cartsInfos.expectedTotalCartsCount = this.$state.shopInfos.availableShops.length;
      this.$state.cartsInfos.successfullyLoadedCartsCount = 0;
      await this.getCarts(0, this.$state.shopInfos.availableShops.length);
      return stores;
    },

    // CARTS
    updateSelectedCart(cart: Cart) {
      const oldCart = this.cartsInfos.selectedCart;
      if (oldCart) this.cartsInfos.cartHistory.push(oldCart);
      this.cartsInfos.selectedCart = cart;
    },
    getCart(store: Store, mod: string, createCart = true): Promise<Cart> {
      const cartDto: CartBody = {
        store_id: store.id,
        delivery_address: store.address,
        cartType: mod as CartBody['cartType'],
        createCart: createCart as boolean,
        ingredients: this.$state.basketInfo.flyMenuAliments.map((a) => ({
          ingredient_id: a.ingredient_id,
          qty: a.qty,
          unit: 'g',
        })),
        products: [],
      };
      return cartController.create(cartDto);
    },
    async getCarts(start = 0, size = 5, createCart = false) {
      const { availableShops } = this.$state.shopInfos;
      const shops = availableShops.slice(start, size);
      const promisedCart: Promise<Cart>[] = shops.map(async (s) => {
        const cart = await this.getCart(s, 'cheapest', createCart);
        this.$state.cartsInfos.successfullyLoadedCartsCount += 1;
        return cart;
      });

      this.$state.cartsInfos.availableCarts = await Promise.all(promisedCart);
      return this.$state.cartsInfos.availableCarts;
    },
    undoCartChanges() {
      const lastCart = this.cartsInfos.cartHistory.pop();
      if (lastCart) { this.cartsInfos.selectedCart = lastCart; }
    },

    // PRODUCTS AND PRODUCTS DETAILS
    async getCartFromProducts(
      store: Store,
      products: CartProduct[],
    ): Promise<Cart> {
      const cartDto: CartBody = {
        store_id: store.id,
        delivery_address: store.address,
        createCart: true,
        cartType: 'default',
        products,
        ingredients: [],
      };
      return cartController.create(cartDto);
    },
    findItemsForCurrentStore(subject: string) {
      const params = {
        query: subject,
        store_id: this.$state.shopInfos.selectedStore?.id,
      };
      return productController.find(params);
    },
    isIngredientInCart(ingredientId : number) {
      if (this.$state.cartsInfos.selectedCart !== null) {
        for (let i = 0; i < this.$state.cartsInfos.selectedCart.products.length; i += 1) {
          if (this.$state.cartsInfos.selectedCart.products[i].ingredient_sid === ingredientId) {
            return true;
          }
        }
      }
      return false;
    },
    getProductsForCategory(categoryId: number, pageToGet: number, perPage = 20) {
      return productController.getByCategory({
        category_id: categoryId,
        store_id: this.$state.shopInfos.selectedStore?.id,
        page: pageToGet,
        per_page: perPage,
      });
    },
    findProductsForCategory(searchQuery : string, categoryId: number) {
      return productController.find({
        product_query: searchQuery,
        query: searchQuery,
        category_id: categoryId,
        store_id: this.$state.shopInfos.selectedStore?.id,
      });
    },
    /* eslint-disable no-param-reassign */
    addProduct(product: CartProduct, otherProduct = false) {
      product.other_product = otherProduct;
      this.$state.cartsInfos.selectedCart?.products.push(product);
    },
    getOtherProducts() : CartProduct[] {
      if (this.$state.cartsInfos.selectedCart === null) {
        return [];
      }
      return this.$state.cartsInfos.selectedCart.products
        .filter((cartProduct) => cartProduct.other_product === true);
    },
    async getDetailsForProducts() {
      if (!this.$state.cartsInfos.selectedCart) {
        console.error('abort GetDetailsProducts --no cart selected');
        return false;
      }
      const promises = this.$state.cartsInfos.selectedCart?.products.map(async (p) => {
        const details = await productController.get(p.productId);
        return { ...p, product: details.data };
      }) ?? [];
      const editedProducts = await Promise.all(promises);
      this.$state.cartsInfos.selectedCart.products = editedProducts;
      return true;
    },
    async getProduct(productId: number) : Promise<Product> {
      const storeId = this.$state.shopInfos.selectedStore
        && this.$state.shopInfos.selectedStore?.id > 0
        ? this.$state.shopInfos.selectedStore?.id : 0;
      const productPromise = await productController.get(productId, storeId);
      return productPromise.data;
    },

    // CATEGORIES
    async getProductCategories(): Promise<Category[]> {
      if (this.$state.shopInfos.categories && this.$state.shopInfos.categories.length) {
        return this.$state.shopInfos.categories;
      }
      const promisedCategories = await categoryController.getAll();
      this.$state.shopInfos.categories = promisedCategories.data;
      return this.$state.shopInfos.categories;
    },
    getCategory(categoryId : number) : Category {
      const filteredCategories = this.$state.shopInfos.categories
        .filter((category) => category.id === categoryId);
      if (filteredCategories.length === 1) {
        return filteredCategories[0];
      }
      return filteredCategories[0];
    },
    //  Getters and setters for entites
    /* eslint-disable vue/max-len */
    incrementProductQty(productId: number, qty: number) {
      const product = this.$state.cartsInfos.selectedCart?.products.find((p) => p.productId === productId);
      if (product) {
        product.qty += qty;
      }
    },
    decrementProductQty(productId: number, qty: number) {
      const product = this.$state.cartsInfos.selectedCart?.products.find((p) => p.productId === productId);
      if (product && product.qty > 0) { product.qty -= qty; }
    },
    switchProduct(newProduct: Product, insteadOfId: number) {
      const cart = this.$state.cartsInfos.selectedCart;
      if (cart) {
        const cartProduct = cart.products.find(
          (p) => Number(p.id) === Number(insteadOfId),
        );
        if (cartProduct) {
          cartProduct.productId = newProduct.id;
          cartProduct.product = newProduct;
          cartProduct.price = newProduct.price * cartProduct.qty;
        }
      }
    },
    isProductInCart(product: Product) {
      const cart = this.$state.cartsInfos.selectedCart;
      if (cart) {
        const cartProduct = cart.products.find(
          (p) => Number(p.productId) === Number(product.id),
        );
        if (cartProduct) {
          return true;
        }
      }
      return false;
    },
    removeProduct(cartproduct: CartProduct) {
      const cart = this.$state.cartsInfos.selectedCart;
      if (cart) {
        const index = cart.products.indexOf(cartproduct);
        if (index >= 0) {
          cart.products.splice(index, 1);
        }
      }
    },
  },
});
export default useCurrentShoppingSessionStore;
