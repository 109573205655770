import { ApiObject } from '../../api';
import { ApiGamificationChallengeGamificationChallenge } from '../../strapi-generation/schemas';
import { AdminUser } from '../../strapi-generation/tmpschemas';


export default
class GamificationChallenge implements ApiObject<ApiGamificationChallengeGamificationChallenge> {
  [key: string]: any;
  title: string;
  description: string;
  picture: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  createdBy: ApiObject<AdminUser>;
  updatedBy: ApiObject<AdminUser>;
  id: number;
}
