enum FoodlistMutationTypes {
  SET_DATA = 'SET_DATA',
  SET_LISTS = 'SET_LISTS',
  ADD_RECIPE = 'ADD_RECIPE',
  REMOVE_RECIPE = 'REMOVE_RECIPE',
  ADD_FOODLIST = 'ADD_FOODLIST',
  REMOVE_FOODLIST = 'REMOVE_FOODLIST',
  RENAME_FOODLIST = 'RENAME_FOODLIST',
  UPDATE_FOODLIST = 'UPDATE_FOODLIST',
  CLEAR = 'CLEAR',
}

enum FoodlistActionTypes {
  FETCH_FOODLIST = 'fetchFoodlist',
  FETCH_FOODLISTS = 'fetchFoodlists',
  CREATE_FOODLIST = 'createFoodlist',
  DELETE_FOODLIST = 'deleteFoodlist',
  TOGGLE_RECIPE_IN_FOODLIST = 'toggleRecipeInFoodlist',
  RENAME_FOODLIST = 'renameFoodlist',
  UPDATE_FOODLIST = 'updateFoodlist',
  CLEAR= 'clear',
}

type PayloadFoodlist = { recipe: number, foodlist: number };

export {
  FoodlistMutationTypes,
  FoodlistActionTypes,
  PayloadFoodlist,
};
