
import { defineComponent, ref } from 'vue';
import { IonSpinner } from '@ionic/vue';
import { checkmarkCircle } from 'ionicons/icons';
import { debounce } from 'lodash';

enum LoadingState {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}
export default defineComponent({
  name: 'ButtonWithLoader',
  components: {
    IonSpinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: async () => { await Promise.resolve(); },
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const loadingState = ref(LoadingState.IDLE);
    const debouncerFunction = debounce(async () => {
      await props.onClick();
      loadingState.value = LoadingState.SUCCESS;
      emit('success');
      setTimeout(() => {
        loadingState.value = LoadingState.IDLE;
      }, 600);
    }, 500);
    function onClickHandler() {
      loadingState.value = LoadingState.LOADING;
      debouncerFunction.cancel();
      return debouncerFunction();
    }
    return { loadingState, onClickHandler, checkmarkCircle, LoadingState };
  },
});
