
import {
  ComputedRef, defineComponent, inject, PropType,
} from 'vue';
import { useIonRouter, IonAccordionGroup, IonAccordion } from '@ionic/vue';
import { get } from 'lodash';
import {
  ellipsisVertical,
  chevronForward,
  chevronBack,
} from 'ionicons/icons';

import { useStore } from '@/store';
import GroceryBlockDisplay from '@/components/grocery/GroceryBlockDisplay.vue';
import GroceryTitleDisplay from '@/components/grocery/GroceryTitleDisplay.vue';
import GroceryList from '@/typings/classes/grocery.class';
import { GroceryAliment } from '@/typings/interfaces';
import Recipe from '@/typings/classes/recipe.class';
import { IGroup, LibraryGroup } from '@/typings/classes/library.class';

export default defineComponent({
  name: 'GroceryListDisplay',
  components: {
    GroceryBlockDisplay,
    GroceryTitleDisplay,
    IonAccordionGroup,
    IonAccordion,
  },
  props: {
    groceryId: {
      type: Number,
      default: 0,
      required: true,
    },
    showBy: {
      type: String as PropType<'recipe' | 'group'>,
      default: 'group',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useIonRouter();
    const store = useStore();
    const groups: LibraryGroup[] = store.getters['library/groups'];
    const recipes: Recipe[] = store.getters['library/recipes'];
    const currentGroceryList: ComputedRef<GroceryList | null> = inject('currentGrocery') as ComputedRef<GroceryList | null>;
    const alimentsByGroupsCode = currentGroceryList.value?.alimentsByGroup ?? [];
    const alimentsByRecipe = currentGroceryList.value?.alimentsByRecipe ?? [];
    const alimentsQuantity = currentGroceryList
      .value?.alimentsQuantity as NonNullable<Map<number, number>>;
    return {
      alimentsQuantity,
      alimentsByRecipe,
      alimentsByGroupsCode,
      groups,
      recipes,
      currentGroceryList,
      router,
      ellipsisVertical,
      chevronForward,
      chevronBack,
    };
  },
  computed: {
    getValueListArray() {
      const { length } = this.alimentsByRecipe;
      const nonExtendedGroups = ['🌶 Condiments et épices', '🍵 Boissons', '🍫 Snacks', '🥣 Sauces', '🥣 Huiles et matières grasses', '🍋 Autres'];
      if (this.showBy === 'group') {
        return this.alimentsByGroupsCode.map(([groupName], i) => {
          if (nonExtendedGroups.includes(groupName)) {
            return -1;
          }
          return i.toString();
        }).filter((i) => i !== -1) as string[];
      }
      return (Array.from(Array(length).keys()).map((i) => `${i}`));
    },
    getList() {
      if (this.showBy === 'group') {
        return this.alimentsByGroupsCode;
      }
      return this.alimentsByRecipe;
    },
  },
  methods: {
    async updateChecked(checked: boolean, aliment: GroceryAliment) {
      const action = (this.showBy === 'group')
        ? 'updateMultiplePurchased'
        : 'updatePurchased';
      await this.$store.dispatch(`grocery/${action}`, {
        groceryId: this.groceryId,
        aliment,
        checked,
      });
    },
    hasUnit(key: string | number) {
      if (typeof key === 'string') {
        return key !== '🌶 Condiments et épices';
      }
      return true;
    },
    getQuantity(aliment: GroceryAliment) {
      if (this.showBy === 'group') {
        return this.alimentsQuantity.get(aliment.id);
      }
      return aliment.quantity;
    },
    getOptions(key: string | number, index: number): IGroup {
      if (this.showBy === 'group') {
        return this.findGroupByCode(key as string);
      }
      const recipe = this.recipes.find(({ id }) => id === key as number);
      const colors = ['primary', 'danger', 'warning'];
      return ({
        title: get(recipe, 'title', 'Autres'),
        color: colors[index % 3],
        icon: '',
        code: '',
      });
    },
    findGroupByCode(title: string): IGroup {
      const group = this.groups.find(({ title: groupTitle }) => groupTitle === title);
      return { title: 'Autres', code: '', ...group };
    },
  },
});
