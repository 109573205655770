import { AxiosInstance } from 'axios';

export default class CategoryController {
  private axiosInstance: AxiosInstance;
  private baseUrl: string;

  constructor(axiosInstance: AxiosInstance, baseUrl: string) {
    this.axiosInstance = axiosInstance;
    this.baseUrl = baseUrl;
  }

  getAll = () => this.axiosInstance.get(`${this.baseUrl}`);
}
