import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IFoodlist } from '@/typings/interfaces';
import Foodlist from '@/typings/classes/foodlist.class';

type FoodlistResult = {
  data: IFoodlist[] | IFoodlist,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};


class FoodlistRepository implements IRead<FoodlistResult> {
  private config = AxiosConfig;

  private prefix = 'foodlists';

  async find(): Promise<AxiosResponse<FoodlistResult>> {
    try {
      return this.config.get(this.prefix);
    } catch (err) {
      console.error('PC -- Error FoodlistRepository');
      console.error(err);
      throw err;
    }
  }

  async findOne(id: string): Promise<AxiosResponse<FoodlistResult>> {
    try {
      return this.config.get(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error FoodlistRepository');
      console.error(err);
      throw err;
    }
  }

  async create(title: string, isFavorite = false): Promise<AxiosResponse<FoodlistResult>> {
    try {
      return this.config.post(`${this.prefix}`, { data: { title, isFavorite } });
    } catch (err) {
      console.error('PC -- Error FoodlistRepository');
      console.error(err);
      throw err;
    }
  }

  async update(id: number, item: Foodlist)
    : Promise<AxiosResponse<FoodlistResult>> {
    try {
      return this.config.put(`${this.prefix}/${id}`, { data: item });
    } catch (err) {
      console.error('PC -- Error FoodlistRepository');
      console.error(err);
      throw err;
    }
  }

  async delete(id: number)
    : Promise<AxiosResponse<FoodlistResult>> {
    try {
      return this.config.delete(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error FoodlistRepository');
      console.error(err);
      throw err;
    }
  }
}

export default FoodlistRepository;
