import { defineStore } from 'pinia';
import RatingRepository from '@/repositories/rating';
import { IRating } from '@/typings/interfaces';

const repository = new RatingRepository();

type State = {
  ratings: IRating[],
};

const useRatingStore = defineStore('rating', {
  state: (): State => ({
    ratings: [],
  }),
  getters: {
    find: (state: State) => state.ratings,
    findOneByRecipeId: (state: State) => (id: number) => {
      const rating = state.ratings.find((r) => r.attributes?.recipe?.data?.id === id);
      return rating;
    },
  },
  actions: {
    clear() { this.$reset(); },
    async findRatings() {
      const { data: { data: ratings } } = await repository.find();
      this.ratings = ratings as IRating[];
      return true;
    },
    async findOneRating(id: number) {
      const { data: { data: rating } } = await repository.findOne(id);
      this.ratings.push(rating as IRating);
      return true;
    },
    async createRating({ recipe, value, comment, firstname }: {
      recipe: number,
      value: number,
      comment: string,
      firstname: string
    }) {
      const { data: { data: newRating } } = await repository
        .create({ recipe, value, comment, firstname });
      this.ratings.push(newRating as IRating);
      return true;
    },
    async updateRating(id: number, { recipe, value, comment }: {
      recipe: number,
      value: number,
      comment: string
    }) {
      await repository.update(id, { recipe, value, comment });
      const setNewRating = (r: IRating) => {
        if (r.id === id) {
          return ({
            id,
            attributes: {
              recipe: { data: { id: recipe } },
              value,
              comment,
            },
          });
        }
        return r;
      };
      this.ratings = this.ratings.map(setNewRating);
      return true;
    },

  },
});

export default useRatingStore;
