
import { get } from 'lodash';
import { defineComponent, ref } from 'vue';
import { IonCard, useIonRouter } from '@ionic/vue';

import RecipeBadgeDetailsOrganism from '@/components/recipes/RecipeBadgeDetailsOrganism.vue';

import Recipe from '@/typings/classes/recipe.class';

export default defineComponent({
  name: 'CarouselRecipeCard',
  components: { IonCard, RecipeBadgeDetailsOrganism },
  props: {
    itemId: {
      type: Number,
      default: 1,
    },
    haveOption: {
      type: Boolean,
      default: false,
    },
    isRectangle: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const router = useIonRouter();
    const width = ref(props.isRectangle ? 300 : 200);
    return { router, width };
  },
  computed: {
    recipe(): Recipe {
      return this.$store.getters['library/findOneRecipe'](this.itemId) as Recipe;
    },
    getUrl(): string {
      const imgUrl = get(this.recipe, 'imageSource', '');
      if (imgUrl) return `https://api.petitcitron.fr${imgUrl}`;
      return imgUrl;
    },
  },
  methods: {
    goToRecipe() {
      if (this.isClickable) {
        const pathBase = `/recipe/${this.itemId}`;
        if (this.$route.query) {
          const append = Object.entries(this.$route.query)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          this.router.push(`${pathBase}?${append}`);
        } else {
          this.router.push(pathBase);
        }
      }
    },
  },
});
