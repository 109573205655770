import { ApiObject } from '../../api';
import { ApiAffiliateCampaignAffiliateCampaign } from '../../strapi-generation/schemas';

export default class Campaign implements ApiObject<ApiAffiliateCampaignAffiliateCampaign> {
  [key: string]: any;
  id: number;
  title: string;
  description: string;
  picture: string;
  portrait: string;
  isPromoted: boolean;
  offer: string;
  cost: number;
  voucher: string;
  type: string;
  startDate: Date;
  endDate: Date;

  constructor(data: { [key: string]: any }) {
    Object.assign(this, data);
  }

  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
}
