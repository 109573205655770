
import { defineComponent, Ref, ref } from 'vue';
import { IonInput, IonItem, IonLabel } from '@ionic/vue';

import { chevronBack } from 'ionicons/icons';
import { useStore } from '@/store';

export default defineComponent({
  name: 'LibraryFoodlistCreateModal',
  components: { IonInput, IonItem, IonLabel },
  emits: ['close:modal', 'foodlist:created'],
  setup(unused, { emit }) {
    const closeModal = () => { emit('close:modal', true); };
    const store = useStore();
    const titleRef: Ref<string> = ref('');
    const addFoodlist = async () => {
      // TODO: retrieve list after creation
      const foodlistId = await store.dispatch('foodlists/createFoodlist', titleRef.value);
      emit('foodlist:created', foodlistId as number);
      closeModal();
    };
    return {
      closeModal, addFoodlist, chevronBack, titleRef,
    };
  },
});
