import { EPhysicalActivity } from '@/typings/enums';
import { IUser } from '@/typings/interfaces';

const [
  NONE,
  VERY_LOW,
  LOW,
  MODERATE,
  MEDIUM,
  HIGH,
  VERY_HIGH,
  PROFESSIONAL,
] = [1.0, 1.2, 1.375, 1.55, 1.725, 1.9, 1.97, 2.4];

const physicalActivityMultiplierMatrix = [
  // 0 time per week
  [NONE, VERY_LOW, MODERATE, MEDIUM],
  // 1 time per week
  [VERY_LOW, LOW, MODERATE, MEDIUM],
  // 2 time per week
  [VERY_LOW, LOW, MODERATE, MEDIUM],
  // 3 time per week
  [LOW, MODERATE, MEDIUM, HIGH],
  // 4 time per week
  [MODERATE, MEDIUM, HIGH, VERY_HIGH],
  // 5 time per week
  [MODERATE, MEDIUM, HIGH, VERY_HIGH],
  // 6 time per week
  [MEDIUM, HIGH, VERY_HIGH, PROFESSIONAL],
  // 7 time per week
  [MEDIUM, HIGH, VERY_HIGH, PROFESSIONAL],
];

function getPhysicalActivityMultiplier(user: IUser) {
  const { sportSessionsPerWeek, physicalActivity } = user;
  // get index of physical activity
  const indexPA = Object
    .values(EPhysicalActivity)
    .indexOf(physicalActivity || EPhysicalActivity.VERY_LOW);
  return physicalActivityMultiplierMatrix[sportSessionsPerWeek ?? 0][indexPA];
}

// eslint-disable-next-line import/prefer-default-export
export { getPhysicalActivityMultiplier };
