/* eslint @typescript-eslint/no-shadow: ["error", { "allow": ["state"] }] */
import {
  Module,
  GetterTree,
  MutationTree,
  ActionTree,
  ActionContext,
} from 'vuex';

import Foodlist from '@/typings/classes/foodlist.class';
import FoodlistRepository from '@/repositories/foodlist';

import { IFoodlist } from '@/typings/interfaces';
import {
  FoodlistMutationTypes,
  FoodlistActionTypes,
  PayloadFoodlist,
} from './foodlist-types';

// Declaration for filters
const repository = new FoodlistRepository();

type State = {
  lists: Foodlist[]
};

type Getters = {
  get(state: State): Foodlist[],
  isRecipeFavorite(state: State): (id: number) => boolean,
};

type Mutations<S = State> = {
  [FoodlistMutationTypes.SET_DATA](state: S, payload: Foodlist): void;
  [FoodlistMutationTypes.SET_LISTS](state: S, payload: Foodlist[]): void;
  [FoodlistMutationTypes.ADD_RECIPE](state: State, payload: PayloadFoodlist): void;
  [FoodlistMutationTypes.REMOVE_RECIPE](state: State, payload: PayloadFoodlist): void;
  [FoodlistMutationTypes.ADD_FOODLIST](state: State, payload: Foodlist): void;
  [FoodlistMutationTypes.REMOVE_FOODLIST](state: State, payload: number): void;
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, unknown>, 'commit'>;

interface Actions {
  [FoodlistActionTypes.FETCH_FOODLIST](
    { commit }: AugmentedActionContext, id: number
  ): Promise<boolean>;
  [FoodlistActionTypes.FETCH_FOODLISTS](
    { commit }: AugmentedActionContext
  ): Promise<boolean>;
  [FoodlistActionTypes.CREATE_FOODLIST](
    { commit }: AugmentedActionContext, title: string,
  ): Promise<number>;
  [FoodlistActionTypes.DELETE_FOODLIST](
    { commit }: AugmentedActionContext, id: number,
  ): Promise<boolean>;
  [FoodlistActionTypes.RENAME_FOODLIST](
    { commit }: AugmentedActionContext,
    payload: { id: number, title: string }) : Promise<boolean>;
}

const state: State = {
  lists: [],
};

const getters: GetterTree<State, unknown> & Getters = {
  get: (state) => state.lists,
  getById: (state) => (id: number) => state.lists.find((list) => list.id === id) ?? null,
  favoriteFoodlist: (state) => state.lists.find(({ isFavorite }) => isFavorite),
  isRecipeFavorite: (state) => (id) => state.lists
    .some(({ isFavorite, recipes }) => isFavorite && recipes.includes(id)),
};

const mutations: MutationTree<State> & Mutations = {
  [FoodlistMutationTypes.SET_DATA](state, payload) {
    const keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      Object.assign(state, {
        [key as keyof Foodlist]: payload[key as keyof Foodlist],
      });
    }
  },
  [FoodlistMutationTypes.ADD_RECIPE](state, payload) {
    const foodlist: Foodlist | undefined = state.lists.find(({ id }) => id === payload.foodlist);
    if (foodlist) {
      foodlist.recipes.push(payload.recipe);
    }
  },
  [FoodlistMutationTypes.ADD_FOODLIST](state, payload) {
    const lists = [...state.lists];
    lists.push(payload);
    state.lists = lists;
  },
  [FoodlistMutationTypes.REMOVE_FOODLIST](state, id) {
    state.lists = state.lists.filter(({ id: foodlistId }) => foodlistId !== id);
  },
  [FoodlistMutationTypes.UPDATE_FOODLIST](state, foodlist: Foodlist) {
    const index = state.lists.findIndex(({ id: foodlistId }) => foodlistId === foodlist.id);
    if (index > -1) {
      // Vue.set(state.lists, index, undefined);
    }
  },
  [FoodlistMutationTypes.REMOVE_RECIPE](state, payload) {
    const foodlist: Foodlist | undefined = state.lists.find(({ id }) => id === payload.foodlist);
    if (foodlist) {
      foodlist.recipes = foodlist.recipes.filter((id) => id !== payload.recipe);
    }
  },
  [FoodlistMutationTypes.SET_LISTS](state: State, payload: Foodlist[]) {
    state.lists = payload;
  },
  [FoodlistMutationTypes.CLEAR](state: State) {
    state.lists = [];
  },
};

const actions: ActionTree<State, unknown> & Actions = {

  // TODO: add a mutation to add a foodlist and check it's existence before
  async [FoodlistActionTypes.FETCH_FOODLIST]({ commit }, payload): Promise<boolean> {
    const { data: { data: list } } = await repository.findOne(payload.toString());
    const foodlist = new Foodlist(list as IFoodlist);
    commit(FoodlistMutationTypes.SET_DATA, foodlist);
    return true;
  },
  async [FoodlistActionTypes.FETCH_FOODLISTS]({ commit }): Promise<boolean> {
    try {
      const { data: { data: lists } } = await repository.find();
      const foodlists = (lists as IFoodlist[]).map((foodlist) => new Foodlist(foodlist));
      commit(FoodlistMutationTypes.SET_LISTS, foodlists);
      return true;
    } catch (err) { console.error(err); return false; }
  },
  async [FoodlistActionTypes.TOGGLE_RECIPE_IN_FOODLIST](
    { commit, state },
    payload: { foodlist: number, recipe: number },
  ) : Promise<string | boolean> {
    const { recipe: recipeId, foodlist: foodlistId } = payload;
    const foodlist = state.lists.find(({ id }) => id === foodlistId);
    if (foodlist) {
      let action = 'add';
      if (foodlist.recipes.includes(recipeId)) {
        action = 'remove';
        commit(FoodlistMutationTypes.REMOVE_RECIPE, payload);
      } else {
        commit(FoodlistMutationTypes.ADD_RECIPE, payload);
      }
      await repository.update(foodlist.id, foodlist);
      return action;
    }
    return false;
  },
  async [FoodlistActionTypes.CREATE_FOODLIST]({ commit }, title) : Promise<number> {
    const { data: foodlist } = await repository.create(title);
    commit(FoodlistMutationTypes.ADD_FOODLIST, new Foodlist(foodlist.data as IFoodlist));
    return (foodlist.data as IFoodlist).id;
  },
  async [FoodlistActionTypes.DELETE_FOODLIST]({ commit }, id) : Promise<boolean> {
    await repository.delete(id);
    commit(FoodlistMutationTypes.REMOVE_FOODLIST, id);
    return true;
  },
  async [FoodlistActionTypes.UPDATE_FOODLIST]({ commit }, foodlist) : Promise<boolean> {
    commit(FoodlistMutationTypes.REMOVE_FOODLIST, foodlist.id);
    await repository.update(foodlist.id, foodlist);
    commit(FoodlistMutationTypes.ADD_FOODLIST, foodlist);
    return true;
  },
  async [FoodlistActionTypes.RENAME_FOODLIST]({ dispatch }, payload) : Promise<boolean> {
    const { id: foodlistId, title } = payload;
    const foodlist = state.lists.find(({ id }) => id === foodlistId);
    if (foodlist) {
      const newFoodlist = foodlist.copy();
      newFoodlist.title = title;
      await dispatch(FoodlistActionTypes.UPDATE_FOODLIST, newFoodlist);
      return true;
    }
    return false;
  },
  async [FoodlistActionTypes.CLEAR]({ commit }) : Promise<boolean> {
    commit(FoodlistMutationTypes.CLEAR);
    return true;
  },
};

const foodlistModule: Module<State, unknown> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default foodlistModule;
