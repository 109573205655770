import { ApiObject, PartialObject } from '../../api';
import { ApiAllergenAllergen } from '../../strapi-generation/schemas';
import { ApiAlimentAliment } from '../../strapi-generation/tmpschemas';

export default class Allergen implements ApiObject<ApiAllergenAllergen> {
  id: number;
  name: string;
  aliments: PartialObject<ApiObject<ApiAlimentAliment>>[];
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
}
