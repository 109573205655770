
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccountEditBiologicalSex',
  props: {
    biologicalSex: { type: String, default: 'F' },
  },
  emits: ['change'],
  methods: {
    change(biologicalSex: string) {
      this.$emit('change', biologicalSex);
    },
  },
});
