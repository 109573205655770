import {
  IRecipe,
  IIngredient,
  IStep,
  IMacronutriments,
} from '@/typings/interfaces';

import Category from './category.class';
import { RecipeClass } from './classes';

class Recipe implements Omit<RecipeClass, 'ustensils'> {
  readonly id: number;
  readonly title: string;

  readonly timeRange?: number;
  readonly cookingTime: number;
  readonly preparationTime: number;

  readonly price: string;
  readonly pricingRange: number;

  readonly categories: Category[];
  readonly imageSource?: string;

  readonly calories?: number;

  readonly seoUrl?: string;
  readonly steps?: IStep[];
  readonly ingredients?: IIngredient[];
  readonly ustensils: { id: number, quantity: number }[];

  readonly _macronutriments: IMacronutriments;

  constructor(recipe: IRecipe) {
    const {
      title, picture,
      cookingTime, preparationTime, timeRange,
      price, pricingRange,
      categories,
      ingredients, ustensils, steps, macronutriments, seoUrl,
    } = recipe.attributes;
    this.id = recipe.id;
    this.title = title;
    this.seoUrl = seoUrl;
    this.calories = macronutriments?.energy_reg_kcal;
    this.cookingTime = cookingTime;
    this.preparationTime = preparationTime;
    this.price = price;
    this.pricingRange = pricingRange;
    this.categories = categories?.data.map((category) => new Category(category));
    this.imageSource = picture.data?.attributes.url;
    this.timeRange = timeRange;
    this._macronutriments = macronutriments;
    this.ingredients = ingredients;
    this.ustensils = ustensils
      ?.filter((ustensil) => ustensil.ustensil.data !== null)
      .map((ustensil) => ({
        id: ustensil.ustensil.data.id,
        quantity: ustensil.quantity ?? 1,
      }))
      ?? [];
    this.steps = steps;
  }

  get macronutriments() {
    return this._macronutriments;
  }

  get totalRecipeTime(): number {
    return this.cookingTime + this.preparationTime;
  }
}

export default Recipe;
