import { CrudHandler, HttpInstance } from '@/tmp/src';
import conf from '@/conf/HttpInstance';
import Referral from '@/tmp/src/entities/referralCode/referralCode';
import User from '@/tmp/src/entities/user/user';


type JsonReferral = {
  referees: User[],
  referral: User,
};

class ReferralRepository extends CrudHandler<Referral> {
  protected resourceUrl = 'referrals';

  constructor(httpInstance: HttpInstance) {
    super(httpInstance);
    this.defaultPopulate = null;
  }

  // eslint-disable-next-line class-methods-use-this
  map(json: JsonReferral): Referral {
    const referral = new Referral();
    referral.referees = json.referees;
    referral.referral = json.referral;
    return referral;
  }

  assign({ referralCode }: { referralCode: string }) {
    return this.httpInstance.create(`${this.resourceUrl}`, { referralCode });
  }
}

export default new ReferralRepository(conf);
