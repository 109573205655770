import { defineStore } from 'pinia';
import accountRepository from '@/repositories/gamification/gamificationAccountRepository';
import GamificationEvent, { EventKeys } from '@/tmp/src/entities/gamification/gamificationEvent';
import GamificationAccountEvent from '@/tmp/src/entities/gamification/gamificationAccountEvent';
import GamificationChallenge from '@/tmp/src/entities/gamification/gamificationChallenge';
import GamificationAccountChallenge from '@/tmp/src/entities/gamification/gamificationAccountChallenge';
import AffiliateCampaign from '@/tmp/src/entities/affiliate/affiliateCampaign';
import GamificationAccount from '@/tmp/src/entities/gamification/gamificationAccount';
import moment from 'moment';
import { countBy, get } from 'lodash';

type State = {
  account: Partial<GamificationAccount>
};

export enum ChallengeKeys {
  TWENTY_CONNECTIONS = 'TWENTY_CONNECTIONS',
  INSTAGRAM_CONNECTION = 'INSTAGRAM_CONNECTION',
  TIKTOK_CONNECTION = 'TIKTOK_CONNECTION',
  FACEBOOK_CONNECTION = 'FACEBOOK_CONNECTION',
  SUMMER_BODY = 'SUMMER_BODY',
}

const useGamificationAccountStore = defineStore('gamificationAccount', {

  state: (): State => ({
    account: {},
  }),
  getters: {
    currentAccount: (state: State) => state.account,
    balance: (state: State) => state.account?.balance ?? 0,
    todayEvents(state: State) {
      return (state.account?.events?.filter(
        (event) => moment(event.creationDate).isSame(moment(), 'day'),
      ) ?? []);
    },
    countTodayEventsByActionKey(): { [key in EventKeys]?: number } {
      const { todayEvents } = this;
      const countEventByActionKey = countBy(todayEvents, 'action.data.attributes.key');
      return countEventByActionKey;
    },
    canValidateMoreRecipes(): boolean {
      const { countTodayEventsByActionKey } = this;
      // this is limited to 2 recipes validations per day
      return (countTodayEventsByActionKey[EventKeys.RECIPE_VALIDATION] ?? 0) < 2;
    },
    totalDayGain(): number {
      const { todayEvents } = this;
      return todayEvents.reduce((acc, event) => acc + get(event, 'action.data.attributes.earning', 0), 0);
    },
  },
  actions: {
    clear() { this.$reset(); },
    async findAccount() {
      const gamificationAccount = await accountRepository.find();
      if (gamificationAccount.length === 1) {
        [this.account] = gamificationAccount;
      } else console.error(gamificationAccount);
      return true;
    },
    async createEvent(key: EventKeys) {
      if (!this.account.id) await this.findAccount();
      if (!this.account?.events) this.account.events = [];

      const event = new GamificationAccountEvent();
      const action = new GamificationEvent();
      action.key = key;
      event.action = action;
      if (typeof this?.account?.id === 'number') {
        return accountRepository.update(
          this.account.id.toString(),
          { events: Array(event) },
        )
          .then((account) => {
            const balanceGain = ((account.balance ?? 0) - (this.account?.balance ?? 0));
            this.account = account;
            return { event: key, gain: balanceGain };
          })
          .catch(() => Promise.resolve(false));
      }
      return Promise.resolve(false);
    },

    async updateChallenge(key: string) {
      if (!this.account?.id) await this.findAccount();
      if (!this.account?.challenges) this.account.challenges = [];

      const accountChallenge = new GamificationAccountChallenge();
      const challenge = new GamificationChallenge();
      challenge.key = key;
      accountChallenge.challenge = challenge;
      if (typeof this?.account?.id === 'number') {
        const account = await accountRepository.update(
          this.account.id.toString(),
          {
            challenges: Array(accountChallenge),
          },
        );
        this.account = account;
      }
    },

    async purchaseGift(id: number) {
      if (!this.account?.id) await this.findAccount();
      if (!this.account?.challenges) this.account.challenges = [];
      const accountGift = new AffiliateCampaign({ id });
      if (typeof this?.account?.id === 'number') {
        const account = await accountRepository.update(
          this.account.id.toString(),
          {
            purchases: Array(accountGift),
          },
        );
        this.account = account;
      }
    },
  },
});

export default useGamificationAccountStore;
