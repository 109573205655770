
import { customRef, defineComponent, ref } from 'vue';
import { person, personOutline } from 'ionicons/icons';
import { useStore } from '@/store';

export default defineComponent({
  name: 'AccountEdit',
  setup() {
    const store = useStore();
    const groupIndexAdults = ref(Math.ceil(((store.getters['user/get'].adults ?? 0) + 1) / 5));
    const groupIndexChildren = ref(Math.ceil(((store.getters['user/get'].children ?? 0) + 1) / 5));
    const numberOfAdults = customRef((track, trigger) => ({
      get(): number | null { track(); return store.getters['user/get'].adults; },
      set(newNumberOfAdults: number | null) {
        groupIndexAdults.value = Math.ceil(((newNumberOfAdults ?? 0) + 1) / 5);
        store.dispatch('user/set', { adults: newNumberOfAdults });
        trigger();
      },
    }));
    const numberOfChildren = customRef((track, trigger) => ({
      get(): number | null { track(); return store.getters['user/get'].children; },
      set(newNumberOfChildren: number | null) {
        groupIndexChildren.value = Math.ceil(((newNumberOfChildren ?? 0) + 1) / 5);
        store.dispatch('user/set', { children: newNumberOfChildren });
        trigger();
      },
    }));
    return {
      groupIndexAdults,
      groupIndexChildren,
      numberOfAdults,
      numberOfChildren,
      person,
      personOutline,
    };
  },
  methods: {
    setAdults(adults: number) {
      this.numberOfAdults = adults;
    },
    setChildren(children: number) {
      if (this.numberOfChildren === 1 && children === 1) {
        this.numberOfChildren = 0;
      } else {
        this.numberOfChildren = children;
      }
    },
  },
});
