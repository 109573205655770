
import { useStore } from '@/store/index';
import { AlimentRestriction, IUser } from '@/typings/interfaces';
import { modalController, IonDatetime } from '@ionic/vue';
import { computed, defineComponent, PropType, reactive } from 'vue';
import AccountEditBiologicalSex from './AccountEditBiologicalSex.vue';
import AccountEditObjective from './AccountEditObjective.vue';
import AccountEditDiets from './AccountEditDiets.vue';
import AccountEditAllergies from './AccountEditAllergies.vue';
import AccountEditPhysicalActivity from './AccountEditPhysicalActivity.vue';
import AccountEditFamilySize from './AccountEditFamilySize.vue';

type UserAccountInformations = keyof Omit<IUser, 'provider' | 'haveAcceptCGU' | 'email' | 'id' | 'lastname' | 'weights' | 'metaData' | 'excludedAliments' | 'startObjectiveDate' | 'objectiveDate' | 'sportSessionsPerWeek' | 'adults' | 'children' | 'allergies'>;

const translations: Record<UserAccountInformations, string> = {
  biologicalSex: 'mon sexe biologique',
  firstname: 'mon prénom',
  birthdate: 'ma date de naissance',
  height: 'ma taille',
  objectiveWeight: 'ma perte de poids',
  objective: 'mon objectif',
  diets: 'mes régimes alimentaires',
  alimentRestriction: 'mes allergies',
  physicalActivity: 'mon activité physique',
};

export default defineComponent({
  name: 'AccountEditModal',
  components: {
    IonDatetime,
    AccountEditBiologicalSex,
    AccountEditFamilySize,
    AccountEditPhysicalActivity,
    AccountEditObjective,
    AccountEditAllergies,
    AccountEditDiets,
  },
  props: {
    type: {
      type: String as PropType<UserAccountInformations>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => {
      const shadllowUser = store.getters['user/get'] as IUser;
      return {
        firstname: shadllowUser.firstname ?? 'Sophie',
        height: shadllowUser.height ?? 180,
        objectiveWeight: shadllowUser.objectiveWeight ?? 0,
        birthdate: shadllowUser.birthdate ?? '1990-01-01',
        biologicalSex: shadllowUser.biologicalSex ?? 'F',
        objective: shadllowUser.objective ?? 'EAT_HEALTHY',
        diets: shadllowUser.diets ?? [],
        allergies: shadllowUser.allergies ?? [],
        physicalActivity: shadllowUser.physicalActivity ?? 'VERY_LOW',
        alimentRestriction: shadllowUser.alimentRestriction ?? [],
      };
    });
    const wrapper = reactive(user.value);

    const cancel = () => { modalController.dismiss(null, 'cancel', 'account-edit-modal'); };
    const confirm = () => {
      const data = wrapper[props.type];
      modalController.dismiss({ [props.type]: data }, 'confirm', 'account-edit-modal');
    };
    return { cancel, confirm, wrapper };
  },
  computed: {
    getLabel(): string {
      return translations[this.type] || '';
    },
  },
  methods: {
    updateBiologicalSex(v: 'F' | 'M') { this.wrapper.biologicalSex = v; },
    updateObjective(v: string) { this.wrapper.objective = v; },
    updateDiets(v: typeof this.wrapper.diets) { this.wrapper.diets = v; },
    updateAllergies(v: AlimentRestriction[]) {
      this.wrapper.alimentRestriction = v;
    },
    updatePhysicalActivity(v: string) {
      this.wrapper.physicalActivity = v;
    },
  },
});
