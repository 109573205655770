
import useGamificationAccountStore from '@/stores/gamification/gamificationAccountStore';
import useAffiliateCustomerStore from '@/stores/affiliate';
import { storeToRefs } from 'pinia';
import { Ref, computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store/index';
import ButtonWithLoader from '@/atomic/organisms/ButtonWithLoader.vue';

export default defineComponent({
  name: 'GiftGetModal',
  components: { ButtonWithLoader },
  emits: ['validate:gift', 'unvalidate:gift', 'close:modal'],
  setup(_props, { emit }) {
    const isGiftValidatedRef = ref(false);
    const affiliateStore = useAffiliateCustomerStore();
    const store = useStore();
    const user = computed(() => store.getters['user/get']);
    // retrieve id of gift
    const route = useRoute();
    const id = computed(() => parseInt(route.params.id as string, 10));
    // retrieving account
    const gamificationAccountStore = useGamificationAccountStore();
    const { currentAccount } = storeToRefs(gamificationAccountStore);
    const giftStatus: Ref<'pending' | 'idle' | 'needCredits' | 'bought' | 'success'> = ref('idle');
    const validateGift = () => {
      isGiftValidatedRef.value = true;
      giftStatus.value = 'pending';
    };
    const unvalidateGift = () => {
      emit('unvalidate:gift');
    };
    return {
      email: user.value.email,
      isGiftValidatedRef,
      gamificationAccountStore,
      affiliateStore,
      id,
      giftStatus,
      validateGift,
      unvalidateGift,
      currentAccount,
    };
  },
  computed: {
    gift() {
      return this.affiliateStore.getGiftByCampaignId(this.id) ?? null;
    },
    haveAlreadyBoughtGift() {
      return this?.currentAccount?.purchases?.some((purchase) => purchase.id === this.gift?.id)
        ?? false;
    },
    haveEnoughCredit() {
      if (this.haveAlreadyBoughtGift) {
        return true;
      }
      if (this.gift?.cost && this.gift?.id && this.currentAccount?.balance) {
        return this.gift.cost <= this.currentAccount.balance;
      }
      return false;
    },
    giftStatusText(): string {
      switch (this.giftStatus) {
        case 'pending':
        case 'success':
          return 'Félicitations 🎉';
        case 'needCredits':
          return 'Tu n\'as pas assez de points !';
        case 'bought':
          return 'Tu as déjà acheté ce cadeau !';
        default:
          return 'Es-tu sûr de vouloir ce cadeau ?';
      }
    },
  },
  watch: {
    haveEnoughCredit: {
      immediate: true,
      handler() {
        if (this.haveEnoughCredit) {
          if (this.haveAlreadyBoughtGift) {
            this.giftStatus = 'bought';
            return;
          }
          this.giftStatus = 'idle';
        } else {
          this.giftStatus = 'needCredits';
        }
      },
    },
  },
  methods: {
    showValidationSuccess() {
      this.giftStatus = 'success';
      setTimeout(() => {
        this.$emit('validate:gift');
        this.giftStatus = 'idle';
      }, 800);
    },
    async sendGiftValidation() {
      // verify if user have enough points
      try {
        if (this.haveEnoughCredit && this.gift) {
          this.gamificationAccountStore.purchaseGift(this.gift.id);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
});
