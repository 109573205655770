/* eslint-disable no-underscore-dangle */
import { Drivers, Storage } from '@ionic/storage';
// import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

const sqliteStorage = new Storage({
  name: '__mydb',
  driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
});
sqliteStorage.create().then(() => {
  console.log('PC -- Storage created with :', sqliteStorage.driver);
});
export default sqliteStorage;
