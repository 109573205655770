import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IAliment } from '@/typings/interfaces';

type AlimentResult = {
  data: IAliment[] | IAliment,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};

class AlimentRepository implements IRead<AlimentResult> {
  private config = AxiosConfig;

  private prefix = 'aliments';

  async find(): Promise<AxiosResponse<AlimentResult>> {
    try {
      return this.config.get(this.prefix);
    } catch (err) {
      console.error('PC -- Error AlimentRepository');
      console.error(err);
      throw err;
    }
  }

  async findOne(id: string): Promise<AxiosResponse<AlimentResult>> {
    try {
      return this.config.get(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error AlimentRepository');
      console.error(err);
      throw err;
    }
  }
}

export default AlimentRepository;
