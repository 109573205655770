type TRecipeFilter = {
  key: string,
  path: string,
  isAdditive: boolean,
  isEnabled: boolean,
};

type TCategory = {
  id: number,
  title: string,
  displayName: string,
};

enum LibraryActionTypes {
  FETCH_RECIPE = 'fetchRecipe',
  FETCH_RECIPES = 'fetchRecipes',
  INIT_LIBRARY = 'initLibrary',
}
enum LibraryMutationTypes {
  SET_DATA = 'SET_DATA',
  SET_RECIPES = 'SET_RECIPES',
  ADD_RECIPE= 'ADD_RECIPE',
}

export {
  LibraryActionTypes, LibraryMutationTypes, TCategory, TRecipeFilter,
};
