
import { defineComponent, inject, ref } from 'vue';
import Track from '@/services/tracking/firebase';

export default defineComponent({
  name: 'SurveyUserDiscoveryFeedback',
  setup() {
    const updateValidation = inject('updateValidation') as (value: boolean) => void;
    const onboardingUserDiscoveryFeedback = ref([
      'Bouche à oreille',
      'Réseaux sociaux',
      'Internet',
      'Publicité',
      'Recommandation d\'un professionnel',
    ]);
    const feedbackSelected = ref<null | number>(null);
    return {
      updateValidation,
      onboardingUserDiscoveryFeedback,
      feedbackSelected,
    };
  },
  ionViewWillLeave() {
    if (this.feedbackSelected !== null) {
      Track.logEvent('survey_user_discovery_feedback', {
        content: this.onboardingUserDiscoveryFeedback[this.feedbackSelected],
      });
    }
  },
  watch: {
    feedbackSelected() {
      this.updateValidation(true);
    },
  },
});
