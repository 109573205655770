
import {
  PropType, defineComponent, ref,
} from 'vue';
import {
  addOutline,
  removeOutline,
} from 'ionicons/icons';

import { IIngredient } from '@/typings/interfaces';
import { useStore } from '@/store';
import { Library } from '@/typings/classes/library.class';

export default defineComponent({
  name: 'RecipeIngredients',
  props: {
    ingredients: {
      type: Object as PropType<IIngredient[]>,
      default: () => [] as Array<IIngredient>,
    },
  },
  setup() {
    const servings = ref(1);
    const store = useStore();
    const library: Library = store.getters['library/get'];

    return {
      library,
      servings,
      addOutline,
      removeOutline,
    };
  },
  methods: {
    hasUrl(ingredient: IIngredient): boolean {
      if (ingredient.aliment.data === null
      || ingredient.aliment?.data?.id === undefined) {
        this.$sentry.captureEvent({
          message: 'Ingredient without aliment',
          level: 'error',
          extra: {
            ingredient,
          },
        });
        return false;
      }
      const aliment = this.library.aliments.get(ingredient.aliment.data.id);
      return aliment?.picture !== null && aliment?.picture !== undefined;
    },
    getUrl(ingredient: IIngredient): string {
      const aliment = this.library.aliments.get(ingredient.aliment.data.id);
      if (import.meta.env.MODE === 'development') {
        return `http://localhost:1337${aliment?.picture}`;
      }
      return `https://api.petitcitron.fr${aliment?.picture}`;
    },
    addServing() { this.servings += 1; },
    removeServing() { if (this.servings > 1) { this.servings -= 1; } },
    getUnit(ingredient: IIngredient) {
      const unit = this.library.units.find((u) => u.id === ingredient.unit?.data?.id);
      if (ingredient.quantity > 1 && unit) {
        return unit.pluralName;
      }
      return unit?.singularName;
    },
    valueDisplay(value: number): number | string {
      const computedValue = this.servings * value;
      const floorValue = Math.floor(computedValue);
      const remainder = computedValue - floorValue;
      function getFractionnalPart(float: number) {
        switch (float) {
          case 0.25:
            return '¼';
          case 0.5:
            return '½';
          case 0.75:
            return '¾';
          default:
            break;
        }
        return float;
      }
      const fractionnalPart = getFractionnalPart(remainder);
      if (floorValue === 0) {
        return fractionnalPart;
      }
      return computedValue;
    },
    getTitle(ingredient: IIngredient) {
      return ingredient?.aliment?.data?.attributes?.name;
    },
  },
});
