/* eslint @typescript-eslint/no-shadow: ["error", { "allow": ["state"] }] */
import {
  Module,
  GetterTree,
  MutationTree,
  ActionTree,
  ActionContext,
} from 'vuex';

import GroceryRepository from '@/repositories/grocery';

import { GroceryAliment, IGrocery } from '@/typings/interfaces';
import GroceryList from '@/typings/classes/grocery.class';
import Menu from '@/typings/classes/menu.class';
import { Library } from '@/typings/classes/library.class';
import {
  GroceryMutationTypes,
  GroceryActionTypes,
} from './grocery-types';

// Declaration for filters
const repository = new GroceryRepository();

type State = {
  lists: GroceryList[]
};

type Getters = {
  get(state: State): GroceryList[],
  findByMenuId(state: State): (id: number) => GroceryList | undefined,
};

type Mutations<S = State> = {
  [GroceryMutationTypes.SET_DATA](state: S, payload: IGrocery): void;
  [GroceryMutationTypes.SET_LISTS](state: S, payload: GroceryList[]): void;
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, unknown>, 'commit'>;

interface Actions {
  [GroceryActionTypes.FETCH_GROCERY](
    { commit }: AugmentedActionContext, id: number
  ): Promise<boolean>;
  [GroceryActionTypes.FETCH_GROCERY_LISTS](
    { commit }: AugmentedActionContext
  ): Promise<boolean>;
  [GroceryActionTypes.UPDATE_PURCHASED](
    { commit }: AugmentedActionContext,
    payload: { aliment: GroceryAliment, groceryId: number, checked: boolean },
  ): Promise<boolean>;
  [GroceryActionTypes.UPDATE_LIST](
    { commit }: AugmentedActionContext,
    payload: { menu: Menu },
  ): Promise<boolean>;
}

const state: State = {
  lists: [],
};

const getters: GetterTree<State, unknown> & Getters = {
  get: (state) => state.lists,
  findByMenuId: (state) => (id: number) => state.lists.find(({ menu }) => menu === id),
  findById: (state) => (id: number) => state.lists.find(({ id: listId }) => listId === id),
};

const mutations: MutationTree<State> & Mutations = {
  [GroceryMutationTypes.SET_DATA](state: State, payload: IGrocery) {
    const keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      Object.assign(state, {
        [key as keyof IGrocery]: payload[key as keyof IGrocery],
      });
    }
  },
  [GroceryMutationTypes.SET_LISTS](state: State, payload: GroceryList[]) {
    state.lists = payload;
  },
  [GroceryMutationTypes.CLEAR](state: State) {
    state.lists = [];
  },
};

const actions: ActionTree<State, unknown> & Actions = {

  async [GroceryActionTypes.FETCH_GROCERY]({ commit }, payload): Promise<boolean> {
    const { data: { data: list } } = await repository.findOne(payload.toString());
    commit(GroceryMutationTypes.SET_DATA, list as IGrocery);
    return true;
  },
  async [GroceryActionTypes.FETCH_GROCERY_LISTS]({ commit, rootGetters }): Promise<boolean> {
    const menus = rootGetters['menus/getClasses'] as Menu[];
    const library = rootGetters['library/get'];
    const { data: { data: lists } } = await repository.find();

    const groceryLists = (lists as IGrocery[]).map(
      (list: IGrocery) => new GroceryList(list, menus, library),
    );
    commit(GroceryMutationTypes.SET_LISTS, groceryLists);
    return true;
  },
  async [GroceryActionTypes.CLEAR]({ commit })
    : Promise<boolean> {
    commit(GroceryMutationTypes.CLEAR);
    return true;
  },
  async [GroceryActionTypes.UPDATE_PURCHASED]({ state, rootGetters }, payload): Promise<boolean> {
    const user = rootGetters['user/get'];
    const grocery = state.lists.find(({ id }) => id === payload.groceryId);
    if (grocery) {
      if (grocery.isPurchased(payload.aliment)) {
        grocery.unpurchaseAliment(payload.aliment);
      } else {
        grocery.purchaseAliment(payload.aliment);
      }
      await repository.update(grocery.id, grocery, user.id);
      return true;
    }
    return false;
  },
  async [GroceryActionTypes.UPDATE_MULTIPLE_PURCHASED]({
    state, rootGetters,
  }, payload): Promise<boolean> {
    const user = rootGetters['user/get'];
    const grocery = state.lists.find(({ id }) => id === payload.groceryId);
    if (grocery) {
      if (grocery.isPurchased(payload.aliment)) {
        grocery.unpurchaseAllAlimentFromId(payload.aliment);
      } else {
        grocery.purchaseAllAlimentFromId(payload.aliment);
      }
      await repository.update(grocery.id, grocery, user.id);
      return true;
    }
    return false;
  },
  async [GroceryActionTypes.UPDATE_LIST]({ state, rootGetters }, payload): Promise<boolean> {
    const library = rootGetters['library/get'] as Library;
    const grocery = state.lists.find(({ menu }) => menu === payload.menu.id);
    if (grocery) {
      grocery.updateList([payload.menu], library);
    }
    return true;
  },

};

const groceryModule: Module<State, unknown> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default groceryModule;
