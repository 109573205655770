import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IAdvice, IImage } from '@/typings/interfaces';

type AdviceResult = {
  data: IAdvice[] | IAdvice,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};
type AdviceCategoriesResult = {
  data: { id: number, attributes: { title: string, picture: { data: IImage | null } } }[],
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};

class AdviceRepository implements IRead<AdviceResult | AdviceCategoriesResult> {
  private config = AxiosConfig;

  private prefix = 'advices';

  async find(): Promise<AxiosResponse<AdviceResult>> {
    try {
      return this.config.get(this.prefix);
    } catch (err) {
      console.error('PC -- Error AdviceRepository');
      console.error(err);
      throw err;
    }
  }

  async findOne(id: string): Promise<AxiosResponse<AdviceResult>> {
    try {
      return this.config.get(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error AdviceRepository');
      console.error(err);
      throw err;
    }
  }

  async findCategories(): Promise<AxiosResponse<AdviceCategoriesResult>> {
    try {
      return this.config.get('advice-categories');
    } catch (err) {
      console.error('PC -- Error AdviceRepository');
      console.error(err);
      throw err;
    }
  }
}

export default AdviceRepository;
