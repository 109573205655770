import { ApiObject } from '../../api';
import { ApiReferralReferral } from '../../strapi-generation/schemas';
import { PluginUsersPermissionsUser } from '../../strapi-generation/tmpschemas';

export default class Referral implements ApiObject<ApiReferralReferral> {
  id: number;
  createdAt: Date;
  referral: ApiObject<PluginUsersPermissionsUser>;
  referees: Partial<Record<'email' | 'password' | 'id' | 'role' | 'username' | 'profile' | 'createdAt' | 'updatedAt' | 'provider' | 'resetPasswordToken' | 'confirmationToken' | 'confirmed' | 'blocked', any>>[];
}
