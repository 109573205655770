
import { defineComponent, computed } from 'vue';
import {
  SvgRecipeCooking,
  SvgRecipeFlame,
  SvgRecipeKnife,
} from './svg';

export default defineComponent({
  name: 'RecipeDetailsColumn',
  props: {
    detailValue: {
      type: Number,
      default: 20,
    },
    detailTitle: {
      type: String,
      default: 'Temps de préparation',
    },
    detailIcon: {
      type: String,
      default: 'Cooking',
    },
    detailMesureUnit: {
      type: String,
      default: 'min',
    },
  },
  setup(props) {
    const iconComponent = computed(() => {
      switch (props.detailIcon) {
        case 'Cooking':
          return SvgRecipeCooking;
        case 'Flame':
          return SvgRecipeFlame;
        case 'Knife':
          return SvgRecipeKnife;
        default:
          return SvgRecipeCooking;
      }
    });
    return { iconComponent };
  },
});
