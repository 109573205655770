
import { defineComponent, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store';

import {
  IonFab,
  IonFabButton,
  IonThumbnail,
  IonCheckbox,
  IonModal,
  toastController,
} from '@ionic/vue';

import {
  addOutline,
  checkmark,
  heartOutline,
  heart,
  close,
  shareOutline,
} from 'ionicons/icons';

import LibraryFoodlistCreateModal from '@/components/library/modals/LibraryFoodlistCreateModal.vue';

import Foodlist from '@/typings/classes/foodlist.class';
import { Library } from '@/typings/classes/library.class';
import Track from '@/services/tracking/firebase';
import { Share } from '@capacitor/share';
import ShareButton from '@/components/common/share/ShareButton.vue';
import RecipeFoodlistItem from './RecipeFoodlistItem.vue';

export default defineComponent({
  name: 'RecipeFabButtons',
  components: {
    IonFab,
    IonFabButton,
    IonCheckbox,
    IonThumbnail,
    RecipeFoodlistItem,
    ShareButton,
    LibraryFoodlistCreateModal,
  },
  emits: ['open:modal'],
  setup() {
    const canShare = ref(true);
    const store = useStore();
    const route = useRoute();
    const library = computed(() => store.getters['library/get'] as Library);
    const recipeId = computed(() => parseInt(route.params.id as string, 10));
    const recipe = computed(() => library.value.findRecipeById(recipeId.value));
    const imageUrl = computed(() => {
      if (import.meta.env.MODE === 'development') {
        return `http://localhost:1337${recipe.value?.imageSource}`;
      }
      return `https://api.petitcitron.fr${recipe.value?.imageSource}`;
    });
    const favoriteList = computed(() => store.getters['foodlists/favoriteFoodlist'] as Foodlist);
    const isFavoriteRecipe = computed(() => store.getters['foodlists/isRecipeFavorite'](recipeId.value));
    const isFoodlistModalOpen = ref(false);

    return {
      favoriteList,
      isFavoriteRecipe,
      isFoodlistModalOpen,

      canShare,
      recipeId,
      library,
      recipe,
      imageUrl,
      addOutline,
      checkmark,
      heartOutline,
      heart,
      close,
      shareOutline,
    };
  },
  computed: {
    foodlists(): Foodlist[] {
      return (this.$store.getters['foodlists/get'] as Foodlist[])
        .filter(({ isFavorite }) => !isFavorite);
    },
    recipeSharingContent() {
      const title = this.recipe?.title;
      const ingredientsTitle = '\nIngrédients :';
      const ingredients = this.recipe?.ingredients?.map((i) => `- ${i.aliment.data.attributes.name} x ${i.quantity}`).join('\n');
      const stepsTitle = '\nÉtapes :';
      const steps = this.recipe?.steps?.map((s) => s.shortDescription).join('\n');
      return [title, ingredientsTitle, ingredients, stepsTitle, steps].join('\n');
    },
    recipeWebSiteUrl() {
      return `${import.meta.env.VITE_PETIT_CITRON_WEBSITE}/recette/${this.recipe?.seoUrl}`;
    },
  },
  mounted() {
    Share.canShare().then((canShare) => {
      this.canShare = canShare.value;
    });
  },
  methods: {
    openNewFoodlistModal() {
      this.isFoodlistModalOpen = true;
    },
    closeNewFoodlistModal() {
      this.isFoodlistModalOpen = false;
    },
    haveRecipeInFoodlist(foodlist: Foodlist) {
      return foodlist?.recipes.includes(this.recipeId) ?? false;
    },
    async toggleRecipeInFoodlist(foodlistId: number) {
      Track.logEvent('add_to_foodlist', { id: this.recipe?.id, title: this.recipe?.title });
      await this.$store.dispatch(
        'foodlists/toggleRecipeInFoodlist',
        { recipe: this.recipeId, foodlist: foodlistId },
      );
    },
    async toggleRecipeInFavorite() {
      Track.logEvent('add_to_favorite', { id: this.recipe?.id, title: this.recipe?.title });
      await this.$store.dispatch(
        'foodlists/toggleRecipeInFoodlist',
        { recipe: this.recipeId, foodlist: this.favoriteList.id },
      );
    },
    async presentToastForError(action: boolean | string) {
      if (action === false) {
        const message = 'Impossible de rajouter la recette à la foodlist';
        const color = 'danger';
        await this.presentToast({ message, color });
      }
    },
    async presentToastWhenFoodlistIsToggled(foodlist: number | null) {
      const foodlistTitle = foodlist
        ? `la foodlist ${this.foodlists.find(({ id }) => id === foodlist)?.title}`
        : 'tes favoris';
      const haveRecipeInFoodlist = foodlist
        ? this.foodlists.some(({ id, recipes: r }) => id === foodlist && r.includes(this.recipeId))
        : this.isFavoriteRecipe;
      const recipe = (this.$store.getters['library/get'] as Library).findRecipeById(this.recipeId);
      await this.toggleRecipeInFoodlist(foodlist as number);
      const message = `La recette ${recipe?.title} a été ${!haveRecipeInFoodlist ? 'ajoutée à' : 'retirée de'} ${foodlistTitle}`;
      const color = !haveRecipeInFoodlist ? 'primary' : 'danger';
      await this.presentToast({ message, color });
    },
    async presentToastWhenFoodlistIsCreated(foodlistId: number) {
      const foodlistTitle = this.foodlists.find(({ id }) => id === foodlistId)?.title;
      const message = `La foodlist ${foodlistTitle} a bien été crée`;
      await this.presentToast({ message, color: 'light' });
      await this.presentToastWhenFoodlistIsToggled(foodlistId);
    },
    async presentToast({ message, color }: { message: string, color: string }) {
      const toast = await toastController.create({
        message,
        duration: 1200,
        position: 'bottom',
        color,
      });

      await toast.present();
    },
    cancel() {
      const modal = this.$refs.modal as typeof IonModal;
      modal.$el?.dismiss(null, 'cancel');
    },
  },
});
