
import { AxiosInstance } from 'axios';
import { Session } from '../entity/Session';

export default class SessionController {
  private axiosInstance: AxiosInstance;
  private baseUrl: string;

  constructor(axiosInstance: AxiosInstance, baseUrl: string) {
    this.axiosInstance = axiosInstance;
    this.baseUrl = baseUrl;
  }

  // existing CRUD operations

  post = (session: Partial<Session>) => this.axiosInstance.post(`${this.baseUrl}`, session);
}
