
import {
  defineComponent, computed, inject, Ref,
} from 'vue';

import MenuItemMeal from '@/components/menus/MenuItemMeal.vue';
import { IonChip, loadingController, pickerController } from '@ionic/vue';
import { chevronExpandOutline } from 'ionicons/icons';

import Menu from '@/typings/classes/menu.class';
import { Meal } from '@/typings/classes/meal.class';
import { Library } from '@/typings/classes/library.class';

export default defineComponent({
  name: 'MenuListMeals',
  components: { MenuItemMeal, IonChip },
  setup() {
    const daySelected = inject('daySelected') as Ref<Date>;
    const dayName = computed(() => daySelected.value
      .toLocaleDateString('fr-FR', { weekday: 'long' }));
    const daySelectedIso = computed(() => daySelected.value.toISOString());
    return {
      dayName,
      daySelected,
      daySelectedIso,
      chevronExpandOutline,
    };
  },
  computed: {
    menus(): Menu[] {
      return this.$store.getters['menus/getClasses'] ?? [];
    },
    menu(): Menu | null {
      if (this.menus.length === 0) return null;
      return this.menus
        .find((menu) => menu && (menu?.isMenuBetweenDates(this.daySelectedIso) ?? false)) ?? null;
    },
    meals(): Meal[] {
      if (this.menu === null) return [];
      return this.menu?.getMeals();
    },
    currentMealSize(): number {
      return this.meals.length;
    },
    isMenuEmpty(): boolean {
      return this.menu?.isEmptyByDay(this.daySelectedIso) ?? false;
    },
    library(): Library {
      return this.$store.getters['library/get'];
    },
    firstname(): string {
      return this.$store.getters['user/firstname'];
    },
  },
  methods: {
    updateServings(servings: number, meal: Meal) {
      if (meal) {
        meal.setServings(servings);
        this.$store.dispatch('menus/updateMenuById', this.menu?.id);
      }
    },
    async addMealsToMenu(mealSize: number) {
      await this.$store.dispatch('menus/fill', {
        menuId: this.menu?.id,
        mealSize,
      });
      return true;
    },
    async openActionSheet() {
      // add a message if menu is equal to 12
      const options = Array.from(Array(12).keys())
        .map((i) => ({ text: `${i + 1}`, value: i + 1 }))
        .slice(this.currentMealSize, 12);
      if (options.length === 0) {
        options.push({ text: '12', value: 12 });
      }
      const picker = await pickerController.create({
        cssClass: 'color-pickes-menu-item-meal',
        columns: [
          {
            name: 'mealSize',
            selectedIndex: 0,
            options,
          },
        ],
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
          },
          {
            text: 'Valider',
            handler: ({ mealSize }: {
              mealSize: { text: string, value: number, columnIndex: number }}) => {
              // add handler to update meal size
              this.presentLoading(mealSize.value);
            },
          },
        ],
      });
      await picker.present();
    },
    async presentLoading(mealSize: number) {
      const { length } = this.meals;
      const diff = mealSize - length;
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: `Ajout de ${diff} recette${diff > 1 ? 's' : ''}...`,
          duration: 6000,
        });
      await loading.present();
      try {
        await this.addMealsToMenu(mealSize);
      } catch (err) {
        this.$sentry.captureException(err);
      } finally {
        loading.dismiss();
      }
    },
  },
});
