import { uniqBy } from 'lodash';
import { IGrocery, GroceryAliment } from '../interfaces';
import { Library, LibraryGroup } from './library.class';
import Menu from './menu.class';

class GroceryList {
  public id: number;

  public menu: number;
  /**
   * Map <number: recipeId, number[]: alimentId[]>
   */
  public purchasedItems: Map<number, number[]> = new Map();

  /**
   * all aliments in the grocery list
   */
  public aliments: GroceryAliment[] = [];

  /**
   * Count aliments in the grocery list
   */
  public alimentsQuantity: Map<number, number> = new Map();

  /**
   * List of aliments in the grocery list
   * by recipe id
   */
  private alimentsByRecipeMap: Map<number, GroceryAliment[]> = new Map();
  get alimentsByRecipe() { return Array.from(this.alimentsByRecipeMap); }
  /**
   * List of aliments in the grocery list
   * by group id
   */
  private alimentsByGroupMap: Map<string, GroceryAliment[]> = new Map();
  get alimentsByGroup() {
    return Array.from(this.alimentsByGroupMap);
  }

  constructor(list: IGrocery, menus: Menu[], library: Library) {
    this.id = list.id;
    this.menu = list.attributes.menu.data.id;
    this.purchasedItems = new Map(list.attributes.purchasedItems);
    this.updateList(menus, library);
  }

  /**
   * Update the grocery list from a menu found in all menus
   * @param menus all menus
   * @param library
   */
  updateList(menus: Menu[], library: Library): void {
    this.hydrateAliments({ menus, library });
    this.hydrateAlimentsQuantity();
    this.hydrateAlimentsByGroup(library.groups);
    this.hydrateAlimentsByRecipe();
  }

  // eslint-disable-next-line max-lines-per-function
  hydrateAliments({ menus, library } :{ menus: Menu[]; library: Library }): void {
    const menu = menus?.find((m) => m.id === this.menu);
    if (menu) {
      const { meals } = menu;
      const { recipesIdWithServings } = menu;
      const alimentsList: GroceryAliment[] = [];

      Array.from(recipesIdWithServings).forEach(([recipeId, servings]) => {
        const recipeAlimentsList = library.getGroceryListFromRecipe(recipeId, servings);
        alimentsList.push(...recipeAlimentsList);
      });
      meals.forEach(({ aliments: mealAliments, servings }) => {
        mealAliments.forEach((aliment) => {
          const { quantity, aliment: id } = aliment;
          if (id) {
            const mealAliment = library.aliments.get(id);
            if (mealAliment) {
              this.aliments.push({
                id,
                name: mealAliment.name,
                groupCode: mealAliment.group ?? '0000',
                servings,
                unit: null,
                quantity: quantity * servings,
                recipe: 0,
                picture: mealAliment.picture,
              });
            }
          }
        });
      });
      this.aliments = alimentsList;
    }
  }

  hydrateAlimentsQuantity(): void {
    // check if aliment have the same unit and if not, convert it
    this.alimentsQuantity = new Map();
    this.aliments.forEach((aliment) => {
      const count = this.alimentsQuantity.get(aliment.id) ?? 0;
      this.alimentsQuantity.set(aliment.id, count + aliment.quantity);
    });
  }

  hydrateAlimentsByGroup(groups: LibraryGroup[]): void {
    this.alimentsByGroupMap = new Map();
    const alimentsSortedByGroup = [...this.aliments];
    alimentsSortedByGroup.sort((a, b) => a.groupCode.localeCompare(b.groupCode));
    alimentsSortedByGroup.forEach((aliment) => {
      const libraryGroup = groups.find(({ codes }) => codes.includes(aliment.groupCode));
      if (libraryGroup) {
        const group = this.alimentsByGroupMap.get(libraryGroup.title) ?? [];
        this.alimentsByGroupMap.set(libraryGroup.title, uniqBy([...group, aliment], 'id'));
      }
    });
  }

  hydrateAlimentsByRecipe(): void {
    this.alimentsByRecipeMap = new Map();
    this.aliments.forEach((aliment) => {
      const aliments = this.alimentsByRecipeMap.get(aliment.recipe) ?? [];
      this.alimentsByRecipeMap.set(aliment.recipe, uniqBy([...aliments, aliment], 'id'));
    });
  }

  isPurchased(aliment: GroceryAliment): boolean | undefined {
    const aliments = this.purchasedItems.get(aliment.recipe ?? -1);
    return aliments?.includes(aliment.id);
  }

  purchaseAliment(aliment: GroceryAliment) {
    const aliments = this.purchasedItems.get(aliment.recipe ?? -1);
    if (aliments) {
      this.purchasedItems.set(aliment.recipe, [...aliments, aliment.id]);
    } else {
      this.purchasedItems.set(aliment.recipe, [aliment.id]);
    }
  }

  unpurchaseAliment(aliment: GroceryAliment) {
    const aliments = this.purchasedItems.get(aliment.recipe ?? -1);
    if (aliments) {
      this.purchasedItems.set(aliment.recipe, aliments.filter((id) => id !== aliment.id));
    }
  }

  purchaseAllAlimentFromId(aliment: GroceryAliment) {
    const aliments = this.aliments.filter((a) => a.id === aliment.id);
    aliments.forEach((a) => this.purchaseAliment(a));
  }

  unpurchaseAllAlimentFromId(aliment: GroceryAliment) {
    const aliments = this.aliments.filter((a) => a.id === aliment.id);
    aliments.forEach((a) => this.unpurchaseAliment(a));
  }

  mergedAliments() {
    const alimentsMap = new Map();
    this.aliments.forEach((a) => {
      if (alimentsMap.has(a.id) && alimentsMap.get(a.id).unit === a.unit) {
        const aliment = a;
        aliment.quantity += alimentsMap.get(a.id).quantity;
        alimentsMap.set(a.id, aliment);
      } else {
        alimentsMap.set(a.id, a);
      }
    });
    return Array.from(alimentsMap.values());
  }
}

export default GroceryList;
