import { CollectionTypeSchema } from '../engine';
import HttpInstance from './HttpInstanceInterface';
import { ApiObject } from './types';

type SelectivePartial<T = any, K extends keyof T = keyof T> = {
  [P in keyof T]: P extends K ? T[P] | undefined : T[P];
};

export default abstract class CrudHandler<T extends ApiObject<CollectionTypeSchema>> {
  protected abstract resourceUrl: string;
  abstract map(json: unknown) : T;
  protected defaultPopulate = null as string | null;
  public httpInstance: HttpInstance;

  constructor(httpInstance: HttpInstance) {
    this.httpInstance = httpInstance;
  }

  public create(item: T | T[]): Promise<T | T[]> {
    return this.httpInstance.create(this.resourceUrl, item);
  }

  public findOne(id: string): Promise<T> {
    let url = `${this.resourceUrl}/${id}`;
    if (this.defaultPopulate) {
      url += `?${this.defaultPopulate}`;
    }
    return this.httpInstance.read(url) as Promise<T>;
  }

  public async find(): Promise<T[]> {
    let url = this.resourceUrl;
    if (this.defaultPopulate) {
      url += `?${this.defaultPopulate}`;
    }
    return this.httpInstance
      .read(url)
      .then((result) => {
        if (Array.isArray(result)) return result.map((e: unknown) => this.map(e));
        return [this.map(result)];
      });
  }


  public async update(id: string, item: Partial<ApiObject<CollectionTypeSchema>>): Promise<T> {
    const object: Partial<CollectionTypeSchema>
    & { id?: number } = {
      info: {},
      attributes: { ...item } as SelectivePartial,
    };
    if (id) object.id = +id;

    return this.httpInstance.update(
      `${this.resourceUrl}/${id}`,
      object,
    ).then((result) => this.map(result));
  }

  public async delete(id: string): Promise<void> {
    const response = await fetch(`${this.resourceUrl}/${id}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error(`Error deleting item: ${response.status} - ${await response.text()}`);
    }
  }
}
