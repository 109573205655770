import { CrudHandler, HttpInstance } from '@/tmp/src';
import conf from '@/conf/HttpInstance';
import { get } from 'lodash';
import GamificationEvent, { EventKeys } from '@/tmp/src/entities/gamification/gamificationEvent';


type PictureFormat = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null;
  size: number;
  width: number;
  height: number;
};

type PictureDataAttributes = {
  name: string;
  alternativeText: null;
  caption: null;
  width: number;
  height: number;
  formats: {
    thumbnail: PictureFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  provider_metadata: null;
  createdAt: string;
  updatedAt: string;
};

interface Attributes {
  title: string;
  description: string;
  earning: number;
  picture: {
    data: {
      id: number;
      attributes: PictureDataAttributes;
    } | null;
  };
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  key: EventKeys;
  order: number;
}

interface GamificationEventJson {
  id: number;
  attributes: Attributes;
}

class GamificationEventRepository extends CrudHandler<GamificationEvent> {
  protected resourceUrl = 'gamification-events';

  // eslint-disable-next-line no-useless-constructor
  constructor(httpInstance: HttpInstance) {
    super(httpInstance);
  }

  // eslint-disable-next-line class-methods-use-this
  map(json: GamificationEventJson): GamificationEvent {
    const { id, attributes } = json;
    const gamificationEvent = new GamificationEvent();
    gamificationEvent.id = id;
    gamificationEvent.title = attributes.title;
    gamificationEvent.description = attributes.description;
    gamificationEvent.earning = attributes.earning;
    gamificationEvent.createdAt = new Date(attributes.createdAt);
    gamificationEvent.updatedAt = new Date(attributes.updatedAt);
    gamificationEvent.publishedAt = new Date(attributes.publishedAt);
    gamificationEvent.picture = get(json, 'attributes.picture.data.attributes.url', '');
    gamificationEvent.key = attributes.key;
    gamificationEvent.order = attributes.order;
    return gamificationEvent;
  }
}

export default new GamificationEventRepository(conf);
