/* eslint-disable no-debugger */
import Fuse from 'fuse.js';
import Filters from './Filters';

type SearchResult<T> = Fuse.FuseResult<T>;

export default abstract class FuseSearchEngine<T> {
  collection: T[];
  fuse: Fuse<T>;
  filters: Filters<T>;
  options: Fuse.IFuseOptions<T>;

  constructor(
    collection: T[],
    options: Fuse.IFuseOptions<T>,
    filters: Filters<T> = new Filters<T>({}),
  ) {
    this.collection = collection;
    this.filters = filters;
    this.options = options; // Assign the provided options to the instance property

    const { keys } = this.options;
    const FuseIndex = Fuse.createIndex(
      keys as Array<Fuse.FuseOptionKey<T>>,
      collection,
    );

    this.fuse = new Fuse([], this.options);
    this.fuse.setCollection(this.collection, FuseIndex);
  }

  search(keywords: string): SearchResult<T>[] {
    const expressions: Fuse.Expression = { $and: [] };
    const filterExpression = this.filters.buildExpressionForFuseJSFromList();
    const words = keywords.length <= 1 ? '!azertyuiopaze' : keywords;
    const wordsExpression: Fuse.Expression = {
      $or: (this.options.keys as Fuse.FuseOptionKeyObject<T>[])
        .map((key) => ({ [<string>key.name]: words })),
    };
    (expressions.$and as Fuse.Expression[]).push(wordsExpression);
    if (filterExpression.length) {
      (expressions.$and as Fuse.Expression[]).push(...filterExpression);
    }
    const results = this.fuse.search(expressions) as SearchResult<T>[];
    const filtersFunctions = this.filters.buildFiltersFunctionForFuseJSFromList();
    return results.filter((result) => filtersFunctions.every((filter) => filter(result)));
  }
}

