
import {
  ref,
  PropType,
  defineComponent,
} from 'vue';

import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  InfiniteScrollCustomEvent,
} from '@ionic/vue';

import Recipe from '@/typings/classes/recipe.class';
import RecipeRectangleCard from '@/components/common/RecipeRectangleCard.vue';

export default defineComponent({
  name: 'LibraryInfiniteScroll',
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    RecipeRectangleCard,
  },
  props: {
    recipes: {
      type: Object as PropType<Recipe[]>,
      default: [] as Recipe[],
    },
  },
  setup(props) {
    const isDisabled = ref(false);
    // items to be displayed
    const items = ref([] as Recipe[]);
    const pushData = () => {
      const max = Math.min(items.value.length + 20, props.recipes.length);
      const min = Math.max(items.value.length, 0);
      for (let i = min; i < max; i += 1) {
        items.value.push(props.recipes[i]);
      }
    };
    const loadData = (ev: InfiniteScrollCustomEvent) => {
      setTimeout(() => {
        // add data
        if (props.recipes.length > 0) {
          isDisabled.value = false;
          pushData();
        } else {
          isDisabled.value = true;
        }
        ev?.target.complete();
        if (items.value.length === props.recipes.length) {
          isDisabled.value = true;
        }
      }, 500);
    };

    return {
      loadData,
      pushData,
      items,
      isDisabled,
    };
  },
  watch: {
    recipes: {
      handler(value) {
        if (value.length > 0) {
          this.items = [];
          this.pushData();
        } else {
          this.items = [];
        }
      },
    },
  },
  mounted() {
    this.pushData();
  },
});
