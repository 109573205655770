import {
  last as _last,
  first as _first,
} from 'lodash';

import moment from 'moment';

import { getBMI, getIdealWeight } from '@/services/nutrition';

import { IUser, UserWeight, BiologicalSex, AlimentRestriction } from '@/typings/interfaces';
import { EDiets, EPhysicalActivity } from '@/typings/enums';

class User implements Omit<IUser, 'provider'> {
  id: number;
  email: string | null;
  lastname: string | null;
  firstname: string | null;
  birthdate: string | null;

  height: number;
  weights: UserWeight[];
  biologicalSex: BiologicalSex | null;

  haveAcceptCGU: boolean;

  diets?: EDiets[] | null | undefined;

  objective: 'eatHealthy' | 'weightLoss' | null;

  startObjectiveDate: Date | null;
  objectiveDate: Date | null;

  adults: number;
  children: number;

  objectiveWeight: number | null;
  physicalActivity?: EPhysicalActivity | null | undefined;
  sportSessionsPerWeek: number | null;

  allergies: number[] | null;
  excludedAliments: number[] | null;
  alimentRestriction: AlimentRestriction[];

  metaData: {
    referralCode: string;
  };

  constructor(user: Omit<IUser, 'provider'>) {
    this.id = user.id;
    this.email = user.email;
    this.lastname = user.lastname;
    this.firstname = user.firstname;
    this.birthdate = user.birthdate;
    this.height = user.height;
    this.weights = user.weights;
    this.diets = user.diets;
    this.biologicalSex = user.biologicalSex;
    this.objective = user.objective;
    this.haveAcceptCGU = user.haveAcceptCGU;
    this.startObjectiveDate = user.startObjectiveDate;
    this.objectiveDate = user.objectiveDate;
    this.objectiveWeight = user.objectiveWeight;
    this.physicalActivity = user.physicalActivity;
    this.sportSessionsPerWeek = user.sportSessionsPerWeek;
    this.allergies = user.allergies;
    this.excludedAliments = user.excludedAliments;
    this.adults = user.adults ?? 1;
    this.children = user.children ?? 0;
    this.metaData = user.metaData;
    this.alimentRestriction = user.alimentRestriction;
  }

  get age() {
    const birthdate = new Date(this.birthdate ?? 0);
    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    const month = today.getMonth() - birthdate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
      age -= 1;
    }
    return age;
  }

  // get body mass indice
  get bmi() { return getBMI(this); }
  get idealWeight() { return getIdealWeight(this); }

  get weightLoss() {
    const firstTrack = _first(this.weights) || { value: 0 };
    const currentWeight = _last(this.weights) || { value: 0 };
    return firstTrack.value - currentWeight.value;
  }

  get weightToLoss() {
    const currentWeight = _last(this.weights) || { value: 0 };
    return currentWeight.value - (this.objectiveWeight ?? 0);
  }

  static weigthLossStatistics(weights: UserWeight[]) {
    const defaultValue = [
      { key: 'mois', value: 0 },
      { key: 'semaines', value: 0 },
      { key: 'jours', value: 0 },
    ];
    if (!weights || weights.length === 0) {
      return defaultValue;
    }
    weights.sort((a, b) => a.timestamp - b.timestamp);
    const firstTrack = _first(weights) as NonNullable<UserWeight>;
    const lastTrack = _last(weights) as NonNullable<UserWeight>;
    const momentLastTrack = moment(lastTrack.timestamp);
    const momentFirstTrack = moment(firstTrack.timestamp);
    let loss = 0;
    for (let i = 0; i < weights.length; i += 1) {
      const weight = weights[i];
      const nextWeight = weights[i + 1];
      if (nextWeight) {
        loss += nextWeight.value - weight.value;
      }
    }
    if (loss === 0) {
      return defaultValue;
    }
    const monthDiff = momentLastTrack.diff(momentFirstTrack, 'months') || 1;
    const weekDiff = momentLastTrack.diff(momentFirstTrack, 'weeks') || 1;
    const dayDiff = momentLastTrack.diff(momentFirstTrack, 'days') || 1;
    return [
      { key: 'mois', value: (loss / monthDiff) },
      { key: 'semaine', value: (loss / weekDiff) },
      { key: 'jour', value: (loss / dayDiff) },
    ];
  }
}

export default User;
