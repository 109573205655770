
import { defineComponent, ref, customRef, inject } from 'vue';

import { chevronForward } from 'ionicons/icons';
import { EDiets } from '@/typings/enums';
import { useStore } from '@/store';
import {
  SvgDietGlutenFree,
  SvgDietLactoseFree,
  SvgDietPorkFree,
  SvgDietVegetarian,
  SvgDietPescovegetarian,
  SvgDietNone,
} from '@/components/survey/svg';
import useAllergenStore from '@/stores/allergen/allergenStore';

type DietDisplay = { value: EDiets; label: string; icon: object, excludedGroups?: string[] };
type RecordToDisplay = Record<EDiets, DietDisplay>;

export default defineComponent({
  name: 'SurveyDietary',
  components: {
    SvgDietGlutenFree,
    SvgDietLactoseFree,
    SvgDietPorkFree,
    SvgDietVegetarian,
    SvgDietPescovegetarian,
    SvgDietNone,
  },
  setup() {
    const store = useStore();
    const isOpenRef = ref(false);
    const updateValidation = inject('updateValidation') as (value: boolean) => void;
    const allergenStore = useAllergenStore();

    const dietList: RecordToDisplay = {
      [EDiets.NONE]: { value: EDiets.NONE, label: 'Aucune', icon: SvgDietNone },
      [EDiets.PORKFREE]: { value: EDiets.PORKFREE, label: 'Sans porc', icon: SvgDietPorkFree, excludedGroups: ['Porc'] },
      [EDiets.PESCOVEGETARIAN]: { value: EDiets.PESCOVEGETARIAN, label: 'Pesco végétarien', icon: SvgDietPescovegetarian, excludedGroups: ['Viande'] },
      [EDiets.VEGETARIAN]: { value: EDiets.VEGETARIAN, label: 'Végétarien', icon: SvgDietVegetarian, excludedGroups: ['Viande', 'Poisson'] },
      [EDiets.GLUTENFREE]: { value: EDiets.GLUTENFREE, label: 'Sans gluten', icon: SvgDietGlutenFree },
      [EDiets.LACTOSEFREE]: { value: EDiets.LACTOSEFREE, label: 'Sans lactose', icon: SvgDietLactoseFree, excludedGroups: ['Fromage'] },
    };
    const userDiets = customRef((track, trigger) => ({
      get(): DietDisplay[] {
        track();
        const storedDiets = store.getters['user/get'].diets as EDiets[] ?? [];
        return storedDiets.map((diet) => dietList[diet]);
      },
      set(newValue: DietDisplay[] = []) {
        const diets = newValue.map((dietDisplay) => dietDisplay.value);
        const alimentRestriction = newValue.map((dietDisplay) => (dietDisplay.excludedGroups ?? [])
          .map((group) => {
            const allergen = allergenStore.allergens.find((a) => a.name === group);
            return {
              aliment: undefined,
              reason: 'diet',
              allergen,
            };
          }))
          .flat();
        store.dispatch('user/set', { diets, alimentRestriction });
        trigger();
      },
    }));
    return {
      userDiets,
      dietList,
      allergenStore,
      updateValidation,
      chevronForward,
      isOpenRef,
    };
  },
  computed: {
    isValid() {
      return true;
    },
  },

  watch: {
    userDiets: {
      handler(newDiets, oldDiets) { this.setDiet(newDiets, oldDiets); },
      immediate: true,
    },
    isValid(newValidation) { this.updateValidation(newValidation); },
  },
  methods: {
    setDiet(newDiets: DietDisplay[], previousDiets: DietDisplay[]) {
      const newDietIncludesThisDiet = (diet: EDiets) => newDiets
        .includes(this.dietList[diet]);
      const previousDietIncludesThisDiet = (diet: EDiets) => previousDiets
        .includes(this.dietList[diet]);
      // Test if NONE is selected with other diets
      if (newDiets.length > 1) {
        if (newDietIncludesThisDiet(EDiets.NONE)) {
          // case where NONE was selected
          if (previousDietIncludesThisDiet(EDiets.NONE)) {
            this.userDiets = newDiets.filter((diet) => diet.value !== EDiets.NONE);
            return;
          }
          // case where NONE is selected with other diets
          this.userDiets = [this.dietList[EDiets.NONE]];
          return;
        }
        // case when vegetarian is selected and pesco vegetarian was selected
        if (newDietIncludesThisDiet(EDiets.VEGETARIAN)
        && previousDietIncludesThisDiet(EDiets.PESCOVEGETARIAN)) {
          this.userDiets = newDiets.filter((diet) => diet.value !== EDiets.PESCOVEGETARIAN);
          return;
        }
        // case when pesco vegetarian is selected and vegetarian was selected
        if (newDietIncludesThisDiet(EDiets.PESCOVEGETARIAN)
        && previousDietIncludesThisDiet(EDiets.VEGETARIAN)) {
          this.userDiets = newDiets.filter((diet) => diet.value !== EDiets.VEGETARIAN);
        }
      }
    },
  },

  ionViewDidEnter() {
    this.updateValidation(this.isValid);
  },
});
