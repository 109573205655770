import { AxiosResponse } from 'axios';

import { IUser, UserProfile } from '@/typings/interfaces';

import storage from '@/services/storage';
import AxiosConfig from '@/services/api/index';
import { UserAuthentificationWithProvider, StrapiTypeLogin } from '@/store/user/user-types';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';

type UserResult = {
  data: {
    user: StrapiTypeLogin,
    jwt: string,
  },
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};

type AuthInformation = {
  email: string,
  password: string
};

class UserRepository {
  private config;

  constructor() {
    this.config = AxiosConfig;
    storage.get('jwt').then((jwt) => {
      if (jwt !== null) {
        this.config.defaults.headers.common.Authorization = `Bearer ${jwt}`;
      }
    });
  }

  async register(userToRegister: AuthInformation): Promise<StrapiTypeLogin> {
    await storage.clear();
    this.config.defaults.headers.common.Authorization = false;
    const {
      data: { jwt, user },
    } = await this.config.post('auth/local/register', {
      ...userToRegister,
      username: userToRegister.email,
    }) as UserResult;
    await storage.set('jwt', jwt);
    this.config.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    return user;
  }

  async connectWithApple(userToRegister: SignInWithAppleResponse): Promise<UserProfile> {
    await storage.clear();
    this.config.defaults.headers.common.Authorization = false;
    const {
      data: { jwt },
    } = await this.config.get(`connect/apple?identityToken=${userToRegister.response.identityToken}`) as UserResult;
    await storage.set('jwt', jwt);
    this.config.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    const { data: profile } = await this.config.get('user/profile');
    return profile as Promise<IUser>;
  }

  async login(userToSignIn: AuthInformation): Promise<StrapiTypeLogin> {
    await storage.clear();
    const { email: identifier, password } = userToSignIn;
    delete this.config.defaults.headers.common.Authorization;
    const {
      data: { jwt, user },
    } = await this.config.post('auth/local', { identifier, password }) as UserResult;
    await storage.set('jwt', jwt);
    this.config.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    return user;
  }

  async loginWithProvider(signIn: UserAuthentificationWithProvider) {
    try {
      await storage.clear();
      await storage.set('jwt', signIn.jwt);
      this.config.defaults.headers.common.Authorization = `Bearer ${signIn.jwt}`;
      const { data: profile } = await this.config.get('user/profile');
      return profile as Promise<IUser>;
    } catch (err) {
      console.log(err);
      throw new Error('can\'t login');
    }
  }

  async update(userToUpdate: IUser): Promise<UserProfile> {
    const profile = {
      haveAcceptCGU: true,
      firstname: userToUpdate.firstname,
      birthdate: userToUpdate.birthdate,
      biologicalSex: userToUpdate.biologicalSex,
      height: userToUpdate.height,
      weights: userToUpdate.weights,

      allergies: userToUpdate.allergies,
      diets: userToUpdate.diets,
      excludedAliments: userToUpdate.excludedAliments,
      metaData: userToUpdate.metaData,
      children: userToUpdate.children,
      adults: userToUpdate.adults,
      objective: userToUpdate.objective,
      startObjectiveDate: userToUpdate.startObjectiveDate,
      objectiveDate: userToUpdate.objectiveDate,
      objectiveWeight: userToUpdate.objectiveWeight,
      physicalActivity: userToUpdate.physicalActivity,
      sportSessionsPerWeek: userToUpdate.sportSessionsPerWeek,
      alimentRestriction: userToUpdate.alimentRestriction,
    } as UserProfile;
    const { data: user } = await this.config.put('user/profile', { profile }) as AxiosResponse<UserProfile>;
    return user;
  }

  async profile(): Promise<UserProfile> {
    const jwt = await storage.get('jwt');
    if (!jwt) {
      delete this.config.defaults.headers.common.Authorization;
      throw (new Error('user is not identified'));
    }
    try {
      const { data: profile } = await this.config.get('user/profile') as AxiosResponse<UserProfile>;
      return profile;
    } catch {
      delete this.config.defaults.headers.common.Authorization;
      throw (new Error('user is not identified'));
    }
  }

  async disconnect(): Promise<boolean> {
    await storage.clear();
    delete this.config.defaults.headers.common.Authorization;
    return true;
  }

  async forgotPassword(payload: string): Promise<boolean> {
    await storage.clear();
    const status = await this.config.post('auth/forgot-password', { email: payload });
    delete this.config.defaults.headers.common.Authorization;
    return status.data.ok === true;
  }

  async resetPassword(
    payload: { passwordConfirmation: string, password: string, code: string },
  ): Promise<boolean> {
    await storage.clear();
    delete this.config.defaults.headers.common.Authorization;
    const {
      data: { jwt },
    } = await this.config.post(`auth/reset-password?code=${payload.code}`, {
      password: payload.password,
      passwordConfirmation: payload.passwordConfirmation,
      code: payload.code,
    });
    await storage.set('jwt', jwt);
    this.config.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    return true;
  }

  async changePassword(payload:
  { password: string, currentPassword: string, passwordConfirmation: string }): Promise<boolean> {
    const { data: { jwt } } = await this.config.post('auth/change-password', payload);
    await storage.set('jwt', jwt);
    this.config.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    return true;
  }

  async destroy(_payload: number) {
    await this.config.delete('/user/profile');
    await storage.clear();
    delete this.config.defaults.headers.common.Authorization;
    return true;
  }
}

export default UserRepository;
