import { ApiObject, CrudHandler, HttpInstance } from '@/tmp/src/api';
import conf from '@/conf/HttpInstance';
import GamificationAccount from '@/tmp/src/entities/gamification/gamificationAccount';
import { GamificationGamificationActionEvent } from '@/tmp/src/strapi-generation/schemas';

interface jsonInterface {
  id: number;
  attributes: {
    balance: number;
    challenges: typeof GamificationAccount.prototype.challenges;
    purchases: { data: { id: number }[] };
    events: ApiObject<GamificationGamificationActionEvent>[];
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  }
}

class GamificationAccountRepository extends CrudHandler<GamificationAccount> {
  protected resourceUrl = 'gamification-accounts';

  constructor(httpInstance: HttpInstance) {
    super(httpInstance);
    this.defaultPopulate = null;
  }

  // eslint-disable-next-line class-methods-use-this
  map(json: jsonInterface): GamificationAccount {
    const { id,
      attributes: {
        balance,
        challenges = [],
        purchases = { data: [] },
        events = [],
      } } = json;
    const gamificationAccount = new GamificationAccount();
    gamificationAccount.id = id;
    gamificationAccount.balance = balance;
    gamificationAccount.challenges = challenges;
    gamificationAccount.purchases = purchases.data;
    gamificationAccount.events = events;
    return gamificationAccount;
  }
}

export default new GamificationAccountRepository(conf);
