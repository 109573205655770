import { omit as _omit, last as _last, first as _first } from 'lodash';
import { GetterTree } from 'vuex';
import { AlimentRestriction, INutriments, IUser, UserWeight } from '@/typings/interfaces';

import {
  getBMI,
  getIdealWeight,
  getPhysicalActivityMultiplier,
  getRecommendedMacronutriments,
} from '@/services/nutrition';

import { BMI } from '@/services/nutrition/bmi';
import WeightLossProgram from '@/services/nutrition/program';
import { State } from './state';

type Getters = {
  referralCode(state: State): string,
  isLogged(state: State): boolean,
  firstname(state: State): string,
  haveAcceptCGU(state: State): boolean,
  get(state: State): IUser,
  bmi(state: State): BMI,
  physicalActivityMultiplier(state: State): number,
  allergies: (state: State) => number[],
  idealWeight(state: State): number[],
  weights: (state: State) => UserWeight[],
  firstWeight(state: State): UserWeight,
  currentWeight(state: State): UserWeight,
  weightLoss(state: State, getters: any): number,
  weightToLoss(state: State, getters: any): number,
  getRecommendedUserMacronutrients(state: State): INutriments,
  program(state: State): WeightLossProgram,
  foodRestriction(state: State): AlimentRestriction[],
};

const getters: GetterTree<State, unknown> & Getters = {
  provider: (state) => state.provider,
  referralCode: (state) => state.metaData.referralCode,
  haveAcceptCGU: (state) => state.haveAcceptCGU,
  isLogged: (state) => state.isLogged,
  get: (state) => _omit(state, 'isLogged') as IUser,
  bmi: (state) => getBMI(state as IUser),
  physicalActivityMultiplier: (state) => getPhysicalActivityMultiplier(state as IUser),
  objective: (state) => state.objective,
  firstname: (state) => state.firstname ?? '',
  allergies: (state) => state.allergies ?? [],
  familySize: (state) => (state.children ?? 0) + (state.adults ?? 0),
  // weight computed values
  weights: (state) => state.weights,
  idealWeight: (state) => getIdealWeight(state as IUser),
  firstWeight: (state) => _first(state.weights)
    || ({ timestamp: 0, value: 0 } as UserWeight),
  currentWeight: (state) => _last(state.weights)
    || ({ timestamp: 0, value: 0 } as UserWeight),
  weightLoss: (unused, get) => get.firstWeight.value - get.currentWeight.value,
  percentWeightLost: (state, get) => {
    const percent = Math.floor(get.weightLoss * 100) / (state.objectiveWeight ?? 1);
    if (percent < 0) { return 0; }
    if (percent > 100) { return 100; }
    return percent;
  },
  idealUserWeight: (state, get) => get.firstWeight.value - (state.objectiveWeight ?? 0),
  weightToLoss: (state, get) => {
    const current: number = get.currentWeight.value;
    const first: number = get.firstWeight.value;
    const toLoss = state.objectiveWeight;
    if (!toLoss) return 0;
    return (current - (first - toLoss));
  },
  program: (state) => new WeightLossProgram(state),
  getRecommendedUserMacronutrients: (state) => getRecommendedMacronutriments(state as IUser),
  foodRestriction: (state: State) => state.alimentRestriction,
};

export { Getters, getters };
