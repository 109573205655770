

import { defineComponent, ref, Ref } from 'vue';
import { get } from 'lodash';

import {
  createAnimation,
  Animation,
  useIonRouter,
  loadingController,
  IonInput,
} from '@ionic/vue';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import Track from '@/services/tracking/firebase';
import useGamificationAccountStore from '@/stores/gamification/gamificationAccountStore';
import { EventKeys } from '@/tmp/src/entities/gamification/gamificationEvent';

export default defineComponent({
  name: 'LoginForm',
  setup() {
    const email = ref('');
    const router = useIonRouter();
    const password = ref('');
    const animation: Ref<null | Animation> = ref(null);
    const error: Ref<null | string> = ref(null);
    const passwordInputRef: Ref<typeof IonInput | null> = ref(null);
    const gamificationAccountStore = useGamificationAccountStore();
    const showPasswordInput = ref(false);
    const isPasswordInputText = ref(false);
    return {
      email,
      password,
      error,
      router,
      animation,
      showPasswordInput,
      isPasswordInputText,
      passwordInputRef,
      gamificationAccountStore,
      eyeOutline,
      eyeOffOutline,
    };
  },
  computed: {
    haveBeenUnauthorized() {
      return this.$route.query?.error === 'unauthorized';
    },
  },
  methods: {
    focusPasswordInput() {
      console.log('focusPasswordInput', this.passwordInputRef);
      this.passwordInputRef?.$el.focus();
    },
    async presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Connexion en cours...',
          duration: 6000,
        });
      await loading.present();
      try {
        const redirectionPath = await this.signIn();
        Track.logEvent('login', { method: 'email' });
        return this.router.push(redirectionPath);
      } catch (err) {
        this.$sentry.captureException(err);
        return Promise.resolve();
      } finally {
        loading.dismiss();
      }
    },
    async signIn() {
      const redirectionPath = get(this.$route.query, 'redirect', '/menu');
      try {
        await this.$store.dispatch('user/login', {
          email: this.email,
          password: this.password,
        });
        await this.$store.dispatch('user/me');

        if (this.$store.getters['library/isLibraryInitialized'] === false) {
          await this.$store.dispatch('library/initLibrary');
        }

        await this.$store.dispatch('menus/find');
        await this.$store.dispatch('grocery/fetchGroceryLists');
        await this.$store.dispatch('foodlists/fetchFoodlists');

        const promises = [
          this.gamificationAccountStore.findAccount(),
          this.gamificationAccountStore.createEvent(EventKeys.DAILY_CONNECTION),
        ];
        await Promise.all(promises);
        return redirectionPath;
        // TODO: get a redirection path after all user traitment
      } catch (err: unknown) {
        this.$sentry.captureException(err);
        const status = get(err, 'response.status', null) ?? get(err, 'status', null);
        const message = get(err, 'message');
        if (status === 400 || status === 404 || status === 403) {
          this.error = 'Vérifie tes informations de connexion !';
        } else if (message === 'Network Error') {
          this.error = 'Impossible de communiquer avec le serveur, vérifie ta connexion à internet !';
        } else {
          this.error = 'Une erreur est survenue !';
        }
        if (this.animation === null) {
          this.animation = createAnimation()
            .addElement(this.$refs.errorRef as Element)
            .duration(300)
            .fromTo('transform', 'translateY(-20px)', 'translateX(0)')
            .fromTo('opacity', '0', '1');
        }
        this.animation.play();
        return Promise.resolve('/login');
      }
    },
  },
});
