import { ApiObject } from '../../api';
import { GamificationGamificationChallengeEvent, ApiGamificationChallengeGamificationChallenge } from '../../strapi-generation/schemas';

export default
class GamificationAccountChallenge implements ApiObject<GamificationGamificationChallengeEvent> {
  [key: string]: any;
  challenge: ApiObject<ApiGamificationChallengeGamificationChallenge>;
  progression: number;
  creationDate: Date;
  updateDate: Date;
  id: number;
}
