import { AxiosInstance } from 'axios';
import { Cart } from '../entity/Cart';
import { CartBody } from '../entity/CartBody';

export default class CartController {
  private axiosInstance: AxiosInstance;
  private baseUrl: string;

  constructor(axiosInstance: AxiosInstance, baseUrl: string) {
    this.axiosInstance = axiosInstance;
    this.baseUrl = baseUrl;
  }

  // existing CRUD operations

  get = (id: number): Promise<Cart> => this.axiosInstance.get(`${this.baseUrl}/${id}`);

  create = async (cart: CartBody) => {
    const result = await this.axiosInstance.post(`${this.baseUrl}`, cart);
    return result.data;
  };
}
