
import { defineComponent, customRef, watch, inject } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'SurveySportSessions',
  setup() {
    const store = useStore();
    const updateValidation = inject('updateValidation') as (value: boolean) => void;
    const goToNextSurvey = inject('goToNextSurvey') as () => void;
    const sportSessionsPerWeek = customRef((track, trigger) => ({
      get(): number | null { track(); return store.getters['user/get'].sportSessionsPerWeek; },
      set(newSportSessionsPerWeek: number | null) {
        store.dispatch('user/set', { sportSessionsPerWeek: newSportSessionsPerWeek });
        trigger();
        goToNextSurvey();
      },
    }));
    watch(sportSessionsPerWeek, (newValue) => {
      updateValidation(newValue !== null);
    });
    return { sportSessionsPerWeek, updateValidation };
  },
  ionViewDidEnter() {
    this.updateValidation(this.sportSessionsPerWeek !== null);
  },
});
