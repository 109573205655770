
import { defineComponent, ComputedRef, computed, PropType } from 'vue';
import { useIonRouter, actionSheetController, IonThumbnail, pickerController } from '@ionic/vue';

import {
  pencil,
  caretForwardOutline,
  caretBackOutline,
  personOutline,
  ellipsisVertical,
  close,
  trashOutline,
} from 'ionicons/icons';
import { useStore } from '@/store';

import Recipe from '@/typings/classes/recipe.class';
import { Meal } from '@/typings/classes/meal.class';
import { EMealType } from '@/typings/enums';

import SvgRecipeCooking from '../recipes/svg/SvgRecipeCooking.vue';
import SvgRecipeKnife from '../recipes/svg/SvgRecipeKnife.vue';
// import SvgRecipeFlame from '../recipes/svg/SvgRecipeFlame.vue';

export default defineComponent({
  name: 'MenuItemMeal',
  components: {
    IonThumbnail,
    SvgRecipeCooking,
    // SvgRecipeFlame,
    SvgRecipeKnife,
  },
  props: {
    meal: {
      type: Object as PropType<Meal>,
      default: () => ({}),
    },
    mealType: {
      type: String as PropType<'starterCourse' | 'mainCourse' | 'dessert'>,
      default: 'mainCourse',
    },
    menuId: {
      type: Number,
      default: 1,
    },
  },
  emits: ['edit', 'update:servings'],
  setup(props) {
    const store = useStore();
    const router = useIonRouter();
    const recipeId = computed(() => props.meal[props.mealType as EMealType]);
    const recipes: ComputedRef<Recipe[]> = computed(() => store.getters['library/recipes'] ?? []);
    const recipe: ComputedRef<Recipe | null> = computed(
      () => recipes.value.find(({ id }) => id === recipeId.value) ?? null,
    );
    const goToRecipe = () => {
      router.push(`/recipe/${recipe.value?.id}?mealId=${props.meal.id}`);
    };

    return {
      recipe,
      recipeId,
      close,
      pencil,
      router,
      goToRecipe,
      caretBackOutline,
      caretForwardOutline,
      ellipsisVertical,
      personOutline,
      trashOutline,
    };
  },
  methods: {
    edit() {
      this.router.push(`/research?menu=${this.menuId}&meal=${this.meal.id}`);
    },
    async remove() {
      await this.$store.dispatch('menus/deleteMealByMenuIdAndMealId', { menuId: this.menuId, mealId: this.meal.id });
      // add a route to replace when action is succesfull
    },
    async presentAction(title: string) {
      const modify = {
        text: 'Modifier',
        role: 'selective',
        handler: this.edit,
      };
      const remove = {
        text: 'Supprimer',
        role: 'destructive',
        cssClass: 'text-danger',
        handler: this.remove,
      };
      const cancel = {
        text: 'Annuler',
        role: 'cancel',
        cssClass: 'text-white',
      };
      const actionSheet = await actionSheetController
        .create({
          header: title,
          cssClass: 'fw-medium text-primary',
          buttons: [modify, remove, cancel],
        });
      await actionSheet.present();
    },
    updateServings(value: number) {
      this.$emit('update:servings', value);
    },
    async openPicker() {
      const picker = await pickerController.create({
        cssClass: 'color-pickes-menu-item-meal',
        columns: [
          {
            name: 'servings',
            selectedIndex: this.meal.servings - 1,
            options: Array.from(Array(10).keys()).map((i) => ({
              text: `${i + 1}`,
              value: i + 1,
            })),
          },
        ],
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
          },
          {
            text: 'Valider',
            handler: ({ servings }) => {
              this.updateServings(servings.value);
            },
          },
        ],
      });
      await picker.present();
    },
  },
});
