
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SvgDietPorkFree',
  props: {
    currentColor: {
      type: String,
      default: '#9F9F9F',
    },
  },
});
