import { IAliment } from '../interfaces';

type Macronutrients = {
  carbohydrates: number;
  proteins: number;
  fat: number;
};

type Sugars = {
  fructose: string | undefined;
  galactose: string | undefined;
  glucose: string | undefined;
  lactose: string | undefined;
  maltose: string | undefined;
  sucrose: string | undefined;
};

type Carbohydrates = {
  fiber: string | undefined;
  sugar: string | undefined;
  starch: string | undefined;
  sugars: Sugars;
};

type SaturedFats = {
  saturated: string | undefined;
  mono: string | undefined;
  poly: string | undefined;
  buturic: string | undefined;
  caproic: string | undefined;
  caprylic: string | undefined;
  capric: string | undefined;
  lauric: string | undefined;
  myristic: string | undefined;
  palmitic: string | undefined;
  stearic: string | undefined;
  oleic: string | undefined;
  linoleic: string | undefined;
  alphalinoleic: string | undefined;
  arachidonic: string | undefined;
  epa: string | undefined;
  dha: string | undefined;
};

type Vitamins = {
  a: string | undefined;
  b1: string | undefined;
  b2: string | undefined;
  b3: string | undefined;
  b5: string | undefined;
  b6: string | undefined;
  b9: string | undefined;
  b12: string | undefined;
  c: string | undefined;
  d: string | undefined;
  e: string | undefined;
  k1: string | undefined;
  k2: string | undefined;
  betaCarotene: string | undefined;
};

type Minerals = {
  calcium: string | undefined;
  chloride: string | undefined;
  copper: string | undefined;
  iodine: string | undefined;
  iron: string | undefined;
  magnesium: string | undefined;
  manganese: string | undefined;
  phosphorus: string | undefined;
  potassium: string | undefined;
  selenium: string | undefined;
  sodium: string | undefined;
  zinc: string | undefined;
};

type Nutrients = {
  ash: string | undefined;
  alcohol: string | undefined;
  cholesterol: string | undefined;
  polyols: string | undefined;
  water: string | undefined;
  carbohydrates: Carbohydrates;
  saturedFats: SaturedFats;
  vitamins: Vitamins;
  minerals: Minerals;
};

class Aliment {
  id: number;
  code: string | undefined;
  group: string | undefined;
  name: string;
  pluralName: string | undefined;
  averageWeight: number;
  calories: number;
  macronutrients: Macronutrients;
  nutrients: Nutrients;
  picture: string | null;

  constructor(aliment: IAliment) {
    const { attributes: a } = aliment;
    this.id = aliment.id;
    this.code = a.alim_code;
    this.group = a.group_code;
    this.name = a.name || a.alim_name_fr;
    this.pluralName = a.pluralName || a.alim_name_fr;
    this.picture = a.picture.data?.attributes.url ?? null;
    this.averageWeight = a.averageWeight;
    this.macronutrients = {
      carbohydrates: Aliment.parseNutrientValue(a.carbohydrate),
      proteins: Aliment.parseNutrientValue(a.protein),
      fat: Aliment.parseNutrientValue(a.fat),
    };
    this.calories = Aliment.parseNutrientValue(a.energy_reg_kcal);
    if (this.calories === 0) {
      this.calories = (this.macronutrients.carbohydrates * 4)
      + (this.macronutrients.proteins * 4)
      + this.macronutrients.fat * 9;
    }
    this.nutrients = {
      ash: a.ash,
      alcohol: a.alcohol,
      cholesterol: a.cholesterol,
      polyols: a.polyols,
      water: a.water,
      carbohydrates: Aliment.getCarbohydratesFromAliment(aliment),
      saturedFats: Aliment.getSaturedFatsFromAliment(aliment),
      vitamins: Aliment.getVitaminsFromAliment(aliment),
      minerals: Aliment.getMineralsFromAliment(aliment),
    };
  }

  static getMineralsFromAliment(aliment: IAliment): Minerals {
    const { attributes: a } = aliment;
    const minerals = {
      calcium: a.calcium,
      chloride: a.chloride,
      copper: a.copper,
      iodine: a.iodine,
      iron: a.iron,
      magnesium: a.magnesium,
      manganese: a.manganese,
      phosphorus: a.phosphorus,
      potassium: a.potassium,
      selenium: a.selenium,
      sodium: a.sodium,
      zinc: a.zinc,
    };
    return minerals;
  }

  static getSugarsFromAliment(aliment: IAliment): Sugars {
    const { attributes: a } = aliment;
    const sugars = {
      fructose: a.fructose,
      galactose: a.galactose,
      glucose: a.glucose,
      lactose: a.lactose,
      maltose: a.maltose,
      sucrose: a.sucrose,
    };
    return sugars;
  }

  static getCarbohydratesFromAliment(aliment: IAliment): Carbohydrates {
    const { attributes: a } = aliment;
    const carbohydrates = {
      fiber: a.fibres,
      sugar: a.sugars,
      starch: a.starch,
      sugars: this.getSugarsFromAliment(aliment),
    };
    return carbohydrates;
  }

  static getSaturedFatsFromAliment(aliment: IAliment): SaturedFats {
    const { attributes: a } = aliment;
    const saturedFats = {
      saturated: a.fa_saturated,
      mono: a.fa_mono,
      poly: a.fa_poly,
      buturic: a.fa_buturic,
      caproic: a.fa_caproic,
      caprylic: a.fa_caprylic,
      capric: a.fa_capric,
      lauric: a.fa_lauric,
      myristic: a.fa_myristic,
      palmitic: a.fa_palmitic,
      stearic: a.fa_stearic,
      oleic: a.fa_oleic,
      linoleic: a.fa_linoleic,
      alphalinoleic: a.fa_alphalinoleic,
      arachidonic: a.fa_arachidonic,
      epa: a.fa_epa,
      dha: a.fa_dha,
    };
    return saturedFats;
  }

  static getVitaminsFromAliment(aliment: IAliment): Vitamins {
    const { attributes: a } = aliment;
    const vitamins = {
      a: a.retinol,
      b1: a.vitamin_b1_or_thiamin,
      b2: a.vitamin_b2_or_riboflavin,
      b3: a.vitamin_b3_or_niacin,
      b5: a.vitamin_b5_or_pantothenic_acid,
      b6: a.vitamin_b6,
      b9: a.vitamin_b9_or_folate,
      b12: a.vitamin_b12,
      c: a.vitamin_c,
      d: a.vitamin_d,
      e: a.vitamin_e,
      k1: a.vitamin_k1,
      k2: a.vitamin_k2,
      betaCarotene: a.beta_carotene,
    };
    return vitamins;
  }

  static parseNutrientValue(value: string | undefined) {
    if (!value || value === '-') return 0;
    if (value.includes('<') || value.includes('>')) {
      return parseInt(value.slice(1, value.length - 1), 10);
    }
    if (value === 'traces') return 0;
    return parseInt(value, 10);
  }
}

export default Aliment;
