
import { computed, defineComponent, inject, Ref, ref } from 'vue';
import { IonSearchbar, IonChip } from '@ionic/vue';
import { addOutline, close } from 'ionicons/icons';
import { Library, SearchResult } from '@/typings/classes/library.class';
import Aliment from '@/typings/classes/aliment.class';
import { AlimentRestriction } from '@/typings/interfaces';
import { uniq } from 'lodash';
import Allergen from '@/tmp/src/entities/allergen/allergen';
import useAllergenStore from '@/stores/allergen/allergenStore';
import { useStore } from '@/store/index';

export default defineComponent({
  name: 'SurveyAlimentExclusion',
  components: {
    IonSearchbar, IonChip,
  },
  setup() {
    const store = useStore();
    const library = computed<Library>(() => store.getters['library/get']);

    const userAlimentRestriction = computed<AlimentRestriction[]>(
      () => store.getters['user/get'].alimentRestriction,
    );
    const allergenStore = useAllergenStore();

    // List of aliments ids to display in the featured group
    const alimentsIdsToDisplay = [1461, 137, 132, 12, 1441];

    const featuredGroups = computed<(Allergen & { picture: string })[]>(
      () => alimentsIdsToDisplay.map((id) => {
        const allergen = allergenStore.findByAlimentId(id)
          ?? allergenStore.searchByName(library.value.findAlimentById(id)?.name ?? '')[0].item;
        return {
          ...allergen,
          picture: library.value.findPictureUrlByAlimentId(id),
        } as Allergen & { picture: string };
      }));

    const results: Ref<SearchResult<Aliment | Allergen>[]> = ref([]);
    const updateValidation = inject('updateValidation') as (value: boolean) => void;

    return {
      updateValidation,
      library,
      results,
      keywords: '',
      addOutline,
      close,
      userAlimentRestriction,
      featuredGroups,
      allergenStore,
    };
  },
  methods: {
    haveExcludedGroup(allergenId: number): boolean {
      return this.userAlimentRestriction.some((a) => a.allergen?.id === allergenId);
    },
    triggerAllergenExclusion(allergen: Allergen & { picture: string }) {
      if (this.haveExcludedGroup(allergen.id)) {
        this.removeAllergenFromExcludedAliments(allergen);
        return;
      }
      this.addAllergenToAlimentsRestriction(allergen);
    },

    addElementFromAlimentsRestriction(element: Aliment | Allergen) {
      if (element instanceof Aliment) {
        this.addAlimentToAlimentsRestriction(element);
      } else {
        this.addAllergenToAlimentsRestriction(element);
      }
    },
    removeRestrictionFromAlimentsRestriction(element: AlimentRestriction) {
      if (element.aliment) {
        this.removeAlimentFromAlimentsRestriction(element.aliment as Aliment);
      } else {
        this.removeAllergenFromExcludedAliments(element.allergen as Allergen);
      }
    },

    // Functions to add/remove allergens/aliments from the user aliment restriction
    addAlimentToAlimentsRestriction(aliment: Aliment) {
      this.$store.dispatch('user/set', {
        alimentRestriction: uniq([...this.userAlimentRestriction, { aliment, reason: 'allergic' }]),
      });
    },
    addAllergenToAlimentsRestriction(allergen: Allergen) {
      this.$store.dispatch('user/set', {
        alimentRestriction: uniq([...this.userAlimentRestriction, { allergen, reason: 'allergic' }]),
      });
    },
    removeAlimentFromAlimentsRestriction(aliment: Aliment) {
      this.$store.dispatch('user/set', {
        alimentRestriction: this.userAlimentRestriction
          .filter((a) => a.aliment?.id !== aliment.id),
      });
    },
    removeAllergenFromExcludedAliments(allergen: Allergen) {
      this.$store.dispatch('user/set', {
        alimentRestriction: this.userAlimentRestriction
          .filter((a) => a.allergen?.id !== allergen.id),
      });
    },

    // Function to research aliments
    search(keywords: string) {
      const allergenResults = this.allergenStore
        .searchByName(keywords)
        .filter((newAllergen) => !this.userAlimentRestriction
          .find((existingAllergen) => newAllergen.item?.name === existingAllergen.allergen?.name));
      const foodResults = this.library.searchAliments(keywords);
      this.results = (allergenResults.length > 1)
        ? allergenResults
        : foodResults.slice(0, 3);
    },
  },
  ionViewDidEnter() {
    this.updateValidation(true);
  },
});
