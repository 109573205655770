
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProgressBarObject',
  props: {
    isRectangle: { type: Boolean, default: false },
    haveOpacity: { type: Boolean, default: false },
  },
});
