
import { computed, defineComponent } from 'vue';
import { IonThumbnail } from '@ionic/vue';
import { trashOutline } from 'ionicons/icons';
import { CartProduct } from '@/services/flyingBlue';
import useCurrentShoppingSessionStore from '@/stores/shopping/currentShoppingSessionStore';
import NutriscoreDisplay from '@/components/common/nutriscore/NutriscoreDisplay.vue';
import ProductAccordionSkeletonItemVue from './ProductAccordionSkeletonItem.vue';

export default defineComponent({
  name: 'ShoppingProductAccordionItem',
  components: {
    IonThumbnail,
    NutriscoreDisplay,
    ProductAccordionSkeletonItemVue,
  },
  props: {
    cartProduct: {
      type: Object as () => CartProduct,
      required: true,
    },
  },
  emits: ['change', 'productTap'],
  setup(props) {
    const currentShoppingSessionStore = useCurrentShoppingSessionStore();

    const cartProductId = computed(() => props.cartProduct.id);
    const productId = computed(() => props.cartProduct.product.id);
    const qty = computed(() => props.cartProduct.qty);
    const shouldRender = computed(() => props.cartProduct.product);
    const weightFormat = new Intl.NumberFormat('fr-FR', { style: 'unit', unit: 'gram' });
    const volumeFormat = new Intl.NumberFormat('fr-FR', { style: 'unit', unit: 'liter' });
    const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
    return {
      qty,
      cartProductId,
      productId,
      shouldRender,
      weightFormat,
      volumeFormat,
      currencyFormat,
      trashOutline,
      currentShoppingSessionStore,
    };
  },
  computed: {
    product() {
      return this.cartProduct.product;
    },
    productPrice() {
      let price = this.product?.price ?? 0;
      if (price === 0) {
        price = this.cartProduct?.price ?? 0;
      }
      return this.currencyFormat.format(price * this.qty);
    },
  },
  methods: {
    changeProduct() {
      this.$emit('change', this.cartProduct);
    },
    incrementProductQty() {
      this.currentShoppingSessionStore
        .incrementProductQty(this.productId, 1);
    },
    decrementProductQty() {
      this.currentShoppingSessionStore
        .decrementProductQty(this.productId, 1);
    },
    showDetailProduct() {
      this.$emit('productTap', this.productId);
    },
  },
});
