
import { defineComponent, Ref, ref } from 'vue';

import {
  IonFab,
  IonContent,
  IonFabButton,
  IonSearchbar,
} from '@ionic/vue';

import { useStore } from '@/store';

import { chevronBack, arrowUp } from 'ionicons/icons';

// Typings
import { Components } from '@ionic/core';
import { Library } from '@/typings/classes/library.class';
import Recipe from '@/typings/classes/recipe.class';
import Filters from '@/typings/classes/filters.class';
import { shuffle } from 'lodash';
import LibraryAllFiltersModal from './LibraryAllFiltersModal.vue';
import LibraryFiltersModal from './LibraryFiltersModal.vue';
import LibraryFilters from '../LibraryFilters.vue';
import LibraryInfiniteScroll from '../LibraryInfiniteScroll.vue';

export default defineComponent({
  name: 'LibraryResearchModal',
  components: {
    IonFab,
    IonFabButton,
    IonSearchbar,
    LibraryInfiniteScroll,
    LibraryFiltersModal,
    LibraryFilters,
    LibraryAllFiltersModal,
  },
  emits: ['close:modal'],
  // eslint-disable-next-line max-lines-per-function
  setup(unused, { emit }) {
    const store = useStore();
    const keywords = ref('');
    const library: Library = store.getters['library/get'];

    const filters: Ref<Filters> = ref(library.filters);
    const results: Ref<Recipe[]> = ref(shuffle(library.recipes));
    const modalKey = ref('');
    const isOpenRef = ref(false);
    const searchbar: Ref<typeof IonSearchbar | null> = ref(null);

    const search = (kw: string | null | undefined) => {
      if (kw) {
        results.value = library.search(kw)
          .map(({ item }) => item);
      } else {
        results.value = shuffle(library.recipes);
      }
    };
    const closeModal = () => { emit('close:modal', true); };
    const closeFilterModal = () => { isOpenRef.value = false; };
    const setResultsWhenModalIsClosed = () => {
      if (isOpenRef.value) { isOpenRef.value = false; }
      if (library.filters.haveActiveFilters()) {
        const shadowResults = library.results.map(({ item }) => item);
        if (keywords.value.length < 4) {
          results.value = shuffle(shadowResults);
        } else {
          results.value = shadowResults;
        }
      } else {
        results.value = library.recipes;
      }
    };
    const resetResults = () => {
      library.clearSimulationResults();
      results.value = library.recipes;
    };
    return {
      closeFilterModal,
      search,
      closeModal,
      resetResults,
      setResultsWhenModalIsClosed,
      searchbar,
      chevronBack,
      arrowUp,
      keywords,
      modalKey,
      isOpenRef,
      results,
      filters,
    };
  },
  watch: {
    $route: {
      handler() { this.closeModal(); },
    },
  },
  methods: {
    scrollToTop() {
      const content = (this?.$refs.libraryResearchModalContent as typeof IonContent);
      if (content) {
        (content?.$el as Components.IonContent)
          .scrollToPoint(0, 0, 1000);
      }
    },
    openFilterModal(key: string) {
      this.modalKey = key;
      this.isOpenRef = true;
    },
  },
});

