
import { get } from 'lodash';
import { defineComponent } from 'vue';
import { timeOutline } from 'ionicons/icons';
import { IonCard, useIonRouter } from '@ionic/vue';

import { useStore } from '@/store';
import Category from '@/typings/classes/category.class';
import Track from '@/services/tracking/firebase';

export default defineComponent({
  name: 'CarouselCategoryCard',
  components: { IonCard },
  props: {
    itemId: {
      type: Number,
      default: 1,
    },
    haveOption: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const router = useIonRouter();
    const categories: Category[] = store.getters['library/categories'];
    return { router, timeOutline, categories };
  },
  computed: {
    category(): Category | null {
      return this.categories.find(({ id }) => id === this.itemId) ?? null;
    },
    getUrl(): string {
      const imgUrl = get(this.category, 'image.data.attributes.url', '');
      if (imgUrl) return `https://api.petitcitron.fr${imgUrl}`;
      return 'https://via.placeholder.com/200x250)';
    },
  },
  methods: {
    goToCategory() {
      if (this.isClickable) {
        const pathBase = `/library/category/${this.itemId}`;
        Track.logEvent('library_category_view', {
          id: this.itemId,
          title: this.category?.displayName,
        });
        if (Object.keys(this.$route.query).length !== 0) {
          const append = Object.entries(this.$route.query)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          this.router.push(`${pathBase}?${append}`);
        } else {
          this.router.push(pathBase);
        }
      }
    },
  },
});
