import { IUser } from '@/typings/interfaces';

type State = {
  isLogged: boolean
  haveAcceptCGU: boolean,
} & IUser;

const state: State = {
  id: -1,
  email: null,
  provider: 'test',
  lastname: null,
  firstname: null,
  birthdate: (new Date('06/14/1998')).toISOString(),
  biologicalSex: null,

  objective: null,
  startObjectiveDate: null,
  objectiveDate: null,
  objectiveWeight: null,

  adults: 1,
  children: 0,

  diets: [],
  physicalActivity: null,
  sportSessionsPerWeek: null,
  allergies: [],
  excludedAliments: [],
  height: 0,
  weights: [{ value: 0, timestamp: Date.now() }],

  haveAcceptCGU: false,
  isLogged: false,
  alimentRestriction: [],
  metaData: {
    referralCode: '',
  },
};

export { State, state };
