import { ActionTree, ActionContext } from 'vuex';
import MenuRepository from '@/repositories/menus';

import { IMenu } from '@/typings/interfaces';
import { EMealTime } from '@/typings/enums';

import Menu from '@/typings/classes/menu.class';
import Aliment from '@/typings/classes/aliment.class';
import { IMeal, Meal } from '@/typings/classes/meal.class';

import moment from 'moment';
import { State } from './state';
import { Mutations } from './mutations';

import {
  MenuActionTypes,
  MenuMutationTypes,
} from './menu-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, unknown>, 'commit'>;

type RecommandationPayload = {
  date: string | undefined,
  wantBreakfast: boolean,
  wantLunch: boolean,
  wantDinner: boolean,
  mealSize: number,
};

type AAC = AugmentedActionContext;
// Actions
interface Actions {
  [MenuActionTypes.FIND]({ commit }: AAC): Promise<boolean>;
  [MenuActionTypes.CREATE_MENU]({ commit }: AAC, payload: Menu): Promise<boolean>;
  [MenuActionTypes.UPDATE_MENU]({ commit }: AAC, payload: Menu): Promise<boolean>;
  [MenuActionTypes.UPDATE_MENU_BY_ID]({ commit }: AAC, payload: number): Promise<boolean>;
  [MenuActionTypes.ADD_ALIMENT_TO_MEAL_BY_DATE_AND_MEAL_TIME]({ commit }: AAC,
    payload: { date: Date, mealTime: EMealTime, aliment: Aliment }): Promise<boolean>;
  [MenuActionTypes.UPDATE_MENU_BY_MEAL_ID]({ commit }: AAC,
    payload: Meal): Promise<boolean>;
  [MenuActionTypes.UPDATE_MEAL]({ commit }: AAC,
    payload: Meal): Promise<boolean>;
  [MenuActionTypes.UPDATE_MEAL_BY_MEAL_ID]({ commit }: AAC,
    payload: Meal): Promise<boolean>;
  [MenuActionTypes.DELETE_MEAL_BY_MENU_ID_AND_MEAL_ID]({ commit }: AAC,
    payload: { menuId: number, mealId: number }): Promise<boolean>;
  [MenuActionTypes.UPDATE_MEAL_BY_IDS]({ commit }: AAC,
    payload: { recipeId: number, mealId: number, menuId: number}): Promise<boolean>;
  [MenuActionTypes.CLEAR]({ commit }: AAC, payload: null): Promise<boolean>;
  [MenuActionTypes.FILL]({ commit }: AAC,
    payload: { menuId: number, mealSize: number }): Promise<boolean>;
}

const repository = new MenuRepository();

const actions: ActionTree<State, unknown> & Actions = {
  async [MenuActionTypes.FIND]({ commit })
    : Promise<boolean> {
    const { data: { data: menus } } = await repository.find();
    (menus as IMenu[]).sort((a: IMenu, b: IMenu) => moment(a.attributes.startDate)
      .diff(moment(b.attributes.startDate)));
    const menusClasses = (menus as IMenu []).map((menu) => new Menu(menu));
    commit(MenuMutationTypes.SET_DATA, { menusClasses });
    return true;
  },
  async [MenuActionTypes.CREATE_MENU]({ commit, state, rootGetters }, payload)
    : Promise<boolean> {
    const user = rootGetters['user/get'];
    const { menusClasses: newMenuList } = state;
    const { data: { data: menu } } = await repository.create({
      menu: payload,
      userId: user.id,
    });
    payload.update(menu as IMenu);
    newMenuList.push(payload);
    commit(MenuMutationTypes.SET_DATA, { menusClasses: newMenuList });
    return true;
  },
  async [MenuActionTypes.UPDATE_MENU]({ rootGetters, dispatch }, payload)
    : Promise<boolean> {
    const user = rootGetters['user/get'];
    const { data: { data: menu } } = await repository.update(payload.id.toString(), {
      menu: payload,
      userId: user.id,
    });
    payload.update(menu as IMenu);
    await dispatch('grocery/updateList', { menu: payload }, { root: true });
    return true;
  },
  async [MenuActionTypes.UPDATE_MENU_BY_ID]({ state, dispatch }, payload)
    : Promise<boolean> {
    const menu = state.menusClasses.find((m) => m.id === payload);
    await dispatch(MenuActionTypes.UPDATE_MENU, menu);
    return true;
  },
  async [MenuActionTypes.UPDATE_MENU_BY_MEAL_ID]({ state, dispatch }, payload)
    : Promise<boolean> {
    const menu = state.menusClasses.find((m) => m.meals.some((meal) => meal.id === payload.id));
    await dispatch(MenuActionTypes.UPDATE_MENU, menu);
    return true;
  },
  async [MenuActionTypes.UPDATE_MEAL]({ state, dispatch }, payload)
    : Promise<boolean> {
    const menu = state.menusClasses.find((m) => m.meals.some((meal) => meal.id === payload.id));
    if (!menu) return false;
    menu.meals.forEach((meal, index) => {
      if (meal.id === payload.id) {
        menu.meals[index] = payload;
      }
    });
    await dispatch(MenuActionTypes.UPDATE_MENU, menu);
    return true;
  },
  async [MenuActionTypes.UPDATE_MEAL_BY_IDS]({ state, dispatch }, { recipeId, mealId, menuId })
    : Promise<boolean> {
    const menu = state.menusClasses.find((m) => m.id === menuId);
    if (!menu) return false;
    menu.meals.forEach((meal, index) => {
      if (meal.id === mealId) {
        menu.meals[index].mainCourse = recipeId;
      }
    });
    await dispatch(MenuActionTypes.UPDATE_MENU, menu);
    return true;
  },
  async [MenuActionTypes.DELETE_MEAL_BY_MENU_ID_AND_MEAL_ID](
    { state, dispatch },
    payload: { menuId: number, mealId: number },
  )
    : Promise<boolean> {
    const menu = state.menusClasses.find((m) => m.id === payload.menuId);
    if (!menu) return false;
    menu.deleteMealById(payload.mealId);
    await dispatch(MenuActionTypes.UPDATE_MENU_BY_ID, payload.menuId);
    return true;
  },

  async [MenuActionTypes.UPDATE_MEAL_BY_MEAL_ID]({ state, dispatch }, payload: Meal)
    : Promise<boolean> {
    const menu = state.menusClasses.find((m) => m.meals.some((meal) => meal.id === payload.id));
    if (!menu) return false;
    menu.meals.forEach((meal, index) => {
      if (meal.id === payload.id) {
        menu.meals[index] = payload;
      }
    });
    await dispatch(MenuActionTypes.UPDATE_MENU, menu);
    return true;
  },

  async [MenuActionTypes.ADD_ALIMENT_TO_MEAL_BY_DATE_AND_MEAL_TIME](
    { state, dispatch },
    payload: { date: Date, mealTime: EMealTime, aliment: Aliment },
  ): Promise<boolean> {
    const menu = state.menusClasses.find((m) => m.isMenuBetweenDates(payload.date.toISOString()));
    if (menu) {
      const [meal] = menu.getMealsByDay(payload.date.toISOString())
        .filter((m) => m.mealTime === payload.mealTime);
      if (meal) {
        meal.addAliment({ aliment: payload.aliment.id, quantity: 1 });
      } else {
        menu.setMeal({
          mealTime: payload.mealTime,
          date: payload.date.toISOString(),
          mealId: null,
          recipeId: null,
          aliments: [{ aliment: payload.aliment.id, quantity: 1 }],
          servings: 1,
        });
      }
      await dispatch(MenuActionTypes.UPDATE_MENU, menu);
    } else {
      const sunday = moment(payload.date).isoWeekday(7);
      const monday = moment(payload.date).isoWeekday(1);
      const newMenu = new Menu({
        id: -1,
        attributes: {
          startDate: monday.toISOString(),
          endDate: sunday.toISOString(),
          meals: [] as IMeal[],
        },
      });
      await dispatch(MenuActionTypes.CREATE_MENU, newMenu);
      await dispatch(MenuActionTypes.ADD_ALIMENT_TO_MEAL_BY_DATE_AND_MEAL_TIME, payload);
    }
    return true;
  },

  async [MenuActionTypes.RECOMMANDATION](
    { commit, state, dispatch },
    payload: RecommandationPayload,
  )
    : Promise<boolean> {
    const { data: menu } = await repository.recommandation(payload);
    const { menusClasses } = state;
    commit(MenuMutationTypes.SET_DATA, {
      menusClasses: [...menusClasses, new Menu(menu.data as IMenu)],
    });

    // TODO change this to retrieve only the last grocery list
    await dispatch('grocery/fetchGroceryLists', null, { root: true });
    return true;
  },
  async [MenuActionTypes.RECOMMANDATION_RECIPES](
    { commit, state, dispatch },
    payload: { date: string, servings: number, recipesNumber: number },
  )
    : Promise<boolean> {
    const { data: menu } = await repository.recommandation({
      date: payload.date,
      mealSize: payload.recipesNumber,
    });
    const { menusClasses } = state;
    commit(MenuMutationTypes.SET_DATA, {
      menusClasses: [...menusClasses, new Menu(menu.data as IMenu)],
    });

    await dispatch('grocery/fetchGroceryLists', null, { root: true });
    return true;
  },
  async [MenuActionTypes.FILL]({ commit, dispatch, state }, payload) : Promise<boolean> {
    const data = await repository.fill(payload.menuId, payload.mealSize);
    commit(MenuMutationTypes.SET_MENU_MEALS, data);
    const menu = state.menusClasses.find((m) => m.id === payload.menuId);
    dispatch('grocery/updateList', { menu }, { root: true });
    return true;
  },
  async [MenuActionTypes.SOLVE]() : Promise<boolean> {
    const data = await repository.solve();
    return data.status === 200;
  },
  async [MenuActionTypes.CLEAR]({ commit }): Promise<boolean> {
    commit(MenuMutationTypes.CLEAR, undefined);
    return true;
  },
};

export { Actions, actions };

