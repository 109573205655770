import { defineStore } from 'pinia';
import CookerRepository from '@/repositories/cooker';

const repository = CookerRepository;

type State = {
  cookers: any[],
};

const useCookerStore = defineStore('cooker', {
  state: (): State => ({
    cookers: [],
  }),
  getters: {
    find: (state: State) => state.cookers,
    findOneById: (state: State) => (id: number) => {
      const cooker = state.cookers.find((c) => c.id === id);
      return cooker;
    },
  },
  actions: {
    clear() { this.$reset(); },
    async findCookers() {
      const cookers = await repository.find();
      this.cookers = cookers ?? [];
      return cookers ?? [];
    },
    async findOneCooker(id: number) {
      const cooker = await repository.findOne(`${id}`);
      this.cookers.push(cooker);
      return true;
    },
  },
});

export default useCookerStore;
