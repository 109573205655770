import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IUnit } from '@/typings/interfaces';

type UnitResult = {
  data: IUnit[] | IUnit,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};

class UnitRepository implements IRead<UnitResult> {
  private config = AxiosConfig;

  private prefix = 'units';

  async find(): Promise<AxiosResponse<UnitResult>> {
    try {
      return this.config.get(this.prefix);
    } catch (err) {
      console.error('PC -- Error UnitRepository');
      console.error(err);
      throw err;
    }
  }

  async findOne(id: string): Promise<AxiosResponse<UnitResult>> {
    try {
      return this.config.get(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error UnitRepository');
      console.error(err);
      throw err;
    }
  }
}

export default UnitRepository;
