import { ApiObject } from '../../api';
import {
  ApiAffiliateCustomerAffiliateCustomer,
  ApiAffiliateCampaignAffiliateCampaign,
} from '../../strapi-generation/schemas';

type SocialMediaName = 'facebook' | 'instagram' | 'linkedin' | 'twitter' | 'youtube' | 'tiktok';
type SocialMedia = {
  id: number,
  SocialMediaName: SocialMediaName,
  url: string,
};

export default class AffiliateCustomer implements ApiObject<ApiAffiliateCustomerAffiliateCustomer> {
  campaigns: ApiObject<ApiAffiliateCampaignAffiliateCampaign>[];
  description: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  [key: string]: any;
  title: string;
  picture: string;
  socialMedias: SocialMedia[];
  id: number;
}
