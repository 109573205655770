
import { defineComponent, ref, computed, ComputedRef } from 'vue';

import {
  IonFabButton,
  IonFab,
  useIonRouter,
} from '@ionic/vue';

import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { chevronBack } from 'ionicons/icons';
import ButtonWithLoader from '@/atomic/organisms/ButtonWithLoader.vue';
import Menu from '@/typings/classes/menu.class';
import { Meal } from '@/typings/classes/meal.class';
import moment from 'moment';

export default defineComponent({
  components: {
    ButtonWithLoader,
    IonFabButton,
    IonFab,
  },
  setup() {
    const route = useRoute();
    const router = useIonRouter();
    // setup open and close actions for modal
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => { isOpenRef.value = state; };

    // params and query setup
    const recipeId = computed(() => parseInt(route.params.id as string, 10));
    const query = computed(() => route.query);

    const haveQuery = computed(() => (!query.value.menu && !query.value.meal) ?? false);
    const mealId = computed(() => parseInt((query.value.meal as string) ?? -1, 10));
    const menuId = computed(() => parseInt((query.value.menu as string) ?? -1, 10));
    const store = useStore();
    const servings = computed(() => store.getters['user/familySize']);
    const currentMenu: ComputedRef<Menu | null> = computed(() => store.getters['menus/findById'](menuId.value));
    const currentMeal: ComputedRef<Meal | null> = computed(
      () => currentMenu.value?.findMealById(mealId.value) ?? null,
    );
    const isMenuThisWeek = computed(() => currentMenu.value?.isMenuBetweenDates(
      moment().toISOString(),
    ) ?? false);
    const currentMealTitle: ComputedRef<string> = computed(
      () => store.getters['library/findOneRecipe'](currentMeal.value?.mainCourse)?.title ?? '',
    );

    return {
      mealId,
      menuId,
      isOpenRef,
      setOpen,
      router,
      haveQuery,
      servings,
      currentMeal,
      currentMenu,
      currentMealTitle,
      recipeId,
      isMenuThisWeek,
    };
  },
  data() {
    // return ionicons icons
    return {
      chevronBack,
    };
  },
  computed: {
    presentingElement(): HTMLElement | undefined {
      return this.$parent?.$refs.ionRouterOutlet as HTMLElement | undefined;
    },
  },
  methods: {
    goToMenu() {
      this.router.push('/menu');
    },
    closeAndRedirectToCurrentMenu() {
      this.setOpen(false);
      return this.router.push(`/menu/${this.currentMenu?.startDate}`);
    },
    closeAndRedirect(isThisWeek = true) {
      this.setOpen(false);
      if (isThisWeek) { return this.goToMenu(); }
      const nextWeekMenu = this.findNextWeekMenu();
      if (nextWeekMenu !== null) {
        return this.router.push(`/menu/${nextWeekMenu.startDate}`);
      }
      return this.goToMenu();
    },
    createMenuAndAddMeal(isThisWeek = true) {
      const today = moment();
      const sunday = moment(today).isoWeekday(7);
      const monday = moment(today).isoWeekday(1);
      const newMenu = new Menu({
        id: -1,
        attributes: {
          startDate: monday.add(isThisWeek ? 0 : 7, 'days').toISOString(),
          endDate: sunday.add(isThisWeek ? 0 : 7, 'days').toISOString(),
          meals: [],
        },
      });
      newMenu.setMeal({
        mealId: null,
        recipeId: this.recipeId,
        servings: this.servings,
      });
      return this.$store.dispatch('menus/createMenu', newMenu);
    },
    addToThisWeekMenu() {
      if (this.isMenuThisWeek) {
        return this.addToCurrentMenu();
      }
      const currentWeekMenu: Menu | null = this.$store.getters['menus/getThisWeekMenu'] ?? null;
      if (currentWeekMenu === null) {
        return this.createMenuAndAddMeal(true);
      }
      if (this.currentMeal === null) {
        currentWeekMenu.setMeal({ mealId: null, recipeId: this.recipeId, servings: this.servings });
        return this.$store.dispatch('menus/updateMenuById', currentWeekMenu.id);
      }
      return this.$store.dispatch('menus/updateMealByIds', {
        mealId: this.mealId,
        menuId: currentWeekMenu.id,
        recipeId: this.recipeId,
      });
    },
    addToCurrentMenu() {
      if (this.currentMenu) {
        if (this.currentMeal) {
          return this.$store.dispatch('menus/updateMealByIds', {
            mealId: this.mealId,
            menuId: this.menuId,
            recipeId: this.recipeId,
          });
        }
        this.currentMenu.setMeal({
          mealId: null,
          recipeId: this.recipeId,
          servings: this.servings,
        });
        return this.$store.dispatch('menus/updateMenuById', this.currentMenu.id);
      }
      return Promise.resolve();
    },
    findNextWeekMenu(): Menu | null {
      const today = moment();
      const todayPlusOneWeek = moment(today).add(7, 'days');
      return (this.$store.getters['menus/getClasses'] as Menu[])
        .find((menu) => menu.isMenuBetweenDates(todayPlusOneWeek.toISOString())) ?? null;
    },
    addToNextWeekMenu() {
      const nextWeekMenu = this.findNextWeekMenu();
      if (nextWeekMenu) {
        nextWeekMenu.setMeal({
          mealId: null,
          recipeId: this.recipeId,
          servings: this.servings,
        });
        return this.$store.dispatch('menus/updateMenuById', nextWeekMenu.id);
      }
      return this.createMenuAndAddMeal(false);
    },
    getMenuNoThisWeekTitle() {
      if (!this.isMenuThisWeek && this.currentMenu) {
        const startDate = moment(this.currentMenu.startDate);
        const endDate = moment(this.currentMenu.endDate);
        const startDay = startDate.date();
        const endDay = endDate.date();
        const startMonth = startDate.format('MMMM');
        const endMonth = endDate.format('MMMM');
        if (startMonth === endMonth) {
          return `Du lun ${startDay} au dim ${endDay} ${startMonth}`;
        }
        return `Du lun ${startDay} ${startMonth} au dim ${endDay} ${endMonth}`;
      }
      return null;
    },
  },
});
