
import { computed, defineComponent } from 'vue';
import useRatingStore from '@/stores/rating';
import RateWithStars from '../common/RateWithStars.vue';

export default defineComponent({
  name: 'RecipeRateRow',
  components: {
    RateWithStars,
  },
  props: {
    recipeId: {
      type: Number,
      required: true,
    },
  },
  emits: ['show:modal'],
  setup(props) {
    const ratingStore = useRatingStore();
    const currentRating = computed(() => ratingStore.findOneByRecipeId(props.recipeId) ?? null);
    return { currentRating };
  },
});
