
import { computed, defineComponent } from 'vue';
import { CartProduct } from '@/services/flyingBlue';
import { trashOutline } from 'ionicons/icons';
import useCurrentShoppingSessionStore from '@/stores/shopping/currentShoppingSessionStore';
import NutriscoreDisplay from '@/components/common/nutriscore/NutriscoreDisplay.vue';
import { useIonRouter } from '@ionic/vue';

export default defineComponent({
  name: 'ShoppingProductCard',
  components: {
    NutriscoreDisplay,
  },
  props: {
    cartProduct: {
      type: Object as () => CartProduct,
      required: true,
    },
  },
  emits: ['change', 'qtyChanged', 'productTap'],
  setup(props) {
    const currentShoppingSessionStore = useCurrentShoppingSessionStore();
    const router = useIonRouter();
    const productId = computed(() => props.cartProduct.product.id);
    const qty = computed(() => props.cartProduct.qty);
    const shouldRender = computed(() => props.cartProduct.product);
    const numberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
    return {
      router,
      qty,
      productId,
      shouldRender,
      numberFormat,
      trashOutline,
      currentShoppingSessionStore,
    };
  },
  computed: {
    productPrice() {
      const price = (this.cartProduct?.price ?? 0) * (this.cartProduct.qty ?? 1);
      return this.numberFormat.format(price);
    },
  },
  methods: {
    changeProduct() {
      this.$emit('change', this.cartProduct);
    },
    incrementProductQty() {
      this.currentShoppingSessionStore
        .incrementProductQty(this.productId, 1);
      this.$emit('qtyChanged', this.qty);
    },
    decrementProductQty() {
      this.currentShoppingSessionStore
        .decrementProductQty(this.productId, 1);
      this.$emit('qtyChanged', this.qty);
    },
    showDetailProduct() {
      this.$emit('productTap', this.productId);
    },
  },
});
