import { matchesProperty, find, shuffle } from 'lodash';
import Fuse from 'fuse.js';
import groups from '@/assets/json/groups.json';
import Allergen from '@/tmp/src/entities/allergen/allergen';
import Recipe from './recipe.class';
import Filters from './filters.class';
import Aliment from './aliment.class';
import { GroceryAliment, IUstensil } from '../interfaces';

type SearchResult<T> = Fuse.FuseResult<T>;
type LibraryExpression = Fuse.Expression;

type LibraryFilterCategory = {
  key: string,
  title: string,
  filters: { title: string, isActive: boolean }[],
};
type TUnit = {
  singularName: string;
  pluralName: string;
  displayName: string;
  id: number;
};
type TCategory = {
  id: number,
  title: string,
  displayName: string,
  image: { data: { attributes: { url: string } } } | null,
  isActive: boolean,
  isPromoted?: boolean,
};

interface IGroup {
  code: string,
  icon?: string,
  color?: string,
  title: string,
}
type LibraryGroup = {
  codes: string[],
  icon?: string,
  color?: string,
  title: string,
};

const alimentNamePath = 'ingredients.aliment.data.attributes.name';
const categoryNamePath = 'categories.displayName';
/**
 * Options for fuse
 * Keys are used to index the recipe list properly and ensure search is more efficient
 */
const options: Fuse.IFuseOptions<Recipe> = {
  includeScore: true,
  useExtendedSearch: true,
  keys: [
    { name: 'title', weight: 5, getFn: (recipe) => recipe.title },
    { name: alimentNamePath, weight: 1 },
    { name: categoryNamePath, weight: 1 },
  ] as Fuse.FuseOptionKeyObject<Recipe>[],
  minMatchCharLength: 3,
  threshold: 0.36,
  includeMatches: true,
};

const optionsAliments: Fuse.IFuseOptions<Aliment> = {
  includeScore: true,
  useExtendedSearch: true,
  keys: [
    { name: 'name', weight: 5, getFn: (aliment) => aliment.name },
  ] as Fuse.FuseOptionKeyObject<Aliment>[],
  minMatchCharLength: 3,
  threshold: 0.36,
  includeMatches: true,
};

const optionsAllergen: Fuse.IFuseOptions<Allergen> = {
  includeScore: true,
  keys: [
    { name: 'name', weight: 1, getFn: (allergen) => allergen.name },
  ] as Fuse.FuseOptionKeyObject<Allergen>[],
  minMatchCharLength: 3,
  threshold: 0.2,
  // includeMatches: true,
};

/**
 * Represents a collection of recipes
 */
class Library {
  public units: TUnit[];
  public ustensils: Map<number,
  { singularName: string, pluralName: string, picture: string | null}
  >;

  public aliments: Map<number, Aliment>;

  public recipes: Recipe[];
  private categories: TCategory[];

  public filters: Filters;
  public results: SearchResult<Recipe>[];

  readonly groups: LibraryGroup[];
  readonly searchEngine: Fuse<Recipe>;
  readonly searchEngineAliments: Fuse<Aliment>;
  readonly searchEngineAllergen: Fuse<Allergen>;

  /**
   * Create a library of recipes */
  constructor() {
    this.filters = new Filters();
    this.ustensils = new Map();
    this.recipes = [];
    this.categories = [];
    this.aliments = new Map();
    this.results = [];
    this.units = [];
    this.groups = (groups as IGroup[]).reduce((acc, {
      code, title, color, icon,
    }) => {
      if (acc.some(({ title: groupTitle }) => groupTitle === title)) {
        const group = acc.find(({ title: groupTitle }) => groupTitle === title) as LibraryGroup;
        group.codes.push(code);
      } else {
        acc.push({
          codes: [code], title, color, icon,
        });
      }
      return acc;
    }, [] as LibraryGroup[]);
    this.searchEngine = new Fuse([], options);
    this.searchEngineAliments = new Fuse([], optionsAliments);
    this.searchEngineAllergen = new Fuse([], optionsAllergen);
  }

  /**
   * Set recipes, categories and aliments
   * create a search engine for recipes
   * @param recipes
   * @param categories
   */
  initialise(
    recipes: Recipe[],
    categories: TCategory[],
    aliments: Aliment[],
    units: TUnit[],
    ustensils: IUstensil[],
    allergens: Allergen[],
  ) {
    const { keys } = options;
    const { keys: keysAliments } = optionsAliments;
    recipes.sort((a, b) => a.id - b.id);
    this.recipes.push(...recipes);
    this.categories.push(...categories);
    ustensils.forEach((ustensil) => {
      this.ustensils.set(ustensil.id, {
        singularName: ustensil.attributes.singularName,
        pluralName: ustensil?.attributes?.pluralName ?? ustensil.attributes.singularName,
        picture: ustensil?.attributes?.picture.data?.attributes.url ?? null,
      });
    });
    aliments.forEach((aliment) => {
      this.aliments.set(aliment.id, aliment);
    });
    this.units = units;
    const fuseIndex = Fuse.createIndex(keys as Array<Fuse.FuseOptionKey<Recipe>>, recipes);
    this.searchEngine.setCollection(recipes, fuseIndex);
    const fuseIndexAliments = Fuse
      .createIndex(keysAliments as Array<Fuse.FuseOptionKey<Aliment>>, aliments);
    const fuseIndexAllergen = Fuse
      .createIndex(keysAliments as Array<Fuse.FuseOptionKey<Allergen>>, allergens);
    this.searchEngineAliments.setCollection(aliments, fuseIndexAliments);
    this.searchEngineAllergen.setCollection(allergens, fuseIndexAllergen);
  }

  /**
   * Search a recipe by keywords, matching by recipe title, ingredients names or tag
   * @param {string} keywords - Keywords to be matched
   * @return {SearchResult<Recipe>[]} A collection of recipes
   */
  search(keywords: string): SearchResult<Recipe>[] {
    const expressions: LibraryExpression = { $and: [] };
    const filterExpression = this.filters.buildExpressionForFuseJSFromList();
    const words = keywords.length <= 3 ? '!azertyuiopaze' : keywords;
    const wordsExpression: LibraryExpression = {
      $or: [
        { title: words },
        { [alimentNamePath]: words },
      ],
    };
    (expressions.$and as Fuse.Expression[]).push(wordsExpression);
    if (filterExpression.length) {
      (expressions.$and as Fuse.Expression[]).push(...filterExpression);
    }
    const results = this.searchEngine.search(expressions) as SearchResult<Recipe>[];
    const filtersFunctions = this.filters.buildFiltersFunctionForFuseJSFromList();
    this.results = results.filter((result) => filtersFunctions.every((filter) => filter(result)));
    return this.results;
  }

  /**
   * Get a number of recipes before filters are applied
   * @param keywords - Keywords to be matched
   * @returns {Number} number of recipes before filters are applied
   */
  getSimulationResearchLength(keywords: string) {
    this.search(keywords);
    return this.results.length;
  }

  searchAliments(keywords: string): SearchResult<Aliment>[] {
    return this.searchEngineAliments.search({ name: keywords });
  }

  searchAllergens(keywords: string): SearchResult<Allergen>[] {
    return this.searchEngineAllergen.search({ name: keywords });
  }

  /**
   * Clear the results of the last search
   */
  clearSimulationResults() { this.results = []; }

  /**
   * Find a recipe in the library
   * @param {number} id
   * @returns { Recipe | null } A recipe or null
   */
  findRecipeById(id: number): Recipe | null {
    const { recipes } = this;
    return find(recipes, matchesProperty('id', id)) ?? null;
  }

  /**
   * Find last recipes created
   * @param {number} size size of recipes array to be retrieved
   * @returns {number[]} last recipes ids
   */
  findLastRecipesIds(size: number): number[] {
    if (this.recipes.length === 0) {
      return [];
    }
    const { recipes } = this;
    return recipes.slice(-size).map(({ id }) => id);
  }

  findRecipesByCategoryId(categoryId: number): Recipe[] {
    const { recipes } = this;
    const recipesFiltered = recipes
      .filter(({ categories }) => categories.some(({ id }) => id === categoryId));
    return shuffle(recipesFiltered);
  }

  /**
   * Find recipes sorted by price tag
   * @param {string} priceTag of recipes array to be retrieved
   * @param {number} size size of recipes array to be retrieved
   * @returns
   */
  findRecipesByPriceTag(priceTag: string, size: number): Recipe[] {
    if (this.recipes.length === 0) {
      return [];
    }
    // TODO: define if we want to filter recipes with dessert category
    const recipesFiltered = this.recipes
      .filter(({ price: a }) => a === priceTag)
      .slice(0, size * 3);
    return shuffle(recipesFiltered).slice(0, size);
  }

  /**
   * Find recipes sorted by category of cooking time
   * ie: breakfast, lunch, ...
   * @param {number} size size of recipes array to be retrieved
   * @returns
   */
  findRecipesByCookingTime(size: number, under: number): Recipe[] {
    if (this.recipes.length === 0) {
      return [];
    }
    const [...recipes] = this.recipes;
    // TODO: define if we want to filter recipes with dessert category
    const filterRecipes = recipes
      .filter(({ cookingTime: a1, preparationTime: a2 }) => a1 + a2 <= under)
      .sort((
        { cookingTime: a1, preparationTime: a2 },
        { cookingTime: b1, preparationTime: b2 },
      ) => (a1 + a2) - (b1 + b2))
      .slice(0, size * 3);

    return shuffle(filterRecipes).slice(0, size);
  }

  /**
   * Find recipes sorted by category of day time
   * ie: breakfast, lunch, ...
   * @param {number} size size of recipes array to be retrieved
   * @returns
   */
  findRecipesByDayTimeCategory(size: number): number[] {
    if (this.recipes.length === 0) {
      return [];
    }
    const [...recipes] = this.recipes;
    // TODO: define if we want to filter recipes with dessert category
    const filterRecipes = recipes
      .filter(({ categories }) => categories.every(({ title }) => title !== 'BREAKFAST'))
      .filter(({ cookingTime: a1, preparationTime: a2 }) => a1 + a2 <= 30)
      .sort((
        { cookingTime: a1, preparationTime: a2 },
        { cookingTime: b1, preparationTime: b2 },
      ) => (a1 + a2) - (b1 + b2))
      .slice(0, size * 3);

    return shuffle(filterRecipes).slice(0, size).map(({ id }) => id);
  }

  findCategories() {
    return this.categories.filter(({ isActive }) => isActive);
  }

  findAlimentById(id: number): Aliment | null {
    return this.aliments.get(id) ?? null;
  }

  findPictureUrlByAlimentId(id: number): string {
    const aliment = this.findAlimentById(id);
    if (aliment) {
      if (import.meta.env.MODE === 'development') {
        return `http://localhost:1337${aliment.picture}`;
      }
      return `https://api.petitcitron.fr${aliment.picture}`;
    }
    return '';
  }

  getGroceryListFromRecipe(recipeId: number, servings: number): GroceryAliment[] {
    const recipe = this.findRecipeById(recipeId);
    if (!recipe) return [];
    const { ingredients } = recipe;
    if (ingredients) {
      const alimentsList = ingredients.map((ingredient) => {
        const { quantity, unit: ingredientUnit } = ingredient;
        const aliment = this.aliments.get(ingredient?.aliment?.data?.id);
        if (aliment) {
          const { name, group } = aliment;
          const unit = this.units.find(({ id: unitId }) => unitId === ingredientUnit?.data?.id);
          return {
            id: aliment.id,
            name,
            groupCode: group ?? '9999',
            servings,
            quantity: quantity * servings,
            unit: unit ?? null,
            recipe: recipeId,
            picture: aliment.picture,
          } as GroceryAliment;
        }
        return null;
      });
      return alimentsList.filter((aliment) => aliment !== null) as GroceryAliment[];
    }
    return [];
  }

  emptyRecipes() {
    this.recipes = this.recipes.slice(0, 0);
  }
}

export {
  Library, SearchResult, LibraryFilterCategory, TCategory, LibraryGroup, IGroup, TUnit,
};
