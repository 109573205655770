import { defineStore } from 'pinia';

import Allergen from '@/tmp/src/entities/allergen/allergen';
import AllergenRepository from '@/repositories/allergen/AllergenRepository';
import AllergenSearchEngine from '@/services/searchEngine/AllergenSearchEngine';

type AllergenState = {
  allergens: Allergen[],
  searchEngine: AllergenSearchEngine | null,
  hydrated: boolean
};

const useAllergenStore = defineStore('allergen', {

  state: (): AllergenState => ({
    allergens: [],
    searchEngine: null,
    hydrated: false,
  }),
  getters: {
    searchByName: (state) => (name: string) => state.searchEngine?.search(name) ?? [],
    findById: (state) => (id: number) => state.allergens
      .find((allergen) => allergen.id === id),
    findByAlimentId: (state) => (id: number) => state.allergens
      .find((allergen) => allergen.aliments.some((a) => a.id === id)),
  },

  actions: {
    async hydrate() {
      if (this.hydrated) return false;
      try {
        const allergens = await AllergenRepository.find();
        this.allergens = allergens;
        this.searchEngine = new AllergenSearchEngine(allergens);
        return true;
      } catch {
        return false;
      }
    },
    async findAllergen(refresh = false) {
      if (refresh && this.allergens.length > 0) {
        return this.hydrate().then(() => this.allergens);
      }
      return this.allergens;
    },
  },
});

export default useAllergenStore;
