
import { defineComponent, ref, Ref } from 'vue';

import {
  IonNote,
  IonCheckbox,
  useIonRouter,
  loadingController,
} from '@ionic/vue';

import { logoFacebook, logoGoogle, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { TextFieldTypes } from '@ionic/core/components';
import Track from '@/services/tracking/firebase';
import axios from 'axios';
import { Browser } from '@capacitor/browser';

export default defineComponent({
  name: 'RegisterView',
  components: {
    IonNote,
    IonCheckbox,
  },
  setup() {
    const router = useIonRouter();
    const haveConnector = ref(true);

    const haveAcceptCGU: Ref<boolean> = ref(false);
    const globalError: Ref<null | string> = ref(null);
    // unused for now
    // const lastname: Ref<string | undefined> = ref();
    return {
      logoFacebook,
      haveConnector,
      haveAcceptCGU,
      logoGoogle,
      globalError,
      router,
    };
  },
  data() {
    return {
      isFormOpen: false,
      isPasswordInputText: false,
      eyeOffOutline,
      eyeOutline,
      email: {
        value: '',
        key: 'email',
        label: 'Mon adresse e-mail',
        placeholder: 'sophie@petitcitron.fr',
        type: 'email' as TextFieldTypes,
        error: true,
        helper: null as null | string,
        onClick: this.openForm,
      },
      password: {
        value: '',
        key: 'password',
        label: 'Mon mot de passe',
        placeholder: ('·').repeat(15),
        type: 'password' as TextFieldTypes,
        error: true,
        helper: null as null | string,
        onClick: this.noop,
      },
      passwordConfirm: {
        value: '',
        key: 'passwordConfirm',
        label: 'Confirmer le mot de passe',
        placeholder: ('·').repeat(15),
        type: 'password' as TextFieldTypes,
        error: true,
        helper: null as null | string,
        onClick: this.noop,
      },
      firstname: {
        value: '',
        key: 'firstname',
        label: 'Mon prénom',
        placeholder: 'Sophie',
        type: 'text' as TextFieldTypes,
        error: true,
        helper: null as null | string,
        onClick: this.noop,
      },
    };
  },
  computed: {
    inputs() {
      if (this.isFormOpen) {
        return [
          this.email,
          this.firstname,
          this.password,
          this.passwordConfirm,
        ];
      }
      return [this.email];
    },
    isValid() {
      return this.inputs.every((input) => input.error === false)
        && this.haveAcceptCGU === true;
    },
  },
  watch: {
    password: {
      deep: true,
      handler({ value }: { value: string }) {
        if (!value || (value && value.length < 6)) {
          this.password.error = true;
          this.password.helper = 'Le mot de passe doit faire au moins 6 caractères';
        } else {
          this.password.error = false;
          this.password.helper = null;
        }
      },
    },
    passwordConfirm: {
      deep: true,
      handler({ value }) {
        if (!value || this.password.value !== value) {
          this.passwordConfirm.error = true;
          this.passwordConfirm.helper = 'Les mots de passe doivent être identiques';
        } else {
          this.passwordConfirm.error = false;
          this.passwordConfirm.helper = null;
        }
      },
    },
    firstname: {
      deep: true,
      handler({ value }) {
        if (!value) {
          this.firstname.error = true;
          this.firstname.helper = 'Saisis ton prénom';
        } else {
          this.firstname.error = false;
          this.firstname.helper = null;
        }
      },
    },
    email: {
      deep: true,
      handler({ value }) {
        // eslint-disable-next-line no-control-regex
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (emailRegex.test(value)) {
          this.email.error = false;
          this.email.helper = null;
        } else {
          this.email.error = true;
          this.email.helper = 'Saisis une adresse email valide';
        }
      },
    },
  },
  methods: {
    openForm() {
      this.isFormOpen = true;
    },
    getInputType(input: { type: TextFieldTypes }) {
      if (input.type === 'password' && this.isPasswordInputText) {
        return 'text';
      }
      return input.type;
    },
    noop() { return Promise.resolve(); },
    async register() {
      this.globalError = null;
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Création du compte...',
          duration: 5000,
        });
      if (this.isValid) {
        loading.present();
        try {
          await this.$store.dispatch('user/set', {
            haveAcceptCGU: this.haveAcceptCGU,
            firstname: this.firstname.value,
          });
          await this.$store.dispatch('user/register', {
            email: this.email.value,
            password: this.password.value,
          });
          await this.$store.dispatch('grocery/fetchGroceryLists');
          await this.$store.dispatch('foodlists/fetchFoodlists');
        } catch (err: unknown) {
          this.$sentry.captureException(err);
          if (axios.isAxiosError(err)) {
            const error = err.response?.data.error;
            if (error.message === 'Email or Username are already taken' && error.status === 400) {
              this.globalError = 'Cette adresse email est déjà utilisée';
            }
          } else {
            this.globalError = 'creation';
          }
        } finally {
          if (this.globalError === null) {
            Track.logEvent('register', { method: 'email' });
            this.router.replace('/ob/data-privacy');
          }
          loading.dismiss();
        }
      } else {
        this.globalError = 'creation';
      }
    },
    openCGU() {
      this.openLink('conditions-generales');
    },
    openConfidentiality() {
      this.openLink('politique-de-confidentialite');
    },
    openLink(type: string) {
      Browser.open({
        url: `${import.meta.env.VITE_PETIT_CITRON_WEBSITE}/legal/${type}`,
        windowName: '_self',
        presentationStyle: 'popover',
      });
    },
  },
});
