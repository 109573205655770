import Fuse from 'fuse.js';
import Allergen from '@/tmp/src/entities/allergen/allergen';
import FuseSearchEngine from './fuseSearchEngine';

const optionsAllergen: Fuse.IFuseOptions<Allergen> = {
  includeScore: true,
  keys: [
    { name: 'name', weight: 1, getFn: (allergen) => allergen.name },
  ] as Fuse.FuseOptionKeyObject<Allergen>[],
  minMatchCharLength: 3,
  threshold: 0.2,
  // includeMatches: true,
};

export default class AllergenSearchEngine extends FuseSearchEngine<Allergen> {
  constructor(collection: Allergen[]) {
    super(collection, optionsAllergen);
  }
}
