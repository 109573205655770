import { AxiosResponse } from 'axios';
import AxiosConfig from '@/services/api/index';

import IRead from '@/repositories/interfaces/IRead';
import { IRating } from '@/typings/interfaces';

type RatingResult = {
  data: IRating[] | IRating,
  meta?: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
};


class RatingRepository implements IRead<RatingResult> {
  private config = AxiosConfig;

  private prefix = 'ratings';

  async find(): Promise<AxiosResponse<RatingResult>> {
    try {
      return this.config.get(this.prefix);
    } catch (err) {
      console.error('PC -- Error CategoriesRepository');
      console.error(err);
      throw err;
    }
  }

  async findOne(id: string | number): Promise<AxiosResponse<RatingResult>> {
    try {
      return this.config.get(`${this.prefix}/${id}`);
    } catch (err) {
      console.error('PC -- Error CategoriesRepository');
      console.error(err);
      throw err;
    }
  }

  async create(data: {
    recipe: number,
    value: number,
    comment: string,
    firstname: string,
  }): Promise<AxiosResponse<RatingResult>> {
    try {
      return this.config.post(this.prefix, { data });
    } catch (err) {
      console.error('PC -- Error CategoriesRepository');
      console.error(err);
      throw err;
    }
  }

  async update(id: number, data: {
    recipe: number,
    value: number,
    comment: string
  }): Promise<AxiosResponse<RatingResult>> {
    try {
      return this.config.put(`${this.prefix}/${id}`, { data });
    } catch (err) {
      console.error('PC -- Error CategoriesRepository');
      console.error(err);
      throw err;
    }
  }
}

export default RatingRepository;
