
import { defineComponent, PropType, ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import CarouselRecipeCard from './CarouselRecipeCard.vue';
import CarouselCategoryCard from './CarouselCategoryCard.vue';

export default defineComponent({
  name: 'CarouselComponent',
  components: {
    Swiper,
    SwiperSlide,
    CarouselRecipeCard,
    CarouselCategoryCard,
  },
  props: {
    isRecipe: {
      type: Boolean,
      default: true,
    },
    isRectangle: {
      type: Boolean,
      default: false,
    },
    itemsId: {
      type: Object as PropType<Array<number>>,
      default: [] as Array<number>,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { innerWidth } = window;
    const slidePerView = ref((innerWidth - 48) / ((props.isRectangle ? 300 : 200)));
    return { slidePerView };
  },
});
